import React from 'react'
import { styled } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import Layout from '../../product/components/templates/Layout'
import { Button } from '@mui/material'

const PREFIX = 'StaticPages'

const classes = {
    table: `${PREFIX}-table`,
}

const StyledLayout = styled(Layout)({
    [`& .${classes.table}`]: {
        minWidth: 650,
    },
})

function createData(title, action) {
    return { title, action }
}

const rows = [
    createData(
        'HowItWorks',
        <Button color="primary" variant="outlined">
            Edit
        </Button>
    ),
    createData(
        'Terms',
        <Button color="primary" variant="outlined">
            Edit
        </Button>
    ),
    createData(
        'Privacy Policy',
        <Button color="primary" variant="outlined">
            Edit
        </Button>
    ),
    createData(
        'Support',
        <Button color="primary" variant="outlined">
            Edit
        </Button>
    ),
    createData(
        'FAQ',
        <Button color="primary" variant="outlined">
            Edit
        </Button>
    ),
    createData(
        'About',
        <Button color="primary" variant="outlined">
            Edit
        </Button>
    ),
]

const StaticPages = () => {
    return (
        <StyledLayout>
            <div className="dashboard staticPages">
                <TableContainer component={Paper}>
                    <Table className={classes.table} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell>Page Title</TableCell>
                                <TableCell align="right">Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row) => (
                                <TableRow key={row.title}>
                                    <TableCell component="th" scope="row">
                                        {row.title}
                                    </TableCell>
                                    <TableCell align="right">
                                        {row.action}
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </StyledLayout>
    )
}
export default StaticPages
