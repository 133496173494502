import {
    GET_ALL_REPORT_DISPLAY,
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
} from './reportTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_REPORT_DISPLAY:
            return {
                ...state,
                reports_data: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
