import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../../../product/components/atoms/PrimaryButton'
import { mapData } from '../../../../product/common/components'
import './CustomSearch.css'
import { Button, Popover } from '@mui/material'

const CustomSearch = (props) => {
    const [searchInfo, setSearchInfo] = useState({})
    const [additionalFilter, setAdditionalFilter] = useState({})
    const [condition, setCondition] = useState(false)
    const formik = props.formik
    const [anchorEl, setAnchorEl] = React.useState(null)

    const handleSubmit = (e) => {
        formik.values.page = 1
        formik.handleSubmit(e)
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const resetFunction = () => {
        props.resetForm()
        formik.handleSubmit()
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)
    const id = open ? 'adv-popover' : undefined
    useEffect(() => {
        setCondition(false)
        if (props.advFilters) {
            if (props.searchInfo.data && props.searchInfo.data?.length) {
                let searchMapData = props.searchInfo?.data
                let addData = props.searchInfo?.data
                let searching = searchMapData.filter(
                    (data, index) => index === 0
                )
                let addFilter = addData.filter((data, index) => index !== 0)
                let finalSearch = {
                    formik: props.searchInfo.formik,
                    data: searching,
                }
                let finalFilter = {
                    formik: props.searchInfo.formik,
                    data: addFilter,
                }
                setSearchInfo(finalSearch)
                console.log(finalFilter, 'finnnn')
                setAdditionalFilter(finalFilter)
                setCondition(true)
            } else if (props.searchInfo.length) {
                let searchMapData = props.searchInfo
                let addData = props.searchInfo
                let searching = searchMapData.filter(
                    (data, index) => index === 0
                )
                let addFilter = addData.filter((data, index) => index !== 0)
                let finalSearch = {
                    formik: formik,
                    data: searching,
                }
                let finalFilter = {
                    formik: formik,
                    data: addFilter,
                }
                setSearchInfo(finalSearch)
                console.log(finalFilter, 'finnnn')
                setAdditionalFilter(finalFilter)
                setCondition(true)
            }
        } else {
            setSearchInfo(props.searchInfo)
            setCondition(true)
        }
    }, [props.searchInfo, props.advFilters])

    return (
        <>
            {condition ? (
                props.advFilters ? (
                    <div className="commActBox d-flex justify-content-between align-items-center">
                        <div className="w-100">
                            <form
                                onSubmit={handleSubmit}
                                className="searchFieldLt row flex-wrap d-flex justify-content-between align-items-center"
                            >
                                <>
                                    {mapData(searchInfo)}
                                    <div className="btnSearch">
                                        <PrimaryButton
                                            id="customSearch_submit"
                                            label={
                                                props.buttonLabel
                                                    ? props.buttonLabel
                                                    : 'Search'
                                            }
                                            type="submit"
                                        />
                                    </div>
                                </>
                                <div>
                                    <Button
                                        aria-describedby={id}
                                        variant="outlined"
                                        onClick={handleClick}
                                        className="searchPopoverBtn"
                                    >
                                        Filter Options{' '}
                                        <span className="material-icons">
                                            expand_more
                                        </span>
                                    </Button>

                                    <Popover
                                        id={id}
                                        open={open}
                                        anchorEl={anchorEl}
                                        onClose={handleClose}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'right',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'right',
                                        }}
                                    >
                                        <div className="spContent">
                                            {mapData(additionalFilter)}
                                            <div className="spAction">
                                                <Button
                                                    onClick={() =>
                                                        resetFunction()
                                                    }
                                                    className="resetBtn"
                                                >
                                                    {props.buttonLabel
                                                        ? props.buttonLabel
                                                        : 'Reset'}
                                                </Button>
                                                <Button
                                                    id="customSearch_submit"
                                                    onClick={() =>
                                                        formik.handleSubmit()
                                                    }
                                                    className="submitBtn"
                                                >
                                                    {props.buttonLabel
                                                        ? props.buttonLabel
                                                        : 'Apply'}
                                                </Button>
                                            </div>
                                        </div>
                                    </Popover>
                                </div>
                            </form>
                        </div>
                    </div>
                ) : (
                    <>
                        <div className="commActBox d-flex justify-content-between align-items-center">
                            <div className="w-100">
                                <form
                                    onSubmit={handleSubmit}
                                    className="row customSearchInner flex-wrap d-flex justify-content-start align-items-center"
                                >
                                    {mapData(searchInfo)}
                                    <div className="col-auto ml-auto btnSearch">
                                        <PrimaryButton
                                            id="customSearch_submit"
                                            label={
                                                props.buttonLabel
                                                    ? props.buttonLabel
                                                    : 'Search'
                                            }
                                            type="submit"
                                        />
                                    </div>
                                </form>
                            </div>
                        </div>
                    </>
                )
            ) : null}
        </>
    )
}

export default CustomSearch
