import React, { useEffect, useContext } from 'react'
import './App.css'
import Routes from './routes'
import { BrowserRouter as Router } from 'react-router-dom'
import { setAuthToken, setIPAddress } from './product/common/api'
import { CookiesProvider } from 'react-cookie'
import AuthState from './product/context/auth/authState'
import CustomCommonState from './custom/context/common/commonState'
import VideoState from './product/context/video/videoState'
import ProductCommonState from './product/context/common/commonState'
import UserState from './product/context/user/userState'
import EmployeeState from './product/context/employee/empState'
import AlertState from './product/context/alert/alertState'
import CommunicationState from './product/context/communication/communicationState'
import BidDepositState from './product/context/bid_deposit/bidDepositState'
import TransactionState from './product/context/transaction/transactionState'
import CreditState from './product/context/credit/creditState'
import SettingState from './product/context/setting/settingState'
import ProductState from './product/context/product/productState'
import UserProductState from './product/context/userProduct/productState'
import AppointmentState from './product/context/appointment/appointmentState'
import UserBuyerState from './product/context/userBuyer/buyerState'
import UserCommonState from './product/context/userCommon/commonState'
import InvoiceState from './product/context/invoice/invoiceState'
import CartState from './product/context/cart/cartState'
import LanguageManagerState from './product/context/languageManager/languageManagerState'
import ReportState from './product/context/report/reportState'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import {
    ThemeProvider,
    StyledEngineProvider,
    createTheme,
} from '@mui/material/styles'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { SnackbarProvider } from 'notistack'
import Alerts from './product/common/alert'
import { publicIpv4 } from 'public-ip'
import AuctionState from './product/context/auction/auctionState'
import DynamicState from './product/context/dynamic/dynamicState'
import ReturnState from './product/context/return/returnState'
import BidState from './product/context/bid/bidState'
import { theme } from './utils/UI/theme'

global.site_url = process.env.REACT_APP_DOMAIN
global.images_url = global.site_url + '/uploads/product/'

global.ignoreStates = []
global.ignoreCountry = []
global.defaultCountry = 'AE'

if (localStorage.token) {
    setAuthToken(localStorage.token)
}

const App = () => {
    useEffect(() => {
        async function fetchData() {
            const ipaddress = await publicIpv4()
            if (ipaddress) {
                setIPAddress(ipaddress)
            }
        }
        fetchData()
    }, [])

    return (
        <CookiesProvider>
            {/* <StyledEngineProvider injectFirst> */}
            <ThemeProvider theme={theme}>
                <CustomCommonState>
                    <ProductCommonState>
                        <AuthState>
                            <AlertState>
                                <UserState>
                                    <AuctionState>
                                        <ProductState>
                                            <UserProductState>
                                                <UserBuyerState>
                                                    <UserCommonState>
                                                        <InvoiceState>
                                                            <CartState>
                                                                <LanguageManagerState>
                                                                    <EmployeeState>
                                                                        <ReportState>
                                                                            <BidDepositState>
                                                                                <TransactionState>
                                                                                    <CommunicationState>
                                                                                        <AppointmentState>
                                                                                            <SettingState>
                                                                                                <VideoState>
                                                                                                    <ReturnState>
                                                                                                        <BidState>
                                                                                                            <CreditState>
                                                                                                                <DynamicState>
                                                                                                                    <LocalizationProvider
                                                                                                                        dateAdapter={
                                                                                                                            AdapterMoment
                                                                                                                        }
                                                                                                                    >
                                                                                                                        <SnackbarProvider
                                                                                                                            maxSnack={
                                                                                                                                3
                                                                                                                            }
                                                                                                                        >
                                                                                                                            <div className="App">
                                                                                                                                <Alerts />
                                                                                                                                <Router>
                                                                                                                                    <Routes />
                                                                                                                                </Router>
                                                                                                                            </div>
                                                                                                                        </SnackbarProvider>
                                                                                                                    </LocalizationProvider>
                                                                                                                </DynamicState>
                                                                                                            </CreditState>
                                                                                                        </BidState>
                                                                                                    </ReturnState>
                                                                                                </VideoState>
                                                                                            </SettingState>
                                                                                        </AppointmentState>
                                                                                    </CommunicationState>
                                                                                </TransactionState>
                                                                            </BidDepositState>
                                                                        </ReportState>
                                                                    </EmployeeState>
                                                                </LanguageManagerState>
                                                            </CartState>
                                                        </InvoiceState>
                                                    </UserCommonState>
                                                </UserBuyerState>
                                            </UserProductState>
                                        </ProductState>
                                    </AuctionState>
                                </UserState>
                            </AlertState>
                        </AuthState>
                    </ProductCommonState>
                </CustomCommonState>
            </ThemeProvider>
            {/* </StyledEngineProvider> */}
        </CookiesProvider>
    )
}

export default App
