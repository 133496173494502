import {
    RESPONSE_STATUS,
    VIDEO_INFORMATION,
    CLEAR_RESPONSE,
} from './videoTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case VIDEO_INFORMATION:
            return {
                ...state,
                video_information: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
