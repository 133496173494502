import React, { useState, useContext, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Header from '../../molecules/Header'
import clsx from 'clsx'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ListItem from '@mui/material/ListItem'
import ListItemText from '@mui/material/ListItemText'
import './Layout.css'
import { LOGO, SIDE_PANEL, SITE_NAME } from '../../../../utils/UI'
import { Link, NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import { Button, Collapse, Tooltip } from '@mui/material'
import { ExpandLess, ExpandMore } from '@mui/icons-material'
import Accordion from '@mui/material/Accordion'
import AccordionDetails from '@mui/material/AccordionDetails'
import AccordionSummary from '@mui/material/AccordionSummary'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AuthContext from '../../../../product/context/auth/authContext'
import { useMediaQuery } from 'react-responsive'
import { handleRedirectInternal } from '../../../../product/common/components'
import AlertContext from '../../../../product/context/alert/alertContext'
import Loaders from '../../molecules/Loaders'

const PREFIX = 'Layout'

const classes = {
    arrow: `${PREFIX}-arrow`,
    tooltip: `${PREFIX}-tooltip`,
    root: `${PREFIX}-root`,
    appBar: `${PREFIX}-appBar`,
    appBarShift: `${PREFIX}-appBarShift`,
    menuButton: `${PREFIX}-menuButton`,
    hide: `${PREFIX}-hide`,
    drawer: `${PREFIX}-drawer`,
    drawerPaper: `${PREFIX}-drawerPaper`,
    drawerHeader: `${PREFIX}-drawerHeader`,
    content: `${PREFIX}-content`,
    contentShift: `${PREFIX}-contentShift`,
}

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.root}`]: {
        display: 'flex',
    },

    [`& .${classes.appBar}`]: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },

    [`& .${classes.appBarShift}`]: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },

    [`& .${classes.menuButton}`]: {
        marginRight: theme.spacing(2),
    },

    [`& .${classes.hide}`]: {
        display: 'none',
    },

    [`& .${classes.drawer}`]: {
        width: drawerWidth,
        flexShrink: 0,
    },

    [`& .${classes.drawerPaper}`]: {
        width: drawerWidth,
    },

    [`& .${classes.drawerHeader}`]: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
    },

    [`& .${classes.content}`]: {
        flexGrow: 1,
        padding: '0px 40px',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },

    [`& .${classes.contentShift}`]: {
        padding: 0,
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}))

const drawerWidth = 240

const CustomTooltip = Tooltip

const Layout = (props) => {
    const alertContext = useContext(AlertContext)

    const location = useLocation()
    const [expanded, setExpanded] = useState(false)
    let history = useHistory()
    const authContext = useContext(AuthContext)
    const { user, logout } = authContext
    const isMobile = useMediaQuery({
        query: '(max-width: 1024px)',
    })
    const { setAlert } = alertContext

    const [openDrawer, setOpenDrawer] = useState(true)
    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false)
    }

    const handleDrawerOpen = () => {
        setOpenDrawer(true)
    }

    const handleDrawerClose = () => {
        setOpenDrawer(false)
    }

    useEffect(() => {
        if (location.pathname.split('/')[1]) {
            setExpanded(location.pathname.split('/')[1])
        }
    }, [location.pathname])

    useEffect(() => {
        setOpenDrawer(isMobile ? false : true)
    }, [isMobile])

    const onLogOut = () => {
        logout()
        setAlert('Logged out successfully', 'success')
        handleRedirectInternal(history, '')
    }

    return (
        <Root className="layout noPrint">
            <Header noUI={props.noUI || false} />
            <div className={(classes.root, 'drawerMain')}>
                {openDrawer ? (
                    <span
                        className="sliderBackdrop"
                        onClick={handleDrawerClose}
                    ></span>
                ) : null}
                <Drawer
                    className={classes.drawer}
                    variant="persistent"
                    anchor="left"
                    open={openDrawer}
                    classes={{
                        paper: classes.drawerPaper,
                    }}
                >
                    {props.renderSidePanelDefault ? (
                        <Link
                            to="/dashboard"
                            id={`${SITE_NAME}_logo`}
                            className="sidepanelLogo"
                        >
                            <img src={LOGO} alt={`${SITE_NAME} Logo`} />
                        </Link>
                    ) : null}

                    <div className={classes.drawerHeader}>
                        <IconButton onClick={handleDrawerClose} size="large">
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>
                        {Object.keys(user)?.length > 0 ? (
                            <>
                                {user &&
                                    user.emp_permissions &&
                                    SIDE_PANEL.map((data, index) => (
                                        <>
                                            {(user.emp_permissions.indexOf(
                                                data.path.toString()
                                            ) !== -1 ||
                                                (typeof global.enableAllAdminPrivilages !=
                                                    'undefined' &&
                                                    user.emp_type &&
                                                    user.emp_type ==
                                                        'super_admin')) && (
                                                <>
                                                    {data.type === 'single' ||
                                                    data.type ===
                                                        'pseudoMultiple' ? (
                                                        <ListItem
                                                            key={`${data.index}_parent`}
                                                            button
                                                            id={
                                                                'sidebar_' +
                                                                (data.id
                                                                    ? data.id
                                                                    : data.label)
                                                            }
                                                        >
                                                            <NavLink
                                                                to={data.path}
                                                                exact={
                                                                    data.type ==
                                                                    'pseudoMultiple'
                                                                        ? false
                                                                        : true
                                                                }
                                                                activeClassName="active"
                                                            >
                                                                <ListItemText>
                                                                    <span
                                                                        className={
                                                                            data.iconClass
                                                                        }
                                                                    >
                                                                        {
                                                                            data.icon
                                                                        }
                                                                    </span>
                                                                    {data.label}
                                                                </ListItemText>
                                                            </NavLink>
                                                        </ListItem>
                                                    ) : (
                                                        <Accordion
                                                            expanded={
                                                                expanded ===
                                                                `${data.path}`
                                                            }
                                                            onChange={handleChange(
                                                                `${data.path}`
                                                            )}
                                                        >
                                                            <ListItem
                                                                button
                                                                id={
                                                                    'sidebar_' +
                                                                    (data.id
                                                                        ? data.id
                                                                        : data.label)
                                                                }
                                                            >
                                                                <AccordionSummary
                                                                    className={`${
                                                                        location.pathname.split(
                                                                            '/'
                                                                        )[1] ===
                                                                            data.path &&
                                                                        'active'
                                                                    }`}
                                                                    expandIcon={
                                                                        <ExpandMoreIcon />
                                                                    }
                                                                    aria-controls={`panel${index}bh-content`}
                                                                    id={`panel${index}bh-header`}
                                                                >
                                                                    <span
                                                                        className={
                                                                            data.iconClass
                                                                        }
                                                                    >
                                                                        {
                                                                            data.icon
                                                                        }
                                                                    </span>
                                                                    {data.label}
                                                                </AccordionSummary>
                                                            </ListItem>
                                                            <AccordionDetails>
                                                                <div className="subLinks">
                                                                    {data.subLinks.map(
                                                                        (
                                                                            d,
                                                                            i
                                                                        ) => (
                                                                            <>
                                                                                {user.emp_permissions.indexOf(
                                                                                    d.path.toString()
                                                                                ) !==
                                                                                    -1 && (
                                                                                    <>
                                                                                        <ListItem
                                                                                            key={`${i}_child`}
                                                                                            button
                                                                                            id={
                                                                                                'sidebar_' +
                                                                                                (d.id
                                                                                                    ? d.id
                                                                                                    : data.label +
                                                                                                      '_' +
                                                                                                      d.label)
                                                                                            }
                                                                                        >
                                                                                            <NavLink
                                                                                                to={
                                                                                                    d.path
                                                                                                }
                                                                                                exact
                                                                                                activeClassName="active"
                                                                                            >
                                                                                                <ListItemText
                                                                                                    primary={
                                                                                                        d.label
                                                                                                    }
                                                                                                />
                                                                                            </NavLink>
                                                                                        </ListItem>
                                                                                    </>
                                                                                )}
                                                                            </>
                                                                        )
                                                                    )}
                                                                </div>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    )}
                                                </>
                                            )}
                                        </>
                                    ))}
                                {props.renderSidePanelDefault ? (
                                    <ListItem
                                        button
                                        id="logout"
                                        className="logoutSidePanel"
                                        onClick={() => onLogOut()}
                                    >
                                        <ListItemText>
                                            <span className="material-icons-round">
                                                power_settings_new
                                            </span>
                                            Logout
                                        </ListItemText>
                                    </ListItem>
                                ) : null}
                            </>
                        ) : (
                            <Loaders name="side_panel" isLoading={true} />
                        )}
                    </List>
                </Drawer>
                <div className="layoutMain">
                    <main
                        className={clsx(classes.content, 'drawerContent', {
                            [classes.contentShift]: openDrawer,
                        })}
                    >
                        <div className="openDrawer">
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={handleDrawerOpen}
                                edge="start"
                                className={clsx(
                                    classes.menuButton,
                                    openDrawer && classes.hide
                                )}
                                size="large"
                            >
                                <MenuIcon />
                            </IconButton>
                            <>
                                {user &&
                                props.renderSidePanelDefault &&
                                !openDrawer &&
                                user.emp_permissions ? (
                                    <>
                                        {SIDE_PANEL.map((data, index) => (
                                            <>
                                                {(user.emp_permissions.indexOf(
                                                    data.path.toString()
                                                ) !== -1 ||
                                                    (typeof global.enableAllAdminPrivilages !=
                                                        'undefined' &&
                                                        user.emp_type &&
                                                        user.emp_type ==
                                                            'super_admin')) && (
                                                    <>
                                                        {data.type ===
                                                            'single' ||
                                                        data.type ===
                                                            'pseudoMultiple' ? (
                                                            <ListItem
                                                                key={`${data.index}_parent`}
                                                                button
                                                                className={`drawerShortIconsCnt ${
                                                                    location.pathname ===
                                                                        data.path &&
                                                                    'active'
                                                                }`}
                                                                onClick={() =>
                                                                    history.push(
                                                                        data.path
                                                                    )
                                                                }
                                                            >
                                                                <>
                                                                    <CustomTooltip
                                                                        title={
                                                                            data.label
                                                                        }
                                                                        placement="right"
                                                                        arrow
                                                                        classes={{
                                                                            arrow: classes.arrow,
                                                                            tooltip:
                                                                                classes.tooltip,
                                                                        }}
                                                                    >
                                                                        <ListItemText>
                                                                            {data.icon ? (
                                                                                <span
                                                                                    className={`${data.iconClass} drawerShortIcons`}
                                                                                >
                                                                                    {
                                                                                        data.icon
                                                                                    }
                                                                                </span>
                                                                            ) : (
                                                                                <span className="material-icons-round drawerShortIcons">
                                                                                    line_weight
                                                                                </span>
                                                                            )}
                                                                        </ListItemText>
                                                                    </CustomTooltip>
                                                                </>
                                                            </ListItem>
                                                        ) : (
                                                            <ListItem
                                                                key={`${data.index}_parent`}
                                                                button
                                                                className={`drawerShortIconsCnt ${
                                                                    location.pathname.split(
                                                                        '/'
                                                                    )[1] ===
                                                                        data.path &&
                                                                    'active'
                                                                }`}
                                                                onClick={() =>
                                                                    history.push(
                                                                        data
                                                                            .subLinks[0]
                                                                            .path
                                                                    )
                                                                }
                                                            >
                                                                <>
                                                                    <CustomTooltip
                                                                        title={
                                                                            data.label
                                                                        }
                                                                        placement="right"
                                                                        arrow
                                                                        classes={{
                                                                            arrow: classes.arrow,
                                                                            tooltip:
                                                                                classes.tooltip,
                                                                        }}
                                                                    >
                                                                        <ListItemText>
                                                                            {data.icon ? (
                                                                                <span
                                                                                    className={`${data.iconClass} drawerShortIcons`}
                                                                                >
                                                                                    {
                                                                                        data.icon
                                                                                    }
                                                                                </span>
                                                                            ) : (
                                                                                <span className="material-icons-round drawerShortIcons">
                                                                                    line_weight
                                                                                </span>
                                                                            )}
                                                                        </ListItemText>
                                                                    </CustomTooltip>
                                                                </>
                                                            </ListItem>
                                                        )}
                                                    </>
                                                )}
                                            </>
                                        ))}
                                        {props.renderSidePanelDefault ? (
                                            <ListItem
                                                button
                                                className="drawerShortIconsCnt"
                                                onClick={() => onLogOut()}
                                            >
                                                <CustomTooltip
                                                    title="Logout"
                                                    placement="right"
                                                    arrow
                                                    classes={{
                                                        arrow: classes.arrow,
                                                        tooltip:
                                                            classes.tooltip,
                                                    }}
                                                >
                                                    <ListItemText>
                                                        <span className="material-icons-round drawerShortIcons">
                                                            power_settings_new
                                                        </span>
                                                    </ListItemText>
                                                </CustomTooltip>
                                            </ListItem>
                                        ) : null}
                                    </>
                                ) : null}
                            </>
                        </div>
                        <div
                            className={`layoutBody ${
                                !openDrawer && 'reducePadding'
                            }`}
                        >
                            {SIDE_PANEL.map((data) => (
                                <>
                                    {location.pathname === data.path && (
                                        <>
                                            {data.noHeading ? (
                                                ''
                                            ) : (
                                                <>
                                                    <div className="layoutTitle d-flex justify-content-between align-items-center">
                                                        <h1>{data.label}</h1>
                                                        <Button
                                                            id={
                                                                data.id
                                                                    ? data.id
                                                                    : 'layout_back'
                                                            }
                                                            onClick={() =>
                                                                history.goBack()
                                                            }
                                                        >
                                                            <span className="material-icons">
                                                                arrow_back
                                                            </span>
                                                            Back
                                                        </Button>
                                                    </div>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {data.subLinks &&
                                        data.subLinks.map(
                                            (d) =>
                                                location.pathname ===
                                                    d.path && (
                                                    <>
                                                        {d.noHeading &&
                                                        d.noHeading === 1 ? (
                                                            ''
                                                        ) : (
                                                            <>
                                                                <div className="layoutTitle d-flex justify-content-between align-items-center">
                                                                    <h1>{`${data.label} > ${d.label}`}</h1>
                                                                    <Button
                                                                        id="layout_goback"
                                                                        onClick={() =>
                                                                            history.goBack()
                                                                        }
                                                                    >
                                                                        <span className="material-icons">
                                                                            arrow_back
                                                                        </span>
                                                                        Back
                                                                    </Button>
                                                                </div>
                                                            </>
                                                        )}
                                                    </>
                                                )
                                        )}
                                </>
                            ))}
                            {props.children}
                        </div>
                    </main>
                </div>
            </div>
        </Root>
    )
}

export default Layout
