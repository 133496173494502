import React, { useReducer } from 'react'
import CreditContext from './creditContext'
import CreditReducer from './creditReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
    GET_ALL_USER_CREDIT,
} from './creditTypes'

const CreditState = (props) => {
    const initialState = {
        responseStatus: null,
        search_allusercredit: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
    }

    const [state, dispatch] = useReducer(CreditReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const addCreditBalance = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'addCreditBalance',
                    formData,
                    '',
                    'user/credit'
                ),
            ])
            resp.commonResponse(res.data, 'addCreditBalance')
        } catch (err) {
            resp.commonErrorResponse('addCreditBalance')
        }
    }

    const addBidDeposit = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'addBidDeposit', formData, '', 'user/credit'),
            ])
            resp.commonResponse(res.data, 'addBidDeposit')
        } catch (err) {
            resp.commonErrorResponse('addBidDeposit')
        }
    }

    const getCreditHistory = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'getCreditHistory',
                    formData,
                    '',
                    'user/credit'
                ),
            ])
            const from = 'getCreditHistory'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_USER_CREDIT,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('getCreditHistory')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <CreditContext.Provider
            value={{
                responseStatus: state.responseStatus,
                search_allusercredit: state.search_allusercredit,
                getCreditHistory,
                addBidDeposit,
                addCreditBalance,
                clearResponse,
            }}
        >
            {props.children}
        </CreditContext.Provider>
    )
}

export default CreditState
