import React from 'react'
import CircularProgress from '@mui/material/CircularProgress'
import './Loader.css'
import { Fade } from '@mui/material'
import GridViewSkeleton from '../../molecules/Loaders/ProductCardSkeletons/GridViewSkeleton'
import ListViewSkeleton from '../../molecules/Loaders/ProductCardSkeletons/ListViewSkeleton'
import CartSkeleton from '../../molecules/Loaders/CartSkeleton'
import LoginSkeleton from './TableSkeleton'
import CheckoutSkeleton from '../../molecules/Loaders/CheckoutSkeleton'
import ProductViewSkeleton from '../../molecules/Loaders/ProductViewSkeleton'
import TableSkeleton from './TableSkeleton'
import SidePanelSkeleton from './SidePanelSkeleton'

const returnSwitch = (props) => {
    switch (props.name) {
        case 'login':
            return <LoginSkeleton />
        case 'table':
            return <TableSkeleton />
        case 'product_grid_view':
            return <GridViewSkeleton />
        case 'product_list_view':
            return <ListViewSkeleton />
        case 'cart':
            return <CartSkeleton />
        case 'checkout':
            return <CheckoutSkeleton />
        case 'product_view':
            return <ProductViewSkeleton />
        case 'side_panel':
            return <SidePanelSkeleton />
        default:
            return <CircularProgress disableShrink />
    }
}

const Loaders = (props) => {
    return (
        <>
            {props.isLoading ? (
                <>
                    <Fade in={props.isLoading} timeout={600}>
                        <div className="loadingCnt">
                            {[...Array(props.loop ? props.loop : 1)].map(
                                (data, index) => (
                                    <div
                                        className={
                                            props.name === 'home'
                                                ? 'loader'
                                                : ''
                                        }
                                        key={index}
                                    >
                                        {returnSwitch(props)}
                                    </div>
                                )
                            )}
                        </div>
                    </Fade>
                </>
            ) : (
                <></>
            )}
        </>
    )
}

export default Loaders
