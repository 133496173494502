import React, { useReducer } from 'react'
import DynamicContext from './dynamicContext'
import DynamicReducer from './dynamicReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_SINGLE_PROPERTY,
    GET_ALL_DYNAMIC_ARRAY,
    GET_ALL_TABLE_VALUES,
    GET_ALL_DYNAMIC_VALUES,
    GET_ALL_DYNAMIC_TABLES,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_ALL_DYNAMIC_PAGES_FIELDS,
} from './dynamicTypes'

const DynamicState = (props) => {
    const initialState = {
        responseStatus: null,
        dynamicValue: {
            records: [],
            from: '',
        },
        auction_dynamicfields: [],
        project_dynamicfields: [],
        user_dynamicfields: [],
        single_property: {
            record: {},
        },
        dynamicTableValue: {
            records: [],
            from: '',
        },
        project_tablefields: [],
        auction_tablefields: [],
        user_tablefields: [],
        template_tablefields: [],
        dynamicPagesFields: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
    }

    const [state, dispatch] = useReducer(DynamicReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const propertyColumnAction = async (formData, noAlert = false, from) => {
        try {
            from = from ? from : 'propertyColumnAction'
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'propertyColumnAction',
                    formData,
                    '',
                    'dynamic'
                ),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const propertyTableAction = async (formData, noAlert = false, from) => {
        try {
            from = from ? from : 'propertyTableAction'
            const [res] = await Promise.all([
                apiCall('post', 'propertyTableAction', formData, '', 'dynamic'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const propertyColumnStatusAction = async (
        formData,
        noAlert = false,
        from
    ) => {
        try {
            from = from ? from : 'propertyColumnStatusAction'
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'propertyColumnStatusAction',
                    formData,
                    '',
                    'dynamic'
                ),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const propertyTableStatusAction = async (
        formData,
        noAlert = false,
        from
    ) => {
        try {
            from = from ? from : 'propertyTableStatusAction'
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'propertyTableStatusAction',
                    formData,
                    '',
                    'dynamic'
                ),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const propertyColumnReOrder = async (formData, noAlert = false, from) => {
        try {
            from = from ? from : 'propertyColumnReOrder'
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'propertyColumnReOrder',
                    formData,
                    '',
                    'dynamic'
                ),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const getSingleProperty = async (formData) => {
        const from = 'getSingleProperty'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSingleProperty', formData, '', 'dynamic'),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_PROPERTY,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllDynamicArray = async (formData, fromVariable) => {
        const from = 'getAllDynamicArray'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'dynamic'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_DYNAMIC_ARRAY,
                    payload: {
                        records: res.data.data.responseData.records
                            ? res.data.data.responseData.records
                            : [],
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllDynamicFields = async (formData, fromVariable) => {
        const from = 'getAllDynamicArray'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'dynamic'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_DYNAMIC_VALUES,
                    payload: {
                        project_dynamicfields: res.data.data.responseData
                            .records
                            ? res.data.data.responseData.records.filter(
                                  (value) =>
                                      value.active != 2 &&
                                      value.view_page === '1'
                              )
                            : [],
                        user_dynamicfields: res.data.data.responseData.records
                            ? res.data.data.responseData.records.filter(
                                  (value) =>
                                      value.active != 2 &&
                                      value.view_page === '3'
                              )
                            : [],
                        auction_dynamicfields: res.data.data.responseData
                            .records
                            ? res.data.data.responseData.records.filter(
                                  (value) =>
                                      value.active != 2 &&
                                      value.view_page === '2'
                              )
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllDynamicTables = async (formData, fromVariable) => {
        const from = 'getAllDynamicArray'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'dynamic'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_DYNAMIC_TABLES,
                    payload: {
                        project_tablefields: res.data.data.responseData.records
                            ? res.data.data.responseData.records.filter(
                                  (value) =>
                                      value.active != 2 &&
                                      value.view_page === '1'
                              )
                            : [],
                        user_tablefields: res.data.data.responseData.records
                            ? res.data.data.responseData.records.filter(
                                  (value) =>
                                      value.active != 2 &&
                                      value.view_page === '3'
                              )
                            : [],
                        auction_tablefields: res.data.data.responseData.records
                            ? res.data.data.responseData.records.filter(
                                  (value) =>
                                      value.active != 2 &&
                                      value.view_page === '2'
                              )
                            : [],
                        template_tablefields: res.data.data.responseData.records
                            ? res.data.data.responseData.records.filter(
                                  (value) =>
                                      value.active != 2 &&
                                      value.view_page === '4'
                              )
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllDynamicPagesFields = async (formData, fromVariable) => {
        const from = 'getAllDynamicPagesFields'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'searchTableFetch', formData, '', 'setting'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_DYNAMIC_PAGES_FIELDS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: fromVariable ? fromVariable : from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const dynamicTableAction = async (
        formData,
        noAlert = false,
        fromVariable = 'dynamicTableAction'
    ) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'dynamicTableAction', formData, '', 'dynamic'),
            ])
            resp.commonResponse(res.data, fromVariable, noAlert)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getDynamicTableValues = async (formData, fromVariable = 'search') => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'dynamicTable', formData, '', 'dynamic'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_TABLE_VALUES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                        from: fromVariable,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, fromVariable)
            } else {
                resp.commonErrorResponse(fromVariable)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <DynamicContext.Provider
            value={{
                responseStatus: state.responseStatus,
                single_property: state.single_property,
                auction_tablefields: state.auction_tablefields,
                project_tablefields: state.project_tablefields,
                template_tablefields: state.template_tablefields,
                user_tablefields: state.user_tablefields,
                dynamicValue: state.dynamicValue,
                dynamicTableValue: state.dynamicTableValue,
                auction_dynamicfields: state.auction_dynamicfields,
                project_dynamicfields: state.project_dynamicfields,
                user_dynamicfields: state.user_dynamicfields,
                dynamicPagesFields: state.dynamicPagesFields,
                clearResponse,
                propertyColumnAction,
                propertyColumnStatusAction,
                propertyTableAction,
                propertyTableStatusAction,
                propertyColumnReOrder,
                getSingleProperty,
                getAllDynamicArray,
                getAllDynamicFields,
                getAllDynamicTables,
                dynamicTableAction,
                getDynamicTableValues,
                getAllDynamicPagesFields,
            }}
        >
            {props.children}
        </DynamicContext.Provider>
    )
}

export default DynamicState
