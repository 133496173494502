import React from 'react'
import { styled } from '@mui/material/styles'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import FormHelperText from '@mui/material/FormHelperText'

import { ListItem } from '@mui/material'

const PREFIX = 'CheckBox'

const classes = {
    root: `${PREFIX}-root`,
    checked: `${PREFIX}-checked`,
}

const Root = styled('div')({
    [`& .${classes.root}`]: {
        color: 'var(--primColor)',
        '&$checked': {
            color: 'var(--primColor)',
        },
        MuiFormControlLabelRoot: {
            marginBottom: 0,
        },
    },
    [`& .${classes.checked}`]: {},
})

const GreenCheckbox = styled(Checkbox)(({ theme }) => ({
    color: theme.palette.default,
    '&$checked': {
        color: theme.palette.primColor,
    },
    '& .MuiFormControlLabel-root': {
        // Adjust the selector accordingly
        marginBottom: 0,
    },
    [`& .${theme.palette.checked}`]: {}, // Adjust the selector accordingly
}))

const CheckBox = (props) => {
    return (
        <Root className="customCheckbox">
            <FormGroup row>
                <FormControlLabel
                    control={
                        <GreenCheckbox
                            name={props.name}
                            checked={props.checked}
                            value={props.value}
                            onChange={
                                props.onChange ? (e) => props.onChange(e) : null
                            }
                            disabled={props.disabled ? true : false}
                            classes={{
                                root: classes.root,
                                checked: classes.checked,
                            }}
                        />
                    }
                    label={<ListItem button>{props.label}</ListItem>}
                />
            </FormGroup>
            <FormHelperText>{props.error}</FormHelperText>
        </Root>
    )
}

export default CheckBox
