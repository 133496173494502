import React, { useReducer } from 'react'
import CartContext from './cartContext'
import CartReducer from './cartReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
    GET_SINGLE_CART,
    GET_ALL_SEARCH,
} from './cartTypes'

const CartState = (props) => {
    const initialState = {
        search_allproducts: {
            cartrecords: [],
            noncartrecords: [],
        },
        search_singlecart: {
            cartrecords: [],
            cartvalues: {},
            cartLocation: {},
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(CartReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getSingleCartProducts = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'checkout', formData, '', 'user/cart'),
            ])
            const from = 'cartsearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_CART,
                    payload: {
                        cartrecords: res.data.data.responseData.cartItems.length
                            ? res.data.data.responseData.cartItems
                            : [],
                        cartvalues: res.data.data.responseData.cartValues,
                        cartLocation: res.data.data.responseData.cartLocation,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('cartsearch')
        }
    }

    const payCartWallet = async (formData) => {
        const from = 'payCartWallet'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'user/credit'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const payCartOffline = async (formData) => {
        const from = 'payCartOffline'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'user/offline'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const payCartsOffline = async (formData, fromValue) => {
        const from = fromValue ? fromValue : 'payCartsOffline'
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'payCartsOffline',
                    formData,
                    '',
                    'user/offline'
                ),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    // Register User
    const changeOnCart = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'changeStatus', formData, '', 'user/buynow'),
            ])
            resp.commonResponse(res.data, 'cartchange')
        } catch (err) {
            resp.commonErrorResponse('cartchange')
        }
    }

    const getAllCartProducts = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'search', formData, '', 'user/buynow'),
            ])
            const from = 'admincartsearch'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_SEARCH,
                    payload: {
                        cartrecords: res.data.data.responseData.cartItems.length
                            ? res.data.data.responseData.cartItems
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                dispatch({
                    type: RESPONSE_STATUS,
                    payload: {
                        status: res.data.status,
                        message: res.data.data.message,
                        type: res.data.data.responseType,
                        from: from,
                    },
                })
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            console.log('Something went wrong!', err)
            resp.commonErrorResponse('cartsearch')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <CartContext.Provider
            value={{
                search_allproducts: state.search_allproducts,
                search_singlecart: state.search_singlecart,
                responseStatus: state.responseStatus,
                getSingleCartProducts,
                payCartWallet,
                payCartOffline,
                payCartsOffline,
                clearResponse,
                changeOnCart,
                getAllCartProducts,
            }}
        >
            {props.children}
        </CartContext.Provider>
    )
}

export default CartState
