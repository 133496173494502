import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import AuthContext from '../product/context/auth/authContext'

function PrivateRoute({ component: Component, ...rest }) {
    const authContext = useContext(AuthContext)
    const { user, token } = authContext

    const exemptedRoutes = ['/dashboard', '/change_password']
    return (
        <Route
            {...rest}
            render={(props) =>
                localStorage.token ? (
                    <>
                        {user && user.emp_permissions ? (
                            <>
                                {user.emp_permissions
                                    .split(',')
                                    .includes(props.location.pathname) ||
                                exemptedRoutes
                                    .split(',')
                                    .includes(props.location.pathname) ? (
                                    <Component {...props} />
                                ) : (
                                    <Redirect
                                        to={{
                                            pathname: '/',
                                            state: { referer: props.location },
                                        }}
                                    />
                                )}
                            </>
                        ) : (
                            <Component {...props} />
                        )}
                    </>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/',
                            state: { referer: props.location },
                        }}
                    />
                )
            }
        />
    )
}

export default PrivateRoute
