import React, { useContext, useState, useEffect } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { FormHelperText } from '@mui/material'

const GoogleCaptch = (props) => {
    const handlechange = (value, data) => {
        if (value) {
            props.formik.setFieldValue([props.data.name], true)
        } else {
            props.formik.setFieldValue([props.data.name], false)
        }
    }
    return (
        <>
            <ReCAPTCHA
                sitekey={global.capcha_key}
                onChange={handlechange}
                onErrored={() => handlechange(false)}
                onExpired={() => handlechange(false)}
            />
            <FormHelperText error>
                {props.formik.touched[props.data.name] &&
                    props.formik.errors[props.data.name]}
            </FormHelperText>
        </>
    )
}
export default GoogleCaptch
