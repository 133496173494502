import React, { useContext, useState, useEffect } from 'react'

import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import AuctionContext from '../../product/context/auction/auctionContext'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
} from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDropzone } from 'react-dropzone'

const UserManage = (props) => {
    const [isLoading, setIsLoading] = useState(true)
    const auctionContext = useContext(AuctionContext)
    const {
        single_auction,
        auction_projects,
        getSingleAuction,
        getAllAuctProjects,
        auctionAction,
    } = auctionContext

    const data = props.data
    const toggleFullScreenPopup = props.function
    const [activeAuctProjects, setActiveAuctProjects] = useState([])
    const [reload, setReload] = useState(false)

    const validationArray = Yup.object({
        title: Yup.string().required('Required!'),
        description: Yup.string().required('Required!'),
        date_added: Yup.string().required('Required!'),
        date_closed: Yup.string().required('Required!'),
        ending_enable: Yup.boolean(),
        ending_items: Yup.number().when('ending_enable', {
            is: (end) => end === true,
            then: Yup.number().min(0, 'Not valid!').required('Required!'),
        }),
        ending_mins: Yup.number().when('ending_enable', {
            is: (end) => end === true,
            then: Yup.number().min(0, 'Not valid!').required('Required!'),
        }),
        project_id: Yup.array().required('Required!'),
        lotupload: Yup.array().when('avatarorg', {
            is: (avatar) => !avatar,
            then: Yup.array().required('Required!'),
        }),
        avatarorg: Yup.string(),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            title: '',
            description: '',
            date_added: dateTimeFormatFunction(new Date()),
            date_closed: dateTimeFormatFunction(new Date()),
            ending_enable: true,
            ending_items: 0,
            ending_mins: 0,
            project_id: [],
            lotupload: [],
            avatarorg: '',
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            setIsLoading(true)
            var formData = new FormData()
            Object.keys(values).forEach((index, key) => {
                if (index === 'lotupload') {
                    formData.append(index, values[index][0])
                } else {
                    formData.append(index, values[index])
                }
            })
            auctionAction(formData)
        },
    })

    useEffect(() => {
        console.log('heree 3333', data)
        resetFrom()
    }, [data])

    useEffect(() => {
        if (data.status === 'edit') {
            formik.values.id = data.id
            getSingleAuction(formik.values)
            getAllAuctProjects(formik.values)
        } else {
            formik.values.id = 0
            getAllAuctProjects(formik.values)
            console.log('heree 1222')
            resetFrom()
        }
    }, [data.id])

    useEffect(() => {
        if (single_auction.record && data.status === 'edit') {
            const auction = single_auction.record
            const project_id = auction.auction_lots
                ? auction.auction_lots.split(',').map(Number)
                : []
            formik.values.title = auction.title
            formik.values.description = auction.description
            formik.values.project_id = project_id
            formik.values.date_added = dateTimeFormatFunction(
                auction.date_added
            )
            formik.values.date_closed = dateTimeFormatFunction(
                auction.date_closed
            )
            formik.values.ending_enable =
                auction.ending_enable === 1 ? true : false
            formik.values.ending_items = auction.ending_items
            formik.values.ending_mins = auction.ending_mins
            formik.values.avatarorg = auction.avatarorg
            formik.values.lotupload = []
            formik.handleReset()
            setReload(!reload)
        } else {
            console.log('heree 111')
            resetFrom()
        }
        setIsLoading(false)
    }, [single_auction])

    useEffect(() => {
        console.log('formik.values', formik.values)
    }, [formik.values])

    const resetFrom = () => {
        console.log('reset form')
        formik.values.title = ''
        formik.values.description = ''
        formik.values.date_added = dateTimeFormatFunction(new Date())
        formik.values.date_closed = dateTimeFormatFunction(new Date())
        formik.values.ending_enable = true
        formik.values.ending_items = 0
        formik.values.ending_mins = 0
        formik.values.avatarorg = ''
        formik.values.project_id = []
        formik.values.lotupload = []
        formik.handleReset()
        setReload(!reload)
    }

    useEffect(() => {
        const projectToShow = []
        if (auction_projects && auction_projects.records.length > 0) {
            auction_projects.records.map((lot) => {
                projectToShow.push({ value: lot.id, show: lot.title })
            })
            setActiveAuctProjects(projectToShow)
        } else {
            setActiveAuctProjects([])
        }
        setIsLoading(false)
    }, [auction_projects])

    const personalDetailsNew = [
        {
            label: 'Auction Lot Title *',
            type: 'text',
            placeholder: 'Enter your Auction Lot Title',
            class: 'col-12 col-sm-6',
            name: 'title',
            formik: formik,
        },
        {
            label: 'Start date *',
            placeholder: 'Enter Start date',
            type: 'datetime',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'date_added',
            formik: formik,
        },
        {
            label: 'End Date *',
            placeholder: 'Enter End Date',
            type: 'datetime',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'date_closed',
            formik: formik,
        },
        {
            label: 'Listings *',
            placeholder: 'Listings',
            type: 'multiselect',
            class: 'col-12',
            options: activeAuctProjects,
            name: 'project_id',
            formik: formik,
        },
        {
            label: 'Description *',
            placeholder: 'Enter Description',
            type: 'textarea',
            class: 'col-12',
            name: 'description',
            formik: formik,
        },
        {
            label: 'Enable Staggered',
            placeholder: 'Enable Staggered Bidding',
            type: 'checkbox',
            class: 'col-sm-6 col-12',
            name: 'ending_enable',
            formik: formik,
        },
        {
            label: 'Items',
            placeholder: 'Enable Items',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'ending_items',
            formik: formik,
        },
        {
            label: 'Minutes',
            placeholder: 'Enable Minutes',
            type: 'number',
            class: 'col-md-3 col-sm-6 col-12',
            name: 'ending_mins',
            formik: formik,
        },
    ]

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        multiple: false,
        onDrop: (acceptedFiles) => {
            let arr = acceptedFiles.map((file) =>
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                })
            )
            // remove files concat if it is only single image
            // setFiles(files.concat(arr))
            formik.values.lotupload = arr
            setReload(!reload)
        },
    })

    const thumbs =
        formik.values.lotupload.length === 0 ? (
            <>
                {formik.values.avatarorg && (
                    <div className="thumb" key={1}>
                        <div className="thumbInner">
                            <img
                                src={formik.values.avatarorg}
                                className="img"
                            />
                        </div>
                    </div>
                )}
            </>
        ) : (
            formik.values.lotupload.map((file) => (
                <div className="thumb" key={file.name}>
                    <div className="thumbInner">
                        <img src={file.preview} className="img" />
                    </div>
                </div>
            ))
        )

    useEffect(() => {
        console.log('formik.values.lotupload', formik.values.lotupload)
        // Make sure to revoke the data uris to avoid memory leaks
        formik.values.lotupload.forEach((file) =>
            URL.revokeObjectURL(file.preview)
        )
    }, [formik.values.lotupload])

    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new'
                    ? 'ADD NEW LOT'
                    : `EDIT LOT: ${single_auction.record.title} `
            }
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        <div className="row">
                            {Object.values(mapData(personalDetailsNew))}
                        </div>

                        <section>
                            <div {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <h4>
                                    Drag n drop some files here, or click to
                                    select files
                                </h4>
                            </div>
                            <aside className="thumbsContainer">{thumbs}</aside>
                        </section>
                        <p className="scheduleError">
                            {formik.errors.lotupload && formik.errors.lotupload}
                        </p>
                        {isLoading && <div>Loading....</div>}
                        <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                            <SecondaryButton
                                label="Cancel"
                                onClick={() => toggleFullScreenPopup(false)}
                            />
                            <PrimaryButton type="submit" label="Submit" />
                        </div>
                    </form>
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default UserManage
