import React, { useContext, useState, useEffect } from 'react'

import FullScreenPopup from '../FullScreenPopup'
import { useFormik } from 'formik'
import { Button } from '@mui/material'
import CustomTable from '../../molecules/CustomTable'
import PrimaryButton from '../../atoms/PrimaryButton'
import CustomDialog from '../../organisms/Dialog'
import ProductContext from '../../../context/product/productContext'
import BidContext from '../../../context/bid/bidContext'
import CommonContext from '../../../context/common/commonContext'
import ReportContext from '../../../context/report/reportContext'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

const BidHistory = () => {
    const productContext = useContext(ProductContext)
    const bidContext = useContext(BidContext)
    const {
        search_allbidhistory,
        getAllBidHistory,
        clearResponse: clearAllBidHistory,
    } = productContext
    const {
        bidCancel,
        bidAward,
        responseStatus: responseStatusBid,
    } = bidContext

    const [changeStatus, setChangeStatus] = React.useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const commonContext = useContext(CommonContext)
    const { bidHistoryValue, clearBidHistoryValue } = commonContext

    const { getAllExportDownload } = useContext(ReportContext)

    const [tableBody, setTableBody] = useState([])
    const [data, setData] = useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const [showDeclined, setShowDeclined] = useState(false)
    const toggleDecline = () => {
        formikData.values.page = 1
        setShowDeclined(!showDeclined)
        formikData.values.declined = !showDeclined
        getAllBidHistory(formikData.values)
    }

    const formikData = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            get_proxy: false,
            declined: false,
            delete_bid: false,
            award_bid: false,
            show_declined: false,
            project: 'NPS',
            filters: {
                project_id: {
                    value: 0,
                    type: 'in',
                    field: 'b.project_id',
                },
                user_id: {
                    value: '',
                    type: 'in',
                    field: 'b.user_id',
                },
                proposal: {
                    value: 'tie_bid',
                    type: 'notin',
                    field: 'b.proposal',
                },
                auctionid: {
                    field: 'p.auctionid',
                    value: '',
                    type: 'in',
                },
                market_status: {
                    field: 'p.market_status',
                    type: 'arrayinmultiple',
                    value: '',
                },
            },
        },
        onSubmit: (values) => {
            getCreditHistory(values)
        },
    })

    useEffect(() => {
        if (bidHistoryValue) {
            if (bidHistoryValue.id) {
                console.log('bidHistoryValue', bidHistoryValue)
                formikData.values.page = 1
                formikData.values.filters.project_id.value = bidHistoryValue.id
                formikData.values.get_proxy = bidHistoryValue.proxy
                formikData.values.filters.auctionid.value = ''
                formikData.values.filters.market_status.value = ''
                if (bidHistoryValue.declined) {
                    formikData.values.declined = bidHistoryValue.declined
                }
                formikData.values.delete_bid =
                    bidHistoryValue.delete_bid &&
                    bidHistoryValue.delete_bid === true
                        ? true
                        : false
                formikData.values.award_bid =
                    bidHistoryValue.award_bid &&
                    bidHistoryValue.award_bid === true
                        ? true
                        : false

                formikData.values.show_declined =
                    bidHistoryValue.show_declined &&
                    bidHistoryValue.show_declined === true
                        ? true
                        : false
                if (bidHistoryValue.user_id) {
                    formikData.values.filters.user_id.value =
                        bidHistoryValue.user_id
                }
                if (
                    bidHistoryValue.getBidByAuction &&
                    global.additionalBidHistory
                ) {
                    formikData.values.get_bid_auction = true
                    formikData.values.filters.project_id.value = ''
                    formikData.values.filters.auctionid.value =
                        bidHistoryValue.id
                    if (bidHistoryValue.market_status == 'closed') {
                        formikData.values.filters.market_status.value =
                            'sold,closed'
                        formikData.values.filters.market_status.type =
                            'arrayinmultiple'
                    } else {
                        formikData.values.filters.market_status.value =
                            bidHistoryValue.market_status
                        formikData.values.filters.market_status.type = 'in'
                    }
                }
                if (bidHistoryValue.show_tiebid) {
                    formikData.values.filters.proposal.value = ''
                } else {
                    formikData.values.filters.proposal.value = 'tie_bid'
                }
            } else {
                formikData.values.page = 1
                formikData.values.filters.project_id.value = bidHistoryValue
            }
            setData({ popup: true, id: bidHistoryValue })
            getAllBidHistory(formikData.values)
            clearBidHistoryValue()
            //console.log(bidHistoryValue, 'bidHistoryValue')
        }
        return () => {
            clearBidHistoryValue()
        }
    }, [bidHistoryValue])

    useEffect(() => {
        if (search_allbidhistory) {
            if (search_allbidhistory.records.length) {
                search_allbidhistory.records.map((item) => {
                    if (item.bidtopstatus) {
                        item.bidtopstatus =
                            item.bidtopstatus == 'lost'
                                ? 'Lost'
                                : item.bidtopstatus == 'won'
                                ? 'Won'
                                : item.bidtopstatus == 'winner'
                                ? 'Winning'
                                : item.bidtopstatus == 'outbid'
                                ? 'Currently Outbid'
                                : 'No Bids'
                    }
                })

                setTableBody(search_allbidhistory.records)
            } else {
                setTableBody([])
                //setTableBody(search_allbidhistory.records.length ? search_allbidhistory.records : [])
            }
        }
    }, [search_allbidhistory])

    let tableValues = [
        {
            field: 'created_at',
            type: 'datetimesecond',
            firstChild: true,
            sort: false,
            disablePadding: false,
            label: 'Date',
        },
        {
            field: 'user_id',
            type: '',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'User ID',
        },
        {
            field: 'users_first_name,users_last_name',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Username',
        },
        {
            field: 'users_companyname',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Company Name',
        },
        {
            field: 'users_email',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'User Email',
        },
    ]

    if (formikData.values.declined) {
        tableValues.push({
            field: 'bidtext',
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'declined',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Retracted',
                },
                false: {
                    type: 'text',
                    value: 'Valid',
                },
            },
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        })
        tableValues.push({
            field: 'bid_retract_logs_created_at',
            type: 'datetime',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'Retracted On',
        })
        tableValues.push({
            field: 'brl_first_name,brl_last_name',
            type: 'text',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'Retracted By',
        })
    }

    if (formikData.values.get_proxy) {
        tableValues.push({
            field: 'bidtext',
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'proxytable',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Max',
                },
                false: {
                    type: 'value',
                    value: 'bidText',
                },
            },
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Type',
        })
    }

    tableValues.push({
        field: 'proposed_amount',
        type: 'price',
        sort: true,
        firstChild: false,
        disablePadding: false,
        label: 'Amount',
    })

    if (formikData.values.get_bid_auction && global.additionalBidHistory) {
        tableValues = global.additionalBidHistory
    }

    const formikSelection = useFormik({
        initialValues: {
            action: '',
            id: '',
            pid: '',
        },
        onSubmit: (values) => {
            if (values.action === 'delete') {
                // setIsLoading(true)
                bidCancel(values)
            } else if (values.action === 'award') {
                // setIsLoading(true)
                bidAward(values)
            }
        },
    })

    const onClickEdit = (id, secondaryID, type) => {
        formikSelection.values.action = type
        formikSelection.values.id = id
        formikSelection.values.pid = formikData.values.filters.project_id.value
        if (type === 'delete' || type === 'award') {
            setChangeStatus(true)
        }
    }

    const getMultipleAction = () => {
        let actionPush = []
        if (formikData.values.delete_bid && !showDeclined) {
            actionPush.push({
                field: 'action',
                type: 'button',
                clickType: 'delete',
                onclick: onClickEdit,
                sort: false,
                firstChild: false,
                disablePadding: false,
                label: 'Action',
                tooltipTitle: 'Delete Bid',
                buttonType: 'delete',
            })
        }

        if (formikData.values.award_bid) {
            actionPush.push({
                field: 'action',
                type: 'button',
                clickType: 'award',
                onclick: onClickEdit,
                sort: false,
                firstChild: false,
                disablePadding: false,
                label: 'Action',
                tooltipTitle: 'Award Bid',
                buttonType: 'emoji_events',
            })
        }

        return actionPush
    }

    if (
        (formikData.values.delete_bid && !showDeclined) ||
        formikData.values.award_bid
    ) {
        tableValues.push({
            name: 'action',
            field: 'action',
            type: 'button',
            isMultiple: true,
            clickID: 'id',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            isMultiple: getMultipleAction(),
            tableShow: 1,
        })
    }

    useEffect(() => {
        if (responseStatusBid) {
            if (
                responseStatusBid.from === 'bidCancel' ||
                responseStatusBid.from === 'bidAward'
            ) {
                if (responseStatusBid.status === 'success') {
                    changeChangeStatus()
                    getAllBidHistory(formikData.values)
                }
            }
        }
    }, [responseStatusBid])

    const tableActions = []

    let warHouse = []
    let warHouseExport = {}
    if (formikData.values.get_bid_auction && global.additionalBidHistory) {
        global.additionalBidHistory.map((spaceDog) => {
            warHouse.push({
                name: spaceDog.label,
                field: spaceDog.field,
                type: 'text',
            })
            warHouseExport[spaceDog.field] = {
                name: spaceDog.label,
                field: spaceDog.field,
                type: 'text',
            }
        })
    }

    return (
        data.popup && (
            <FullScreenPopup
                modaltitle={`Bid History`}
                open={data.popup === true}
                handleClose={() => setData({ ...data, popup: false })}
            >
                <div className="wallet">
                    <div className="fspBody">
                        <Button
                            id="bid_history"
                            onClick={() =>
                                getAllExportDownload({
                                    action: 'export',
                                    order: 'asc',
                                    orderby: '',
                                    search: formikData.values.get_bid_auction
                                        ? `p.auctionid=${formikData.values.filters.auctionid.value}`
                                        : `b.project_id=${formikData.values.filters.project_id.value}`,
                                    report: `bid_history`,
                                    get_bid_auction: formikData.values
                                        .get_bid_auction
                                        ? true
                                        : false,
                                    report_action: formikData.values
                                        .get_bid_auction
                                        ? 'download'
                                        : '',
                                    projectID:
                                        formikData.values.filters.project_id
                                            .value,
                                    showAuctionName: true,
                                    fields: warHouseExport,
                                    market_status:
                                        formikData.values.filters.market_status
                                            .value,
                                })
                            }
                        >
                            {' '}
                            <span className="material-icons">get_app</span>
                            Export All Data
                        </Button>

                        {formikData.values.show_declined ? (
                            <div className="text-right">
                                <FormControlLabel
                                    id={'show_declined'}
                                    label={'Show Retracted'}
                                    control={
                                        <Switch
                                            checked={showDeclined}
                                            onChange={toggleDecline}
                                        />
                                    }
                                />
                            </div>
                        ) : null}

                        <CustomTable
                            formik={formikData.values}
                            tableBody={tableBody}
                            tableValues={tableValues}
                            tableActions={tableActions}
                            allData={search_allbidhistory}
                            changeTableFunction={getAllBidHistory}
                        />
                    </div>
                </div>
                <CustomDialog
                    title={'Change Bid Status'}
                    open={changeStatus}
                    function={changeChangeStatus}
                >
                    {formikSelection.values.action === 'award' ? (
                        <h5>Are you sure you want to award the bid</h5>
                    ) : (
                        <h5>Are you sure you want to delete the bid</h5>
                    )}

                    <div className="actionWrapper">
                        <Button
                            id="bidHistory_cancel"
                            onClick={() => changeChangeStatus()}
                        >
                            Cancel
                        </Button>
                        <form
                            onSubmit={formikSelection.handleSubmit}
                            autoComplete="nofill"
                        >
                            <PrimaryButton
                                id="bidHistory_submit"
                                type="submit"
                                label="Confirm"
                            />
                        </form>
                    </div>
                </CustomDialog>
            </FullScreenPopup>
        )
    )
}

export default BidHistory
