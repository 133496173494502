import React, { useContext, useState, useEffect } from 'react'
import Layout from '../../product/components/templates/Layout'
import CustomTable from '../../product/components/molecules/CustomTable'
import TransactionContext from '../../product/context/transaction/transactionContext'
import CustomSearch from '../../product/components/molecules/CustomSearch'
import { useFormik } from 'formik'
import ReportContext from '../../product/context/report/reportContext'
import { Button } from '@mui/material'

const Transaction = (props) => {
    const reportContext = useContext(ReportContext)
    const transactionContext = useContext(TransactionContext)
    const [isLoading, setIsLoading] = useState(true)
    const {
        all_payment,
        getAllPayment,
        responseStatus: responseStatusInvoice,
        clearResponse: clearResponseInvoice,
    } = transactionContext

    let action =
        props.match.params.action !== 'all' ? props.match.params.action : null
    const { getAllExportDownload } = reportContext

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                searchterm: {
                    value: '',
                    type: 'like',
                    field: 'par.cart_id',
                },
                fromdate: {
                    value: '',
                    type: 'like',
                    field: 'par.created_at',
                },
                todate: {
                    value: '',
                    type: 'like',
                    field: 'par.created_at',
                },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllPayment(values)
        },
    })

    useEffect(() => {
        if (all_payment) {
            setTableBody(all_payment.records.length ? all_payment.records : [])
        }
        setIsLoading(false)
    }, [all_payment])

    useEffect(() => {
        setTableBody([])
        setIsLoading(true)
        formik.values.page = 1
        formik.values.filters.fromdate.value = ''
        formik.values.filters.todate.value = ''
        formik.values.filters.searchterm.value = ''

        getAllPayment(formik.values)
        /* return () => {
            setTableBody([])
        }*/
    }, [action])

    const tableValues = [
        {
            field: 'cart_id',
            type: '',
            firstChild: true,
            sort: false,
            disablePadding: false,
            label: 'Invoice ID',
        },
        {
            field: 'trans_id',
            type: '',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'Transaction ID',
        },
        {
            field: 'amount',
            type: 'price',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Amount',
        },
        {
            field: 'card_type',
            type: 'capitalize',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Card',
        },
        {
            field: 'payment_method',
            type: 'capitalize',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Payment Type',
        },
        {
            field: 'first_name,last_name',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'User Name',
        },
        {
            field: 'created_at',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Created Date',
        },
        {
            field: 'status',
            type: 'capitalize',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
    ]

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: 'Invoice Number',
                placeholder: 'Enter invoice number',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'searchterm',
                filter: true,
            },
            {
                label: 'Created From Date',
                placeholder: 'Date Ending from',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'fromdate',
                filter: true,
            },
            {
                label: 'Created To Date',
                placeholder: 'Date Ending To',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'todate',
                filter: true,
            },
        ],
    }

    const tableActions = []

    return (
        <Layout>
            <div className="dashboard invoice">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <Button
                    onClick={() =>
                        getAllExportDownload({
                            action: 'export',
                            order: 'asc',
                            orderby: '',
                            search: 'all',
                            report: `transaction_list`,
                        })
                    }
                >
                    <span className="material-icons">get_app</span>Export All
                    Data
                </Button>
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_payment}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    changeTableFunction={getAllPayment}
                />
            </div>
        </Layout>
    )
}

export default Transaction
