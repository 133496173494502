import React from 'react'
import Skeletons from '../../Skeletons'

function SidePanelSkeleton() {
    return (
        <div className="sidePanelSkeleton">
            <Skeletons type="input" />
            <Skeletons type="input" />
            <Skeletons type="input" />
            <Skeletons type="input" />
            <Skeletons type="input" />
            <Skeletons type="input" />
            <Skeletons type="input" />
            <Skeletons type="input" />
            <Skeletons type="input" />
            <Skeletons type="input" />
        </div>
    )
}

export default SidePanelSkeleton
