import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../product/components/templates/Layout'
import CustomTable from '../../product/components/molecules/CustomTable'
import CustomSearch from '../../product/components/molecules/CustomSearch'
import { Button } from '@mui/material'
import { handleRedirectInternal } from '../../product/common/components'
import { useFormik } from 'formik'
import AlertContext from '../../product/context/alert/alertContext'
import CommunicationContext from '../../product/context/communication/communicationContext'

import TemplateManage from './TemplateManage'
import Loaders from '../../product/components/molecules/Loaders'

const AllTemplate = (props) => {
    const alertContext = useContext(AlertContext)
    const communicationContext = useContext(CommunicationContext)
    const history = useHistory()
    const [isLoading, setIsLoading] = useState(true)
    const { setAlert } = alertContext
    const {
        all_templates,
        getAllSearchTemplates,
        responseStatus: responseStatusCommuncation,
        clearResponse: clearResponseCommuncation,
    } = communicationContext

    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
        action: '',
    })

    let action = props.match.params.action

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id, action: action })
        } else {
            setManage({ popup, status: 'new', id: 0, action: action })
        }
    }

    const [tableBody, setTableBody] = useState([])
    const formik = useFormik({
        initialValues: {
            action: action,
            searchterm: '',
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllSearchTemplates(values)
        },
    })

    useEffect(() => {
        if (all_templates) {
            setTableBody(
                all_templates.records.length ? all_templates.records : []
            )
        }
        setIsLoading(false)
    }, [all_templates])

    useEffect(() => {
        setTableBody([])
        setIsLoading(true)
        formik.values.action = action
        formik.values.page = 1
        formik.values.searchterm = ''
        getAllSearchTemplates(formik.values)
        /*  return () => {
            setTableBody([])
        }*/
    }, [action])

    useEffect(() => {
        if (responseStatusCommuncation) {
            if (
                responseStatusCommuncation.from === 'Communicationtemplates' &&
                responseStatusCommuncation.status === 'success'
            ) {
                toggleFullScreenPopup(false)
                setTableBody([])
                formik.values.action = action
                formik.values.page = 1
                formik.values.searchterm = ''
                getAllSearchTemplates(formik.values)
            }
        }
    }, [responseStatusCommuncation])

    const tableValues = [
        {
            field: 'subject',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Subject',
        },
    ]

    if (action === 'emailLogs') {
        tableValues.push({
            field: 'email',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Email',
        })
    } else {
        tableValues.push({
            field: 'phone',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Phone number',
        })
    }
    tableValues.push({
        field: 'date_sent',
        type: 'datetime',
        firstChild: true,
        sort: true,
        disablePadding: false,
        label: 'Date sent',
    })

    const searchInfo = [
        {
            label: 'Subject',
            placeholder: 'Enter Subject',
            class: 'col-md-4 col-sm-6 col-12',
            type: 'text',
            name: 'searchterm',
            formik: formik,
        },
    ]

    if (action === 'emailLogs') {
        searchInfo.push({
            label: 'Email',
            placeholder: 'Enter Email',
            class: 'col-md-4 col-sm-6 col-12',
            type: 'text',
            name: 'emailsearch',
            formik: formik,
        })
    } else {
        searchInfo.push({
            label: 'Phone',
            placeholder: 'Enter Subject',
            class: 'col-md-4 col-sm-6 col-12',
            type: 'text',
            name: 'phonesearch',
            formik: formik,
        })
    }

    return (
        <Layout>
            <div className="dashboard communications">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={[]}
                    allData={all_templates}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    changeTableFunction={getAllSearchTemplates}
                />
            </div>
            <TemplateManage data={manage} function={toggleFullScreenPopup} />
        </Layout>
    )
}
export default AllTemplate
