import React, { useContext, useState, useEffect } from 'react'

import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import {
    mapData,
    handleRedirectInternal,
} from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import SettingContext from '../../product/context/setting/settingContext'

const TemplateManage = (props) => {
    const settingContext = useContext(SettingContext)

    const {
        single_configuration,
        getSingleConfiguration,
        configurationAction,
    } = settingContext

    const [product, setProduct] = useState(null)

    const data = props.data
    const toggleFullScreenPopup = props.function

    const validationArray = Yup.object({
        value: Yup.mixed().required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            value: '',
            type: '',
            question: '',
            variable: '',
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            configurationAction(values)
        },
    })

    // useEffect(() => {
    //     resetFrom()
    // }, [props])

    useEffect(() => {
        if (data.status === 'edit') {
            formik.values.id = data.id
            getSingleConfiguration(formik.values)
        } else {
            resetFrom()
        }
    }, [data.id])

    useEffect(() => {
        if (single_configuration.record && data.status === 'edit') {
            const template = single_configuration.record

            setProduct(template)
            formik.values.id = template.id
            formik.values.type = template.type
            formik.values.question = template.question
            formik.values.variable = template.variable

            if (template.type === 'checkbox') {
                formik.setFieldValue(
                    'value',
                    template.value === '1' ? true : false
                )
            } else {
                formik.setFieldValue('value', template.value)
            }
        } else {
            resetFrom()
        }
    }, [single_configuration])

    console.log('template.value', formik.values.value)

    const resetFrom = () => {
        formik.values.id = 0
        formik.values.value = ''
        formik.values.type = ''
        formik.values.question = ''
        formik.values.variable = ''
        setProduct(null)
        formik.handleReset()
    }

    const emailTemplate = []

    if (data.status === 'new') {
        emailTemplate.push(
            {
                label: 'Variable',
                placeholder: 'Enter Variable',
                class: 'col-12 mb-4',
                type: 'text',
                shrink: true,
                name: 'variable',
                formik: formik,
            },
            {
                label: 'Question',
                placeholder: 'Enter Question',
                class: 'col-12 mb-4',
                type: 'text',
                shrink: true,
                name: 'question',
                formik: formik,
            },
            {
                label: 'Type',
                placeholder: 'Enter type',
                class: 'col-12 mb-4',
                type: 'select',
                options: [
                    { value: 'checkbox', show: 'Checkbox' },
                    { value: 'text', show: 'Text' },
                    { value: 'number', show: 'Number' },
                ],
                name: 'type',
                formik: formik,
            }
        )
    }
    emailTemplate.push({
        label: product ? product.question : 'Value *',
        placeholder: `Enter ${product ? product.question : 'Value'}`,
        class: 'col-12',
        name: 'value',
        type: product ? product.type : '',
        formik: formik,
    })

    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new'
                    ? 'ADD CONFIGURATION'
                    : `EDIT CONFIGURATION: ${product && product.variable}`
            }
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        <div className="row">
                            {Object.values(mapData(emailTemplate))}
                        </div>

                        <div className="actionButton d-flex justify-content-center align-items-center">
                            <SecondaryButton
                                label="Cancel"
                                onClick={() => toggleFullScreenPopup(false)}
                            />
                            <PrimaryButton type="submit" label="Submit" />
                        </div>
                    </form>
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default TemplateManage
