import React, { useRef, useEffect, useState } from 'react'
import EmailEditor from 'react-email-editor'

const EmailEditorForm = (props, ref) => {
    const emailEditorRef = useRef(null)

    const [isReady, setReady] = useState(false)

    const onLoad = () => {
        // editor instance is created
        // you can load your template here;
        // const templateJson = {};
        if (emailEditorRef?.current) {
            if (
                props.formik &&
                props.formik.values &&
                props.formik.values[props.name]
            ) {
                emailEditorRef.current.editor.loadDesign(
                    props.formik.values[props.name]
                )
            }
        }
        setReady(true)
    }

    const onReady = () => {
        emailEditorRef?.current?.addEventListener(
            'design:updated',
            function (data) {
                // Design is updated by the user
                var type = data.type // body, row, content
                var item = data.item
                var changes = data.changes
                if (emailEditorRef.current) {
                    emailEditorRef.current.editor.exportHtml((data) => {
                        const { design, html } = data
                        props.formik.setFieldValue(
                            props.data.name,
                            JSON.stringify(design)
                        )
                        props.formik.setFieldValue(props.data.htmlName, html)
                    })
                }
            }
        )
    }

    useEffect(() => {
        if (isReady) {
            if (
                props.formik &&
                props.formik.values &&
                props.formik.values[props.name]
            ) {
                emailEditorRef.current.editor.loadDesign(
                    props.formik.values[props.name]
                )
            }
        }
    }, [props.reload, isReady])

    return (
        <div>
            <EmailEditor
                ref={emailEditorRef}
                onLoad={onLoad}
                onReady={onReady}
            />
        </div>
    )
}

export default EmailEditorForm
