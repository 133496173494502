import { Button } from '@mui/material'
import React, { useState, useRef, useEffect, useContext } from 'react'
// import CustomInput from '../../atoms/Inputs/CustomInput'
// import PrimaryButton from '../../atoms/PrimaryButton'
import { currencyFormat } from '../../custom/common/components'
const CartItem = (props) => {
    const [product, setProduct] = useState()

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    return (
        <>
            {product ? (
                <>
                    <div className="cartItem d-flex justify-content-between align-items-start">
                        <div className="itemInfo d-flex justify-content-start">
                            <div className="pclImg">
                                <img
                                    src={
                                        global.site_url +
                                        '/uploads/product/' +
                                        product.projects_avatar
                                    }
                                    alt={product.title}
                                />
                            </div>
                            <div className="listContent">
                                <h2 className="listProdTitle">
                                    {product.projects_title}
                                </h2>
                                {props.from == 'buynowcart' ||
                                props.from == 'buynowcheckout' ||
                                (props.from == 'invoice' &&
                                    product.buynow_autype === 'buynow') ? (
                                    <>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>Amount:</span>
                                                <span>
                                                    {currencyFormat(
                                                        product.amount
                                                    )}
                                                </span>
                                            </h5>
                                            <h5>
                                                <span>Quantity:</span>{' '}
                                                <span>{product.qty}</span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>Sub-total:</span>
                                                <span>
                                                    {currencyFormat(
                                                        product.per_total
                                                    )}
                                                </span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>
                                                    VAT({product.tax_percent}%):
                                                </span>
                                                <span>
                                                    {currencyFormat(
                                                        product.total_tax
                                                    )}
                                                </span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>Total:</span>
                                                <span>
                                                    {currencyFormat(
                                                        product.total_amount
                                                    )}
                                                </span>
                                            </h5>
                                        </div>
                                    </>
                                ) : props.from == 'cart' ||
                                  props.from == 'checkout' ||
                                  (props.from == 'invoice' &&
                                      product.buynow_autype === 'auction') ? (
                                    <>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>Sub-total:</span>
                                                <span>
                                                    {currencyFormat(
                                                        product.per_total
                                                    )}
                                                </span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>
                                                    VAT ({product.tax_percent}
                                                    %):
                                                </span>
                                                <span>
                                                    {currencyFormat(
                                                        product.total_tax
                                                    )}
                                                </span>
                                            </h5>
                                        </div>
                                        <div className="listLotInfo d-flex justify-content-start align-items-center">
                                            <h5>
                                                <span>Total:</span>
                                                <span>
                                                    {currencyFormat(
                                                        product.total_amount
                                                    )}
                                                </span>
                                            </h5>
                                        </div>
                                    </>
                                ) : null}
                            </div>
                        </div>

                        <div className="listActBtn"></div>
                    </div>
                </>
            ) : null}
        </>
    )
}
export default CartItem
