import React, { useReducer } from 'react'
import ProductContext from './productContext'
import ProductReducer from './productReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_PRODUCTS,
    GET_ALL_ACTIVE_AUCTIONS,
    GET_SINGLE_PROJECT,
    GET_ALL_BID_HISTORY,
    GET_ALL_TTWBID_HISTORY,
    RESPONSE_STATUS,
    RECONCILIATION,
    CLEAR_RESPONSE,
    GET_ALL_ROYALTY_INPUTS,
    GET_ALL_OFFERS,
} from './productTypes'

const ProductState = (props) => {
    const initialState = {
        all_products: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        all_offers: {
            records: [],
            totalRecords: 0,
            from: '',
        },
        all_active_auctions: {
            activelots: [],
        },
        single_project: {
            record: {},
        },
        search_allbidhistory: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        reconcilation: null,
        search_allttwbidhistory: [],
        responseStatus: null,
        all_royalty_inputs: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
    }

    const [state, dispatch] = useReducer(ProductReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllMakeOffers = async (formData, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'adminoffers', formData, '', 'product'),
            ])
            from = from ? from : 'getAllOffers'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_OFFERS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllAdminOffers = async (formData, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'adminoffers', formData, '', 'product'),
            ])
            from = from ? from : 'getAllAdminOffers'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_OFFERS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllOffers = async (formData, from) => {
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'index/offer/offer/getAllOffers',
                    formData,
                    '',
                    '',
                    '',
                    true
                ),
            ])
            from = from ? from : 'getAllOffers'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_OFFERS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const getAllProducts = async (formData, from) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'listings', formData, '', 'product'),
            ])
            from = from ? from : 'Communicationtemplates'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_PRODUCTS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: from,
                    },
                })
                dispatch({
                    type: GET_ALL_ACTIVE_AUCTIONS,
                    payload: res.data.data.responseData.activelots.length
                        ? res.data.data.responseData.activelots
                        : [],
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const changeProductStatus = async (formData, from) => {
        try {
            //const from = 'productchange'
            from = from ? from : 'productchange'
            const [res] = await Promise.all([
                apiCall('post', 'changeStatus', formData, '', 'product'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const changeOfferStatus = async (formData, from) => {
        try {
            //const from = 'productchange'
            from = from ? from : 'offerChange'
            const [res] = await Promise.all([
                apiCall('post', 'changeOfferStatus', formData, '', 'product'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const projectAction = async (formData) => {
        try {
            const from = 'projectAction'
            const [res] = await Promise.all([
                apiCall('post', 'projectAction', formData, '', 'product'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const addItemToCart = async (formData) => {
        try {
            const from = 'addItemToCart'
            const [res] = await Promise.all([
                apiCall('post', 'addItemToCart', formData, '', 'product'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const bulkProjectAction = async (formData) => {
        const from = 'bulkProjectAction'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'product'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const bulkProjectImageAction = async (formData) => {
        const from = 'bulkProjectImageAction'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'product'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllBidHistory = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'bidhistory', formData, '', 'product'),
            ])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_BID_HISTORY,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleProject = async (formData) => {
        try {
            const from = 'getSingleProject'
            const [res] = await Promise.all([
                apiCall('post', 'getSingleProject', formData, '', 'product'),
            ])
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_PROJECT,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    // only for songvest
    const getReconsolationData = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'reconsolationReport', formData, '', 'product'),
            ])
            const from = 'reconsolationReport'
            if (res.data.status === 'success') {
                dispatch({
                    type: RECONCILIATION,
                    payload: res.data.data.responseData,
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            console.log('err', err)
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllTtwBidHistory = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'ttwbidhistory', formData, '', 'product'),
            ])
            const from = 'search'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_TTWBID_HISTORY,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        sorts: res.data.data.responseData.sorts,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            console.log('err', err)
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    const bidHistoryUpdate = async (formData) => {
        try {
            const from = 'bidHistoryUpdate'
            const [res] = await Promise.all([
                apiCall('post', 'ttwbidhistoryUpdate', formData, '', 'product'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const addHistoricValue = async (formData, finishEdit) => {
        try {
            const from = 'addHistoricValue'
            const [res] = await Promise.all([
                apiCall('post', 'royalty/action', formData, '', '', '', true),
            ])
            if (finishEdit === 1) {
                resp.commonResponse(res.data, from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getOfferingsListing = async (formData) => {
        try {
            const from = 'getOfferingsListing'

            let apiPath = 'royalty/search'
            if (formData.list && formData.list == 'pendingQuarterWise') {
                apiPath = 'royalty/pending'
            }

            const [res] = await Promise.all([
                apiCall('post', apiPath, formData, '', '', '', true),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_ROYALTY_INPUTS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                        from: from,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const processRoyaltyPayments = async (formData, noAlert) => {
        try {
            const from = 'processRoyaltyPayments'
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'royalty/process/payment',
                    formData,
                    '',
                    '',
                    '',
                    true
                ),
            ])
            //if (finishEdit === 1) {
            resp.commonResponse(res.data, from, noAlert)
            //}
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const cancelAllBids = async (formData, noAlert) => {
        try {
            const from = 'cancelAllBids'
            const [res] = await Promise.all([
                apiCall('post', 'cancelAllBids', formData, '', 'product'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const deletePendingRoyaltyPayments = async (formData, finishEdit) => {
        try {
            const from = 'deletePendingRoyaltyPayments'
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'royalty/delete/pending/payment',
                    formData,
                    '',
                    '',
                    '',
                    true
                ),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    return (
        <ProductContext.Provider
            value={{
                // buyer_allproducts: state.buyer_allproducts,
                all_products: state.all_products,
                responseStatus: state.responseStatus,
                activelots: state.activelots,
                single_project: state.single_project,
                search_allbidhistory: state.search_allbidhistory,
                search_allttwbidhistory: state.search_allttwbidhistory,
                reconcilation: state.reconcilation,
                all_royalty_inputs: state.all_royalty_inputs,
                all_offers: state.all_offers,
                clearResponse,
                getAllProducts,
                getAllOffers,
                getAllAdminOffers,
                changeProductStatus,
                changeOfferStatus,
                getReconsolationData,
                projectAction,
                getSingleProject,
                bulkProjectAction,
                bulkProjectImageAction,
                getAllBidHistory,
                getAllTtwBidHistory,
                bidHistoryUpdate,
                addItemToCart,
                addHistoricValue,
                getOfferingsListing,
                processRoyaltyPayments,
                cancelAllBids,
                deletePendingRoyaltyPayments,
            }}
        >
            {props.children}
        </ProductContext.Provider>
    )
}

export default ProductState
