import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
    palette: {
        primary: {
            main: '#1e1450',
        },
        secondary: {
            main: '#003e6c',
        },
    },
    components: {
        PrimaryButton: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: '#1e1450',
                    },
                },
            },
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderColor: '#c4c4c4',
                    color: '#5d5d5d',
                    boxShadow: 'none',
                    textTransform: 'uppercase',
                    '&:hover': {
                        opacity: '0.9',
                        borderColor: '#c4c4c4',
                        backgroundColor: '#f8f8f8',
                        boxShadow: 'none',
                    },
                },
            },
        },
    },
})
