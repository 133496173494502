import React, { useContext, useState, useEffect } from 'react'

import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import AuctionContext from '../../product/context/auction/auctionContext'
import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
} from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useDropzone } from 'react-dropzone'
import CustomTable from '../../product/components/molecules/CustomTable'
import BidDepositContext from '../../product/context/bid_deposit/bidDepositContext'
import AlertContext from '../../product/context/alert/alertContext'

const WalletManage = (props) => {
    const bidDepositContext = useContext(BidDepositContext)
    const [isLoading, setIsLoading] = useState(false)
    const { search_allprojectdeposit, getDepositHistory } = bidDepositContext

    const [tableBody, setTableBody] = useState([])

    const data = props.data
    const toggleFullScreenPopup = props.function

    const formikData = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            id: 0,
            filters: {},
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getDepositHistory(values)
        },
    })
    useEffect(() => {
        return () => setIsLoading(false)
    }, [])
    useEffect(() => {
        if (data.status === 'edit') {
            formikData.values.id = data.id
            getDepositHistory(formikData.values)
        }
    }, [data.id])

    useEffect(() => {
        if (search_allprojectdeposit) {
            setTableBody(
                search_allprojectdeposit.records.length
                    ? search_allprojectdeposit.records
                    : []
            )
        }
        setIsLoading(false)
    }, [search_allprojectdeposit])

    const tableValues = [
        {
            field: 'date_added',
            type: 'datetime',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Date',
        },
        {
            field: 'users_first_name,users_last_name',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'User/Company',
        },
        {
            field: 'users_email',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            field: 'amount',
            type: 'price',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Amount',
        },

        {
            field: 'refunded',
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'refunded',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Refunded',
                },
                false: {
                    type: 'text',
                    value: 'Deposited',
                },
            },
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Type',
        },
    ]

    const tableActions = []

    return (
        <FullScreenPopup
            modaltitle={`Deposit History`}
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="wallet">
                <div className="fspBody">
                    {isLoading ? (
                        <div>Loading...</div>
                    ) : (
                        <CustomTable
                            formik={formikData.values}
                            tableBody={tableBody}
                            tableValues={tableValues}
                            tableActions={tableActions}
                            allData={search_allprojectdeposit}
                            changeTableFunction={getDepositHistory}
                        />
                    )}
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default WalletManage
