import React, { useContext, useState, useEffect } from 'react'

import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import {
    mapData,
    handleRedirectInternal,
} from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CommunicationContext from '../../product/context/communication/communicationContext'

const TemplateManage = (props) => {
    const communicationContext = useContext(CommunicationContext)
    const [isLoading, setIsLoading] = useState(true)
    const { single_template, getSingleTemplate, templateAction } =
        communicationContext

    const data = props.data
    const toggleFullScreenPopup = props.function

    const validationArray = Yup.object({
        template: Yup.string().required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            id: 0,
            subject: '',
            template: '',
            title: '',
            method: data.action,
        },
        validateOnBlur: false,
        validationSchema: validationArray,
        onSubmit: (values) => {
            setIsLoading(true)
            templateAction(values)
        },
    })

    useEffect(() => {
        resetFrom()
    }, [props])

    useEffect(() => {
        if (data.status === 'edit') {
            formik.values.id = data.id
            getSingleTemplate(formik.values)
        } else {
            resetFrom()
        }
    }, [data.id])

    useEffect(() => {
        if (single_template.record && data.status === 'edit') {
            const template = single_template.record
            formik.setFieldValue('template', template.template)
            formik.setFieldValue('title', template.title)
            formik.setFieldValue('subject', template.subject)
            formik.setFieldValue('id', data.id)
            setIsLoading(false)
        } else {
            resetFrom()
            data.status !== 'edit' && setIsLoading(false)
        }
    }, [single_template])

    const resetFrom = () => {
        formik.values.id = 0
        formik.values.subject = ''
        formik.values.template = ''
        formik.values.title = ''
        formik.values.method = data.action
        formik.handleReset()
    }

    const emailTemplate = []

    if (data.status === 'new') {
        emailTemplate.push({
            label: 'Title',
            placeholder: 'Enter Title',
            class: 'col-12',
            type: 'text',
            shrink: true,
            name: 'title',
            formik: formik,
        })
    }
    emailTemplate.push(
        {
            label: 'Subject',
            placeholder: 'Enter subject',
            class: 'col-12',
            type: 'text',
            shrink: true,
            name: 'subject',
            formik: formik,
        },
        {
            class: 'col-12',
            name: 'template',
            type: 'ckeditor',
            formik: formik,
        }
    )

    const smsTemplate = []

    if (data.status === 'new') {
        smsTemplate.push({
            label: 'Title',
            placeholder: 'Enter Title',
            class: 'col-12',
            type: 'text',
            shrink: true,
            name: 'title',
            formik: formik,
        })
    }
    smsTemplate.push({
        label: 'Template',
        placeholder: 'Enter Template',
        class: 'col-12',
        type: 'text',
        name: 'template',
        formik: formik,
    })
    return (
        <FullScreenPopup
            modaltitle={
                data.status === 'new'
                    ? 'ADD NEW TEMPLATE'
                    : `EDIT TEMPLATE: ${single_template.record.title}`
            }
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
            disableEnforceFocus={true}
        >
            <div className="addUserModal">
                <div className="fspBody">
                    <form onSubmit={formik.handleSubmit} autoComplete="nofill">
                        {isLoading && <div>Loading....</div>}
                        {!isLoading &&
                            ((data.status === 'edit' &&
                                formik.values.template) ||
                                data.status !== 'edit') && (
                                <>
                                    <div className="row">
                                        {data.action === 'email'
                                            ? Object.values(
                                                  mapData(emailTemplate)
                                              )
                                            : Object.values(
                                                  mapData(smsTemplate)
                                              )}
                                    </div>
                                    <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                                        <SecondaryButton
                                            label="Cancel"
                                            onClick={() =>
                                                toggleFullScreenPopup(false)
                                            }
                                        />
                                        <PrimaryButton
                                            type="submit"
                                            label="Submit"
                                        />
                                    </div>
                                </>
                            )}
                    </form>
                </div>
            </div>
        </FullScreenPopup>
    )
}

export default TemplateManage
