import React from 'react'
import TextField from '@mui/material/TextField'
import Tooltip from '@mui/material/Tooltip'
import InputMask from 'react-input-mask'

const CustomMaskInput = (props) => {
    const shrink = props.shrink ? props.shrink.toString() : 'false'
    return (
        <div className="customInput" style={props.inputStyle}>
            {props.upperLabel ? (
                <>
                    <label>{props.label}</label>
                    <TextField
                        value={props.value}
                        autoFocus={props.autoFocus}
                        name={props.name}
                        onChange={props.onChange}
                        onBlur={props.onBlur}
                        InputProps={{
                            inputProps: props.inputProps,
                            startAdornment: props.startAdornment,
                            endAdornment: props.endAdornment,
                            shrink: shrink,
                        }}
                        id={props.id}
                        type={props.type}
                        size={props.size}
                        disabled={props.disabled}
                        variant={props.variant ? props.variant : 'outlined'}
                        placeholder={props.placeholder}
                        error={props.error}
                        helperText={props.helperText}
                    />
                </>
            ) : (
                <Tooltip
                    title={props.tooltiptitle}
                    arrow={props.tooltiptitle ? true : false}
                    placement="top"
                    disableHoverListener={true}
                    disableFocusListener={props.tooltiptitle ? false : true}
                >
                    <InputMask
                        mask={props.maskValue}
                        value={props.value}
                        onChange={props.onChange}
                    >
                        {(inputProps) => (
                            <TextField
                                {...inputProps}
                                autoFocus={props.autoFocus}
                                name={props.name}
                                InputProps={{
                                    inputProps: props.inputProps,
                                    startAdornment: props.startAdornment,
                                    endAdornment: props.endAdornment,
                                    shrink: shrink,
                                }}
                                id={props.id}
                                label={props.label}
                                type={props.type}
                                size={props.size}
                                disabled={props.disabled}
                                variant={
                                    props.variant ? props.variant : 'outlined'
                                }
                                placeholder={props.placeholder}
                                error={props.error}
                                helperText={props.helperText}
                            />
                        )}
                    </InputMask>
                </Tooltip>
            )}
        </div>
    )
}

export default CustomMaskInput
