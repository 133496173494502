import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import './FullScreenPopup.css'

const PREFIX = 'FullScreenPopup'

const classes = {
    appBar: `${PREFIX}-appBar`,
    title: `${PREFIX}-title`,
}

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.appBar}`]: {
        position: 'relative',
    },

    [`& .${classes.title}`]: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}))

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />
})

const FullScreenPopup = ({
    open,
    handleClose,
    children,
    modaltitle,
    className,
    disableEnforceFocus = false,
}) => {
    // useEffect(() => {
    //     console.log('asdasdasdad', children)
    //     // window.scrollTo(0, 0)
    // }, [children])
    return (
        <Root>
            <Dialog
                fullScreen
                className={`fullScreenPopup ${className}`}
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
                disableEnforceFocus
            >
                <AppBar className={`${classes.appBar} noPrint`}>
                    <Toolbar className="fspToolbar">
                        <IconButton
                            edge="start"
                            color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            size="large"
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {modaltitle}
                        </Typography>
                    </Toolbar>
                </AppBar>
                <div className="modalChildren">{children}</div>
            </Dialog>
        </Root>
    )
}

export default FullScreenPopup
