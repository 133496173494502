import React, { useContext, useState, useEffect } from 'react'

import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import {
    mapData,
    capitalize,
    dateTimeFormatFunction,
    handleRedirectInternal,
} from '../../product/common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { currencyFormat } from '../../custom/common/components'
import CartContext from '../../product/context/cart/cartContext'
import CartItem from './cartItem'
import './PaymentManage.css'
const CreditsManage = (props) => {
    const cartContext = useContext(CartContext)
    const [viewContent, setViewContent] = useState(null)
    const [isLoading, setIsLoading] = useState(true)
    const {
        search_singlecart,
        getSingleCartProducts,
        payCartWallet,
        payCartOffline,
    } = cartContext

    const data = props.data
    const toggleFullScreenPopup = props.function

    const formik = useFormik({
        initialValues: {
            invoice_id: 0,
            user_id: 0,
        },
    })
    let [cartProducts, setCartProducts] = useState([])
    let [cartValues, setCartValues] = useState({})

    useEffect(() => {
        setCartProducts([])
        setCartValues({})
        return () => {
            setCartProducts([])
            setCartValues({})
        }
    }, [])

    const resetFrom = () => {
        setViewContent(null)
    }

    useEffect(() => {
        if (data.status === 'edit' || data.status === 'view') {
            formik.values.invoice_id = data.id
            formikPayment.values.invoice_id = data.id
            formikPayment.values.user_id = data.sid
            formik.values.user_id = data.sid
            getSingleCartProducts(formik.values)
        } else {
            resetFrom()
        }
    }, [data.id])

    const validationPayment = Yup.object({
        appointmentID: Yup.string(),
        pickupDay: Yup.string().when(['appointmentID'], {
            is: (a) => !a,
            then: Yup.string().required('Required'),
        }),
        pickupTime: Yup.string().when(['appointmentID'], {
            is: (a) => !a,
            then: Yup.string().required('Required'),
        }),
        payment_method: Yup.string().required('Required'),
        partial: Yup.boolean(),
        amount: Yup.number().when('partial', {
            is: true,
            then: Yup.number()
                .max(
                    cartValues ? cartValues.total_due_amount : 0,
                    `Max. Amount ${
                        cartValues
                            ? currencyFormat(cartValues.total_due_amount)
                            : currencyFormat(0)
                    }`
                )
                .min(
                    cartValues ? (cartValues.total_due_amount / 100) * 25 : 0,
                    `Min. Amount ${
                        cartValues
                            ? currencyFormat(
                                  (cartValues.total_due_amount / 100) * 25
                              )
                            : currencyFormat(0)
                    }`
                )
                .required('Enter Payment Amount'),
        }),
    })

    const formikPayment = useFormik({
        initialValues: {
            invoice_id: data.id,
            appointmentID: '',
            appointmentTime: '',
            pickupDay: '',
            pickupTime: '',
            payment_method: '',
            pay_type: 'auction',
            amount: 0,
            partial: false,
            user_id: 0,
            // kount_session_id: window.kount_session_id.MercSessId,
        },
        validateOnBlur: false,
        validationSchema: validationPayment,
        onSubmit: (values) => {
            setIsLoading(true)
            formikPayment.setSubmitting(true)
            let appointmentTime = ''
            if (values.pickupDay && values.pickupTime) {
                let pickupChanged = values.pickupDay + ' ' + values.pickupTime
                appointmentTime = moment(
                    pickupChanged,
                    'MM-DD-YYYY HH:mm'
                ).format()
            }

            let formData = {
                invoice_id: values.invoice_id,
                payment_object: {
                    id: values.appointmentID,
                    appointmentTime: appointmentTime,
                    invoice_id: values.invoice_id,
                },
                payment_method: values.payment_method,
                pay_type: values.pay_type,
                amount: values.amount,
                user_id: values.user_id,
            }

            if (values.payment_method === 'wallet') {
                payCartWallet(formData)
            } else if (values.payment_method === 'offline') {
                payCartOffline(formData)
            }
        },
    })

    const modeOfPayment = [
        {
            label: 'Select Mode of Payment',
            placeholder: 'Select Mode of Payment',
            type: 'select',
            class: 'col-12',
            options: [
                { value: 'offline', show: 'Offline' },
                { value: 'wallet', show: 'Wallet' },
            ],
            name: 'payment_method',
            formik: formikPayment,
        },
    ]
    if (false && formikPayment.values.payment_method !== 'offline') {
        modeOfPayment.push(
            {
                label: t('make_a_partial_payment'),
                placeholder: t('make_a_partial_payment'),
                type: 'switch',
                class: 'col-12',
                name: 'partial',
                formik: formikPayment,
            },
            {
                label: t('enter_amount'),
                name: 'amount',
                type: 'number',
                placeholder: t('enter_payment_amount'),
                class: `col-12 ${formikPayment.values.partial ? '' : 'd-none'}`,
                formik: formikPayment,
            }
        )
    }

    useEffect(() => {
        setCartProducts(
            search_singlecart.cartrecords.length
                ? search_singlecart.cartrecords
                : []
        )
        setCartValues(search_singlecart.cartvalues)
        formikPayment.values.appointmentID = search_singlecart.cartrecords
            .length
            ? search_singlecart.cartrecords[0].appointment_id
            : []
        formikPayment.values.user_id = search_singlecart.cartrecords.length
            ? search_singlecart.cartrecords[0].user_id
            : []

        formikPayment.values.amount = search_singlecart.cartvalues
            ? search_singlecart.cartvalues.total_due_amount
            : []
        setIsLoading(false)
    }, [search_singlecart])
    useEffect(() => {
        return () => {
            setIsLoading(false)
        }
    }, [])
    return (
        <FullScreenPopup
            modaltitle={`Manage Payment`}
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <form onSubmit={formikPayment.handleSubmit} autoComplete="nofill">
                <div className="checkout container-lg">
                    <div className="checkoutCnt d-flex justify-content-between">
                        <div className="checkoutLt">
                            <div className="review">
                                <div className="d-flex align-items-center mb-3">
                                    <h1 className="checkoutHead m-0">
                                        Review order
                                    </h1>
                                    <span>{cartProducts.length} items</span>
                                </div>
                                {cartProducts.length ? (
                                    <div className="cartProducts">
                                        <div>
                                            {cartProducts.map((data, index) => (
                                                <CartItem
                                                    key={index}
                                                    data={data}
                                                    from="checkout"
                                                />
                                            ))}
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        <div className="checkoutRt">
                            <div className="orderSummary">
                                <div>
                                    <h3>Order Summary</h3>
                                    <p>
                                        <label>Items:</label>
                                        <span>{cartProducts.length}</span>
                                    </p>
                                    <p>
                                        <label>Sub Total:</label>
                                        <span>
                                            {currencyFormat(
                                                cartValues.per_total
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <label>VAT: </label>
                                        <span>
                                            {currencyFormat(
                                                cartValues.total_tax
                                            )}
                                        </span>
                                    </p>
                                    <p>
                                        <label>Total Amount:</label>
                                        <span>
                                            {currencyFormat(
                                                cartValues.total_amount
                                            )}
                                        </span>
                                    </p>
                                    {/* <Tooltip title="You can choose to make a partial payment of 25% and above of your total amount now and pay the remaining amount later."></Tooltip> */}
                                    {cartValues.total_deposit ? (
                                        <p>
                                            <label>Total Deposit:</label>
                                            <span>
                                                {currencyFormat(
                                                    cartValues.total_deposit
                                                )}
                                            </span>
                                        </p>
                                    ) : null}
                                    {cartValues.total_paid ? (
                                        <p>
                                            <label>Total paid:</label>
                                            <span>
                                                {currencyFormat(
                                                    cartValues.total_paid
                                                )}
                                            </span>
                                        </p>
                                    ) : null}
                                    {cartValues.total_due_amount ? (
                                        <p>
                                            <label>Due Amount:</label>
                                            <span>
                                                {currencyFormat(
                                                    cartValues.total_due_amount
                                                )}
                                            </span>
                                        </p>
                                    ) : null}
                                    <div className="row">
                                        {mapData(modeOfPayment)}
                                    </div>
                                    {isLoading && <div>Loading...</div>}
                                    <PrimaryButton
                                        type="submit"
                                        label={'Make Payment'}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </FullScreenPopup>
    )
}

export default CreditsManage
