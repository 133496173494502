import React, { useState, useContext, useEffect, useRef } from 'react'
import ImageGallery from 'react-image-gallery'
import openSocket from 'socket.io-client'
import './ProductView.css'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'
import 'react-image-gallery/styles/css/image-gallery.css'
import { Breadcrumbs, Drawer, Typography } from '@mui/material'
import { Link } from 'react-router-dom'
import ProductContext from '../../product/context/userProduct/productContext'
import AuctionContext from '../../product/context/auction/auctionContext'
import { dateFormatFront } from '../../product/common/components'
import NoRecordsFound from '../../product/components/atoms/NoRecordsFound'
import Timer from '../../product/common/timer'
import ListView from '../../custom/components/molecules/ProductCard/ListView'
import GridView from '../../custom/components/molecules/ProductCard/GridView'
import ProductView from '../../custom/components/organisms/ProductView'
import { Button } from '@mui/material'
import { DirectAPICAll } from '../../product/common/components'

import { socket, reInitializeSocket } from '../../product/common/socket'
import ReactPlayer from 'react-player/lazy'

import { messageHandler } from '../../product/common/socketHandler'
import AlertContext from '../../product/context/alert/alertContext'
import AuthContext from '../../product/context/auth/authContext'

function LiveAuction(props) {
    const data = props.data
    const toggleFullScreenPopup = props.function

    const t = (text) => {
        text = text.charAt(0).toUpperCase() + text.slice(1)
        return text.replace(/_/g, ' ')
    }
    const alertContext = useContext(AlertContext)
    const authContext = useContext(AuthContext)

    const { getAllSearchProducts, search_allproducts } =
        useContext(ProductContext)

    const { setAlert } = alertContext
    const { user, isAuthenticated, saveSearch } = authContext

    const auctionContext = useContext(AuctionContext)
    const { single_auction, getSingleAuction } = auctionContext

    const [auctionProducts, setAuctionProducts] = useState([])
    const [auctionDetails, setAuctionDetails] = useState({})
    const [auctionImages, setAuctionImages] = useState([])
    const [auctionView, setAuctionView] = useState('Grid')
    const [state, setState] = useState({
        right: false,
        bottom: false,
        data: {},
    })
    const [viewProduct, setViewProduct] = useState([])

    useEffect(() => {
        console.log('data', data)
        if (data.status === 'edit') {
            getAllSearchProducts({
                limit: 12,
                page: 1,
                orderby: 'p.date_closed, asc',
                order: '',
                user_id: 1,
                filters: {
                    auctionid: {
                        value: [JSON.stringify(data.id)],
                        type: 'array',
                        field: 'p.auctionid',
                    },
                },
            })

            getSingleAuction({ id: data.id })
        }
    }, [data.id])

    useEffect(() => {
        let images = [
            {
                original: `${global.site_url}/uploads/auction/${auctionDetails.avatar}`,
                thumbnail: `${global.site_url}/uploads/auction/${auctionDetails.avatar}`,
            },
        ]

        setAuctionImages(images)
    }, [auctionDetails])

    useEffect(() => {
        if (single_auction) {
            setAuctionDetails(single_auction.record)
        }
    }, [single_auction])

    useEffect(() => {
        console.log('search_allproducts', search_allproducts)
        if (search_allproducts) {
            setAuctionProducts(
                search_allproducts.records.length
                    ? search_allproducts.records
                    : []
            )
        }
    }, [search_allproducts])

    const toggleDrawer = (anchor, open, data) => (event) => {
        if (
            event.type === 'keydown' &&
            (event.key === 'Tab' || event.key === 'Shift')
        ) {
            return
        }
        if (open) {
            setState({ ...state, [anchor]: open, data: data })
        } else {
            setState({ ...state, [anchor]: open, data: {} })
        }
    }

    useEffect(() => {
        if (state.right) {
            const index = auctionProducts.findIndex(
                (s) => s.id == parseInt(state.data.id, 10)
            )
            if (index !== -1) {
                setState({ ...state, data: auctionProducts[index] })
            }
        }
    }, [auctionProducts])

    useEffect(() => {
        if (auctionDetails.id) {
            init()
        }
    }, [auctionDetails])

    const config = {
        iceServers: [
            {
                urls: process.env.REACT_APP_TURN_URL,
                credential: process.env.REACT_APP_TURN_CREDENTIAL,
                username: process.env.REACT_APP_TURN_USERNAME,
            },
        ],
    }

    let [uri, setUri] = useState([])

    let peerConnection
    const videoRef = useRef()

    useEffect(() => {
        socket.on('offer', (id, description) => {
            peerConnection = new RTCPeerConnection(config)
            peerConnection
                .setRemoteDescription(description)
                .then(() => peerConnection.createAnswer())
                .then((sdp) => peerConnection.setLocalDescription(sdp))
                .then(() => {
                    socket.emit('answer', id, peerConnection.localDescription)
                })
            peerConnection.ontrack = (event) => {
                console.log(event.streams[0])
                setUri(event.streams[0])
            }
            peerConnection.onicecandidate = (event) => {
                if (event.candidate) {
                    socket.emit('candidate', id, event.candidate)
                }
            }
        })
    }, [socket])

    useEffect(() => {
        socket.on('candidate', (id, candidate) => {
            peerConnection
                .addIceCandidate(new RTCIceCandidate(candidate))
                .catch((e) => console.error(e))
        })
    }, [socket])

    useEffect(() => {
        socket.on('disconnectPeer', () => {
            peerConnection.close()
        })
    }, [socket])

    useEffect(() => {
        window.onunload = window.onbeforeunload = () => {
            socket.emit(
                'watcher-disconnect',
                localStorage.getItem('broadcasterID')
            )
            if (peerConnection) {
                peerConnection.close()
            }
            reInitializeSocket()
        }
    }, [window])

    async function init() {
        const payload = {
            auctionid: auctionDetails.id,
        }

        const data = await DirectAPICAll(
            'post',
            `${global.site_url}/api/video/newVideoStream`,
            payload
        )
        if (data?.data?.data?.responseData?.length) {
            console.log('sender_stream', data.data.data)
            localStorage.setItem(
                'broadcasterID',
                data.data.data.responseData[0].sender_stream
            )
            socket.emit('watcher', data.data.data.responseData[0].sender_stream)
        }
    }

    const viewProductRef = useRef(auctionProducts)
    const userRef = useRef(user)

    useEffect(() => {
        viewProductRef.current = auctionProducts
        userRef.current = user
    })

    const handler = (message, type) => {
        messageHandler(
            message,
            viewProductRef.current,
            userRef.current,
            setAlert,
            setAuctionProducts,
            type
        )
    }

    useEffect(() => {
        const socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, {
            transports: ['websocket'],
        })
        socket.on('realclosedupdates', (data) => {
            handler(data, 'realclosedupdates')
        })
        socket.on('bidAddtime', (data) => {
            handler(data, 'bidAddtime')
        })
        return () => {
            socket.off('realclosedupdates', (data) => {
                handler(data, 'realclosedupdates')
            })
            socket.off('bidAddtime', (data) => {
                handler(data, 'bidAddtime')
            })
            socket.disconnect()
        }
    }, [])
    return (
        <FullScreenPopup
            // modaltitle={`VIEW LOT: ${single_auction.record.title} `}
            open={data.popup}
            handleClose={() => toggleFullScreenPopup(false)}
        >
            <div className="liveAuction customContainer">
                <div className="pvTimerCnt">
                    {auctionDetails.market_status === 'open' ? (
                        <div className="pvTimerBox">
                            <span className="material-icons">timer</span>
                            <h4>
                                <Timer
                                    date_added={auctionDetails.date_added}
                                    date_closed={auctionDetails.date_closed}
                                    withText={1}
                                    endText={t('time_left') + ':'}
                                    startText={t('starts_in') + ':'}
                                ></Timer>
                            </h4>
                        </div>
                    ) : (
                        <div className="pvTimerBox">
                            <span className="material-icons">timer</span>
                            <label>{t('closed_on')}</label>
                            <h4>
                                {dateFormatFront(auctionDetails.date_closed)}
                            </h4>
                        </div>
                    )}
                </div>
                <>
                    <>
                        <div className="row auctionAssetsCnt">
                            <div className="col-lg-5 col-12 liveAucImages">
                                <ImageGallery
                                    items={auctionImages}
                                    thumbnailPosition="bottom"
                                    showNav={false}
                                    showBullets={false}
                                    showFullscreenButton={true}
                                    showPlayButton={false}
                                />
                            </div>
                            <div className="col-lg-7 col-12">
                                <div className="videoAuction">
                                    {Array.isArray(uri) ? (
                                        <div>
                                            <span className="material-icons">
                                                personal_video
                                            </span>
                                            <h5>
                                                {t(
                                                    'video_will_be_streamed_soon'
                                                )}
                                            </h5>
                                        </div>
                                    ) : (
                                        <ReactPlayer
                                            className="react-player"
                                            width="100%"
                                            height="100%"
                                            url={uri}
                                            playing
                                            stopOnUnmount={false}
                                            muted
                                            controls
                                            playsinline
                                            pip
                                        />
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-5">
                                <ul className="laInfo">
                                    <li className="descriptionInfo">
                                        <label>{t('description')}</label>
                                        <span>
                                            {auctionDetails.description}
                                        </span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12 mb-3 d-flex justify-content-between align-items-center">
                                <h2 className="laTitle">{t('auction_lots')}</h2>
                                <div className="gridListToggle">
                                    <Button
                                        className={
                                            auctionView === 'Grid'
                                                ? 'active'
                                                : ''
                                        }
                                        onClick={() => setAuctionView('Grid')}
                                    >
                                        <span className="material-icons">
                                            apps
                                        </span>
                                    </Button>
                                    <Button
                                        className={
                                            auctionView === 'List'
                                                ? 'active'
                                                : ''
                                        }
                                        onClick={() => setAuctionView('List')}
                                    >
                                        <span className="material-icons">
                                            view_list
                                        </span>
                                    </Button>
                                </div>
                            </div>
                            <div className="col-12">
                                <Drawer
                                    className="rightDrawer"
                                    anchor={'right'}
                                    open={state['right']}
                                    onClose={toggleDrawer('right', false)}
                                >
                                    <ProductView data={state.data} />
                                </Drawer>

                                <div
                                    className={`searchResults  ${auctionView}`}
                                >
                                    {auctionProducts.map((data, index) => (
                                        <>
                                            {auctionView === 'Grid' ? (
                                                <GridView
                                                    data={data}
                                                    key={index}
                                                    favId={`searchProd_${index}`}
                                                    drawerHandler={toggleDrawer(
                                                        'right',
                                                        true,
                                                        data
                                                    )}
                                                />
                                            ) : (
                                                <ListView
                                                    data={data}
                                                    key={index}
                                                    favId={`searchProd_${index}`}
                                                    drawerHandler={toggleDrawer(
                                                        'right',
                                                        true,
                                                        data
                                                    )}
                                                />
                                            )}
                                        </>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </>
                </>
            </div>
        </FullScreenPopup>
    )
}

export default LiveAuction
