import React, { useReducer } from 'react'
import UserContext from './userContext'
import UserReducer from './userReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_USERS,
    GET_SINGLE_USER,
    GET_DASHBOARD_USER,
    GET_ALL_BIDDING_USERS,
    CLEAR_RESPONSE,
    RESPONSE_STATUS,
    GET_SINGLE_OWNER,
} from './userTypes'

const UserState = (props) => {
    const initialState = {
        responseStatus: null,
        single_user: {
            record: {},
        },
        search_alldashboardproducts: {
            records: [],
            totalRecords: 0,
            setDisp: '',
            sorts: {},
        },
        all_users: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        all_bidding_users: {
            records: [],
        },
        single_owner: {
            record: {},
        },
    }

    const [state, dispatch] = useReducer(UserReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllUsers = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'allbuyers', formData, '', 'user'),
            ])
            const from = 'UserManagement'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_USERS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAllBiddingUsers = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'allbiddingbuyers', formData, '', 'user'),
            ])
            const from = 'UserManagement'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_BIDDING_USERS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleUser = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSingleUser', formData, '', 'user'),
            ])
            const from = 'UserManagement'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_USER,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getDashboardUser = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'dashboard', formData, '', 'user/product'),
            ])
            const from = 'UserManagement'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_DASHBOARD_USER,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const userAction = async (formData) => {
        try {
            const from = 'userAction'
            const [res] = await Promise.all([
                apiCall('post', 'userAction', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const bulkUserAction = async (formData) => {
        const from = 'bulkUserAction'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'user'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const changeUserStatus = async (formData) => {
        try {
            const from = 'changeStatus'
            const [res] = await Promise.all([
                apiCall('post', 'changeStatus', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const triggerEmail = async (formData) => {
        try {
            const from = 'triggerEmail'
            const [res] = await Promise.all([
                apiCall('post', 'triggerEmail', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const forgotPassword = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'forgotPassword', formData, '', 'user'),
            ])
            resp.commonResponse(res.data, 'forgotPassword')
        } catch (err) {
            resp.commonErrorResponse('forgotPassword')
        }
    }

    const getSingleCompanyOwner = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getSingleCompanyOwner', formData, '', 'user'),
            ])
            const from = 'UserManagement'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_OWNER,
                    payload: {
                        record: res.data.data.responseData.record
                            ? res.data.data.responseData.record
                            : {},
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const adminForgotPassword = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'forgotAdminPassword', formData, '', ''),
            ])
            resp.commonResponse(res.data, 'adminForgotPassword')
        } catch (err) {
            resp.commonErrorResponse('adminForgotPassword')
        }
    }

    const resetAdminPassword = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'resetAdminPassword', formData, '', ''),
            ])
            resp.commonResponse(res.data, 'resetAdminPassword')
        } catch (err) {
            resp.commonErrorResponse('resetAdminPassword')
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })
    return (
        <UserContext.Provider
            value={{
                all_users: state.all_users,
                all_bidding_users: state.all_bidding_users,
                single_user: state.single_user,
                responseStatus: state.responseStatus,
                search_alldashboardproducts: state.search_alldashboardproducts,
                single_owner: state.single_owner,
                getDashboardUser,
                clearResponse,
                getAllUsers,
                getAllBiddingUsers,
                triggerEmail,
                getSingleUser,
                userAction,
                bulkUserAction,
                changeUserStatus,
                forgotPassword,
                getSingleCompanyOwner,
                adminForgotPassword,
                resetAdminPassword,
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}

export default UserState
