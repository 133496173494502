import React, { useState, useEffect, useRef } from 'react'
import { styled } from '@mui/material/styles'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { lighten } from '@mui/material/styles'
import Table from '@mui/material/Table'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TablePagination from '@mui/material/TablePagination'
import TableRow from '@mui/material/TableRow'
import TableSortLabel from '@mui/material/TableSortLabel'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Checkbox from '@mui/material/Checkbox'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import FormControlLabel from '@mui/material/FormControlLabel'
import { arrayMoveImmutable } from 'array-move'
import Switch from '@mui/material/Switch'
// import FilterListIcon from '@mui/icons-material/FilterList'
import { Visibility, Edit } from '@mui/icons-material'
import {
    dateFormatFunction,
    dateTimeFormatFunction,
    currencyFormat,
    capitalize,
} from '../../../../custom/common/components'
import {
    dateTimeSecondFormatFunction,
    insertArrayIndex,
    mapData,
} from '../../../../product/common/components'
import { useFormik } from 'formik'
import './CustomTable.css'
import NoRecordsFound from '../../atoms/NoRecordsFound'
import { Button, Divider, Popover } from '@mui/material'
import Loaders from '../Loaders'
import ReactToPrint from 'react-to-print'
import Snackbar from '@mui/material/Snackbar'
import CloseIcon from '@mui/icons-material/Close'
import CheckBox from '../../atoms/CheckBox'
import CustomDialog from '../../organisms/Dialog'
import SecondaryButton from '../../atoms/SecondaryButton'
import CustomSelect from '../../atoms/Inputs/CustomSelect'
import Reorder from 'react-reorder'
import move from 'lodash-move'
import CustomCommon from './CustomCommon'

const PREFIX = 'CustomTable'

const classes = {
    root: `${PREFIX}-root`,
    highlight: `${PREFIX}-highlight`,
    title: `${PREFIX}-title`,
}

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.root}`]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },

    [`& .${classes.highlight}`]:
        theme.palette.mode === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },

    [`& .${classes.title}`]: {
        flex: '1 1 100%',
    },
}))

{/* prettier-ignore */}

const useToolbarStyles = styled(({ theme }) => ({
    [`& .${classes.root}`]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },

    [`& .${classes.highlight}`]:
        theme.palette.mode === 'light'
            ? {
                  color: theme.palette.secondary.main,
                  backgroundColor: lighten(theme.palette.secondary.light, 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: theme.palette.secondary.dark,
              },

    [`& .${classes.title}`]: {
        flex: '1 1 100%',
    },
}))

const CustomTable = (props) => {
    const [selected, setSelected] = useState([])
    const [dense, setDense] = useState(false)
    const [reload, setReload] = useState(false)
    const [tableBody, setTableBody] = useState(props.tableBody)
    const tableValues = props.tableValues
    const tableActions = props.tableActions
    const allData = props.allData
    const selectValues = props.selectValues
    const formik = props.formik
    const changeTableFunction = props.changeTableFunction
    const [showCompactView, setShowCompactView] = useState(true)
    const [allowFieldEdit, setFieldEdit] = useState(false)
    const [allowReorder, setAllowReorder] = useState(false)
    const [showPaginationView, setShowPaginationView] = useState(true)
    const [showPrint, setShowPrint] = useState(false)
    const [addFilter, setAddFilter] = useState(false)
    const [addFilterMap, setAddFilterMap] = useState(null)

    const [allDefaultFields, setAllDefaultFields] = useState([])
    const [allDynamicFields, setAllDynamicFields] = useState([])

    const [availableColumns, setAvailableColumns] = useState(props.tableValues)
    const [editColumnModal, setEditColumnModal] = useState(false)
    let enableEditTableCell = props.enableEditTableCell
    let enableColumnEdit = props.enableColumnEdit
    let reorderEnabled = props.reorder

    useEffect(() => {
        setTableBody(props.tableBody)
        setSelected([])
        return () => {
            setSelected([])
        }
    }, [props.tableBody])

    useEffect(() => {
        if (props.denseVal) {
            setDense(props.denseVal)
        }

        if (props.hideCompactView) {
            setShowCompactView(false)
        }

        if (props.hidePaginationView) {
            setShowPaginationView(false)
        }

        if (props.formik) {
            if (props.allData?.totalRecords > props.formik.limit) {
                setShowPaginationView(true)
            } else if (props.hidePaginationView) {
                setShowPaginationView(false)
            } else {
                setShowPaginationView(false)
            }
        }

        if (props.showPrint) {
            setShowPrint(true)
        }

        if (props.additionalFilter) {
            setAddFilter(props.additionalFilter)
        }
        if (props.additionalFilterMapData) {
            setAddFilterMap(props.additionalFilterMapData)
        }
    }, [props])

    useEffect(() => {
        if (props.triggerTableActions) {
            props.triggerTableActions(selected)
        }
    }, [selected])

    const [initialValues, setInitialValues] = useState({ fields: [] })

    const columnsFormik = useFormik({
        initialValues: initialValues,
        validationSchema: null,
        enableReinitialize: false,
        onSubmit: (values) => {
            console.log(values)
        },
    })

    useEffect(() => {
        let fieldValue = []
        tableValues.map((d) => {
            fieldValue.push(d.field)
        })
        columnsFormik.setFieldValue('fields', fieldValue)
        setAllDefaultFields(fieldValue)
        setAvailableColumns(tableValues)
    }, [tableValues])

    useEffect(() => {
        if (props.dynamicFieldValues) {
            if (allDefaultFields.length) {
                let ignoreDynamicField = ['uploadimages']
                let dynamicTableData = props.dynamicFieldValues
                    .filter(
                        (item) =>
                            !allDefaultFields.includes(item.name) &&
                            !ignoreDynamicField.includes(item.name)
                    )
                    .map((data, index) => ({ ...data, field: data.name }))

                setAllDynamicFields(dynamicTableData)
            }
        }
    }, [props.dynamicFieldValues, allDefaultFields])

    useEffect(() => {
        if (enableColumnEdit) {
            if (columnsFormik?.values?.fields?.length) {
                let tempColumns = []
                let index = availableColumns.findIndex(
                    (e) => e.field === 'action'
                )
                tempColumns = tableValues
                    .filter((item) =>
                        columnsFormik?.values?.fields.includes(item.field)
                    )
                    .map((data, index) => ({ ...data, name: data.field }))

                tempColumns = tempColumns.flat()

                // if multiple action buttons comes. Check this
                insertArrayIndex(
                    tempColumns,
                    index,
                    allDynamicFields.filter((item) =>
                        columnsFormik?.values?.fields.includes(item.name)
                    )
                )
                setAvailableColumns(tempColumns)
            }
        }
    }, [columnsFormik?.values?.fields, enableColumnEdit])

    const selectAllDefault = (event) => {
        let defSelected = tableValues.map((n) => n.field)
        let activeDefaultColumns = availableColumns.filter(
            (avail) => !defSelected.includes(avail.field)
        )
        let defaultSelected = activeDefaultColumns.map((n) => n.field)
        let updSelectedValues = [
            ...new Set([...columnsFormik?.values?.fields, defSelected].flat()),
        ]
        if (event.target.checked) {
            columnsFormik.setFieldValue('fields', updSelectedValues)
            setAvailableColumns([...availableColumns, tableValues])
        } else {
            columnsFormik.setFieldValue('fields', [
                ...defaultSelected,
                'id',
                'action',
            ])
            setAvailableColumns(availableColumns)
        }
    }

    const selectAllCustom = (event) => {
        let cstSelected = allDynamicFields.map((n) => n.field)
        let activeDefaultColumns = availableColumns.filter(
            (avail) => !cstSelected.includes(avail.field)
        )
        let defaultSelected = activeDefaultColumns.map((n) => n.field)
        let updSelectedValues = [
            ...new Set([...columnsFormik?.values?.fields, cstSelected].flat()),
        ]
        if (event.target.checked) {
            columnsFormik.setFieldValue('fields', updSelectedValues)
            setAvailableColumns([...availableColumns, allDynamicFields])
        } else {
            columnsFormik.setFieldValue('fields', defaultSelected)
            setAvailableColumns(availableColumns)
        }
    }

    const EnhancedTableHead = (props) => {
        const {
            classes,
            onSelectAllClick,
            order,
            orderBy,
            numSelected,
            rowCount,
            onRequestSort,
        } = props

        const createSortHandler = (property) => (event) => {
            onRequestSort(event, property)
        }

        const defaultWidth = [
            'Picture',
            'ID',
            'Qty',
            'Status',
            'Bids',
            'Bid Count',
        ]

        console.log('orderBy order', order, orderBy)
        return (
            <TableHead>
                <TableRow>
                    {allowReorder && (
                        <TableCell className="dragCell stickyHeader"></TableCell>
                    )}
                    {tableActions.length > 0 && (
                        <TableCell
                            padding="checkbox"
                            className="defaultWidth"
                            id="selectall_checkbox"
                        >
                            <Checkbox
                                indeterminate={
                                    numSelected > 0 && numSelected < rowCount
                                }
                                checked={
                                    rowCount > 0 && numSelected === rowCount
                                }
                                onChange={(e) => {
                                    onSelectAllClick(e)
                                }}
                                inputProps={{ 'aria-label': 'select all' }}
                            />
                        </TableCell>
                    )}
                    {availableColumns?.map((headCell, index) => (
                        <>
                            {headCell.sort ? (
                                <TableCell
                                    key={index}
                                    align="left"
                                    padding={
                                        headCell.disablePadding
                                            ? 'none'
                                            : 'default'
                                    }
                                    sortDirection={
                                        headCell.sortField
                                            ? orderBy === headCell.sortField
                                                ? order
                                                : false
                                            : orderBy === headCell.field
                                            ? order
                                            : false
                                    }
                                    className={`${
                                        defaultWidth.includes(headCell.label)
                                            ? 'defaultWidth'
                                            : ''
                                    }`}
                                >
                                    <>
                                        {console.log(
                                            headCell.label,
                                            'headCell.label'
                                        )}

                                        <TableSortLabel
                                            active={
                                                headCell.sortField
                                                    ? orderBy ===
                                                      headCell.sortField
                                                    : orderBy === headCell.field
                                            }
                                            direction={
                                                headCell.sortField
                                                    ? orderBy ===
                                                      headCell.sortField
                                                        ? order
                                                        : 'asc'
                                                    : orderBy === headCell.field
                                                    ? order
                                                    : 'asc'
                                            }
                                            onClick={createSortHandler(
                                                headCell.sortField
                                                    ? headCell.sortField
                                                    : headCell.field
                                            )}
                                        >
                                            {headCell.label}
                                            {headCell.sortField ? (
                                                orderBy === headCell.sortField
                                            ) : orderBy === headCell.field ? (
                                                <span
                                                    className={
                                                        classes.visuallyHidden
                                                    }
                                                >
                                                    {order === 'desc'
                                                        ? 'sorted descending'
                                                        : 'sorted ascending'}
                                                </span>
                                            ) : null}
                                        </TableSortLabel>
                                    </>
                                </TableCell>
                            ) : (
                                <TableCell
                                    key={index}
                                    align="left"
                                    padding={
                                        headCell.disablePadding
                                            ? 'none'
                                            : 'default'
                                    }
                                    className={`${
                                        defaultWidth.includes(headCell.label)
                                            ? 'defaultWidth'
                                            : ''
                                    }`}
                                >
                                    <>
                                        {console.log(
                                            headCell.label,
                                            'headCell.label'
                                        )}
                                        {headCell.label}
                                    </>
                                </TableCell>
                            )}
                        </>
                    ))}
                </TableRow>
            </TableHead>
        )
    }

    EnhancedTableHead.propTypes = {
        classes: PropTypes.object.isRequired,
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.oneOf(['asc', 'desc']).isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    }

    const EnhancedTableToolbar = (props) => {
        const classes = useToolbarStyles()
        const { numSelected } = props

        return (
            <Toolbar
                className={clsx(
                    classes.root,
                    'tableToolbar d-flex justify-content-start align-items-center',
                    {
                        [classes.highlight]: numSelected > 0,
                    }
                )}
            >
                {numSelected > 0 ? (
                    <>
                        <Typography
                            className={classes.title}
                            color="inherit"
                            variant="subtitle1"
                            component="div"
                        >
                            {numSelected} Selected
                        </Typography>

                        {tableActions.map((data, indexhead) => {
                            return (
                                <>
                                    <Tooltip title={data.label}>
                                        <IconButton
                                            id={data.id ? data.id : data.label}
                                            onClick={() => {
                                                data.onclick(
                                                    selected,
                                                    data.type
                                                )
                                            }}
                                            aria-label={data.label}
                                            size="large"
                                        >
                                            {data.icon}
                                        </IconButton>
                                    </Tooltip>
                                </>
                            )
                        })}
                    </>
                ) : (
                    <>
                        {showCompactView && (
                            <FormControlLabel
                                label="Compact View"
                                control={
                                    <Switch
                                        checked={dense}
                                        onChange={handleChangeDense}
                                    />
                                }
                            />
                        )}
                        {enableEditTableCell == true ? (
                            <Tooltip
                                placement="top"
                                title="Enabling this allows you to edit the Form values directly from the table columns."
                            >
                                <FormControlLabel
                                    label="Edit fields"
                                    className="ml-5"
                                    control={
                                        <Switch
                                            checked={allowFieldEdit}
                                            onChange={() => {
                                                setFieldEdit(!allowFieldEdit)
                                                setAllowReorder(false)
                                            }}
                                        />
                                    }
                                />
                            </Tooltip>
                        ) : null}

                        {reorderEnabled == true ? (
                            <Tooltip
                                placement="top"
                                title="Enabling this allows you to edit the order of the table rows."
                            >
                                <FormControlLabel
                                    label="Enable reorder"
                                    className="ml-5"
                                    control={
                                        <Switch
                                            checked={allowReorder}
                                            onChange={() => {
                                                setAllowReorder(!allowReorder)
                                                setFieldEdit(false)
                                            }}
                                        />
                                    }
                                />
                            </Tooltip>
                        ) : null}

                        {addFilter && (
                            <Root className="auctionsSelect d-flex">
                                {addFilterMap ? mapData(addFilterMap) : null}
                            </Root>
                        )}
                        {enableColumnEdit && (
                            <Button
                                className="columnSettings"
                                onClick={() => setEditColumnModal(true)}
                            >
                                <span className="material-icons">
                                    view_column
                                </span>{' '}
                                Column settings
                            </Button>
                        )}
                        {showPrint && (
                            <>
                                <ReactToPrint
                                    trigger={() => (
                                        <IconButton
                                            aria-label="print"
                                            size="large"
                                        >
                                            <span className="material-icons">
                                                print
                                            </span>
                                        </IconButton>
                                    )}
                                    content={() => printRef.current}
                                />
                            </>
                        )}
                        {/* <Tooltip title="Filter list">
                            <IconButton aria-label="filter list">
                                <FilterListIcon />
                            </IconButton>
                        </Tooltip> */}
                    </>
                )}
            </Toolbar>
        )
    }

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    }

    const handleRequestSort = (event, property) => {
        const isAsc = formik.orderby === property && formik.order === 'asc'
        formik.order = isAsc ? 'desc' : 'asc'
        formik.orderby = property
        formik.page = 1
        changeTableFunction(
            formik,
            props.fromVariable ? props.fromVariable : ''
        )
    }

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = tableBody.map((n) => n.id)
            setSelected(newSelecteds)
            return
        } else {
            setSelected([])
        }
    }

    useEffect(() => {
        if (props.searchData) {
            let values = props.searchData
            if (values.length) {
                const newSelecteds = []
                tableBody.map((item) => {
                    if (values.includes(item.id)) {
                        newSelecteds.push(item.id)
                    }
                })
                console.log(newSelecteds, 'newww')
                setSelected(newSelecteds)
            } else {
                setSelected([])
            }
        }
    }, [props.searchData])

    useEffect(() => {
        if (props.selectedUpdate) {
            props.selectedUpdate(selected)
        }
    }, [selected])

    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name)
        let newSelected = []

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name)
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1))
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1))
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            )
        }

        setSelected(newSelected)
    }

    const handleChangePage = (event, newPage) => {
        formik.page = newPage + 1
        changeTableFunction(formik)
    }

    const handleChangeRowsPerPage = (event) => {
        formik.limit = parseInt(event.target.value, 10)
        formik.page = 1
        changeTableFunction(formik)
    }

    const handleChangeDense = (event) => {
        setDense(event.target.checked)
    }

    const isSelected = (name) => selected.indexOf(name) !== -1

    var evaluate = (c) => Function(`return ${c}`)()

    const conditionalFormatting = (row, valhead) => {
        let returnValue = row[valhead.field] || '-'
        if (
            valhead.condition &&
            valhead.conditionValue &&
            valhead.conditionValue.formula.isString
        ) {
            let condition = valhead.conditionValue
            if (
                evaluate(
                    `"${row[condition.formula.key1]}" ${
                        condition.formula.notation
                    } "${condition.formula.key2}"`
                )
            ) {
                if (condition.true.type === 'text') {
                    returnValue = condition.true.value
                } else if (condition.true.type === 'key') {
                    returnValue = row[condition.true.value]
                }
            } else {
                if (condition.false.type === 'text') {
                    returnValue = condition.false.value
                } else if (condition.false.type === 'key') {
                    returnValue = row[condition.false.value]
                }
            }
        } else if (valhead.condition) {
            if (valhead.conditionValue) {
                let condition = valhead.conditionValue
                if (
                    evaluate(
                        `${row[condition.formula.key1]} ${
                            condition.formula.notation
                        } ${condition.formula.key2}`
                    )
                ) {
                    if (condition.true.type === 'text') {
                        returnValue = condition.true.value
                    } else if (condition.true.type === 'key') {
                        returnValue = row[condition.true.value]
                    } else if (condition.true.type === 'icon') {
                        returnValue = (
                            <Button id="actionIcon">
                                <span className="material-icons">
                                    {condition.true.icon_name}
                                </span>
                            </Button>
                        )
                    }
                } else {
                    if (condition.false.type === 'text') {
                        returnValue = condition.false.value
                    } else if (condition.false.type === 'key') {
                        returnValue = row[condition.false.value]
                    } else if (condition.false.type === 'icon') {
                        returnValue = (
                            <Button id="conditionIcon">
                                <span className="material-icons">
                                    {condition.false.icon_name}
                                </span>
                            </Button>
                        )
                    }
                }
            }
        }
        return returnValue
    }

    const printRef = useRef()

    // For the new column Settings
    const [open, setOpen] = useState(false)

    const handleFieldClick = () => {
        setOpen(true)
    }

    const handleFieldClose = (event, reason) => {
        if (reason === 'clickaway') {
            return
        }

        setOpen(false)
    }

    const handleFieldEdit = (valhead, row) => {
        props.ignoreEditArray?.includes(valhead.field)
            ? handleFieldClick()
            : props.editTableCell(valhead, row)
    }

    const [popoverAnchorEl, setPopoverAnchorEl] = useState(null)
    const [missingFields, setMissingFields] = useState(null)

    const getMissingFields = (missingData) => {
        setMissingFields(missingData)
    }

    const handlePopoverOpen = (event) => {
        setPopoverAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setPopoverAnchorEl(null)
    }

    const isValidHttpUrl = (string) => {
        let url

        try {
            url = new URL(string)
        } catch (_) {
            return false
        }

        return url.protocol === 'http:' || url.protocol === 'https:'
    }

    const popoverOpen = Boolean(popoverAnchorEl)

    const onReorder = async (e, from, to) => {
        let changedArray = tableBody
        changedArray = await arrayMoveImmutable(changedArray, from, to)
        let idOrder = changedArray.map((cat, index) => {
            let orderValue = {
                id: cat.id,
                show_order: index,
            }
            return orderValue
        })
        props.reorderTable
            ? props.propertyColumnReOrder({
                  array: true,
                  data: idOrder,
                  table: props.reorderTable,
              })
            : props.propertyColumnReOrder({
                  array: true,
                  data: idOrder,
                  table: 'projects',
                  customTable: 'custom_projects',
              })
        setTableBody(changedArray)
        setReload(!reload)
    }

    const customFilterFunction = (
        array,
        filterOn,
        filterValue,
        returnVariable
    ) => {
        let filtered = array.filter((item) => item[filterOn] == filterValue)
        return filtered.length
            ? filtered.map((item) => item[returnVariable])
            : filterValue
    }

    return (
        <div className={classes.root} ref={printRef}>
            {addFilter && !tableBody.length ? (
                <div className="auctionsSelect d-flex">
                    {addFilterMap ? mapData(addFilterMap) : null}
                </div>
            ) : null}
            {props.isLoading ? (
                <Loaders
                    name={`${
                        props.loadingTemplate ? props.loadingTemplate : 'table'
                    }`}
                    isLoading={props.isLoading}
                />
            ) : (
                <>
                    {tableBody.length ? (
                        <>
                            <Paper className={classes.paper}>
                                <EnhancedTableToolbar
                                    numSelected={selected.length}
                                />
                                <TableContainer className={classes.container}>
                                    <Table
                                        stickyHeader
                                        className={classes.table}
                                        aria-labelledby="tableTitle"
                                        size={dense ? 'small' : 'medium'}
                                        aria-label="enhanced table"
                                    >
                                        <EnhancedTableHead
                                            classes={classes}
                                            numSelected={selected.length}
                                            order={formik.order}
                                            orderBy={formik.orderby}
                                            onSelectAllClick={
                                                handleSelectAllClick
                                            }
                                            onRequestSort={handleRequestSort}
                                            rowCount={tableBody.length}
                                        />
                                        {/* <TableBody> */}
                                        {reorderEnabled === true ? (
                                            <CustomCommon
                                                conditionalFormatting={
                                                    conditionalFormatting
                                                }
                                                tableBody={tableBody}
                                                isSelected={isSelected}
                                                onReorder={onReorder}
                                                allowReorder={allowReorder}
                                                tableActions={tableActions}
                                                handleClick={handleClick}
                                                reorderId={props.reorderId}
                                                availableColumns={
                                                    availableColumns
                                                }
                                                allowFieldEdit={allowFieldEdit}
                                                handleFieldEdit={
                                                    handleFieldEdit
                                                }
                                                getMissingFields={
                                                    getMissingFields
                                                }
                                                handlePopoverOpen={
                                                    handlePopoverOpen
                                                }
                                                popoverOpen={popoverOpen}
                                                dense={dense}
                                            />
                                        ) : (
                                            tableBody.map((row, indexhead) => {
                                                const isItemSelected =
                                                    isSelected(row.id)
                                                const labelId = `enhanced-table-checkbox-${indexhead}`

                                                return (
                                                    <TableRow
                                                        hover
                                                        role="checkbox"
                                                        aria-checked={
                                                            isItemSelected
                                                        }
                                                        tabIndex={-1}
                                                        key={indexhead}
                                                        selected={
                                                            isItemSelected
                                                        }
                                                    >
                                                        {allowReorder && (
                                                            <TableCell className="dragCell stickyHeader">
                                                                <IconButton
                                                                    className="dragBtn"
                                                                    size="large"
                                                                >
                                                                    <span className="material-icons">
                                                                        drag_indicator
                                                                    </span>
                                                                </IconButton>
                                                            </TableCell>
                                                        )}
                                                        {tableActions.length >
                                                            0 && (
                                                            <TableCell padding="checkbox">
                                                                <Checkbox
                                                                    id={
                                                                        'table_checkbox'
                                                                    }
                                                                    onClick={(
                                                                        event
                                                                    ) =>
                                                                        handleClick(
                                                                            event,
                                                                            row.id
                                                                        )
                                                                    }
                                                                    checked={
                                                                        isItemSelected
                                                                    }
                                                                    inputProps={{
                                                                        'aria-labelledby':
                                                                            labelId,
                                                                    }}
                                                                />
                                                            </TableCell>
                                                        )}
                                                        {availableColumns?.map(
                                                            (
                                                                valhead,
                                                                index
                                                            ) => {
                                                                return (
                                                                    <>
                                                                        <TableCell
                                                                            onClick={() =>
                                                                                allowFieldEdit
                                                                                    ? handleFieldEdit(
                                                                                          valhead,
                                                                                          row
                                                                                      )
                                                                                    : null
                                                                            }
                                                                            className={`${
                                                                                valhead &&
                                                                                valhead?.type ===
                                                                                    'datetime' &&
                                                                                'ws_noWrap'
                                                                            }`}
                                                                        >
                                                                            {valhead.type ==
                                                                            'image' ? (
                                                                                <img
                                                                                    className={`tableimage ${
                                                                                        dense &&
                                                                                        'small'
                                                                                    }`}
                                                                                    src={
                                                                                        row[
                                                                                            valhead
                                                                                                .field
                                                                                        ]
                                                                                    }
                                                                                    alt={
                                                                                        row.title
                                                                                    }
                                                                                    onError={(
                                                                                        e
                                                                                    ) =>
                                                                                        (e.target.src =
                                                                                            '/assets/images/noImg.png')
                                                                                    }
                                                                                />
                                                                            ) : valhead.type ==
                                                                              'input' ? (
                                                                                <input
                                                                                    value={
                                                                                        valhead
                                                                                            .value[
                                                                                            valhead.field +
                                                                                                row[
                                                                                                    valhead
                                                                                                        .clickID
                                                                                                ]
                                                                                        ] >
                                                                                        -1
                                                                                            ? valhead
                                                                                                  .value[
                                                                                                  valhead.field +
                                                                                                      row[
                                                                                                          valhead
                                                                                                              .clickID
                                                                                                      ]
                                                                                              ]
                                                                                            : row[
                                                                                                  valhead
                                                                                                      .field
                                                                                              ]
                                                                                    }
                                                                                    onKeyUp={(
                                                                                        e
                                                                                    ) =>
                                                                                        valhead.onClick(
                                                                                            e,
                                                                                            valhead.returnFullRrow
                                                                                                ? row
                                                                                                : row[
                                                                                                      valhead
                                                                                                          .clickID
                                                                                                  ]
                                                                                        )
                                                                                    }
                                                                                    onChange={(
                                                                                        e
                                                                                    ) =>
                                                                                        valhead.onChange(
                                                                                            e,
                                                                                            valhead.returnFullRrow
                                                                                                ? row
                                                                                                : row[
                                                                                                      valhead
                                                                                                          .clickID
                                                                                                  ]
                                                                                        )
                                                                                    }
                                                                                    name={
                                                                                        row.title
                                                                                    }
                                                                                    disabled={
                                                                                        valhead.disabled ||
                                                                                        false
                                                                                    }
                                                                                    style={{
                                                                                        width: valhead.inputWidth
                                                                                            ? valhead.inputWidth +
                                                                                              '%'
                                                                                            : '',
                                                                                    }}
                                                                                    className={
                                                                                        valhead.addClass
                                                                                            ? valhead.addClass
                                                                                            : ''
                                                                                    }
                                                                                    id={
                                                                                        valhead.addId
                                                                                            ? valhead.addId +
                                                                                              '-' +
                                                                                              row[
                                                                                                  valhead
                                                                                                      .clickID
                                                                                              ]
                                                                                            : ''
                                                                                    }
                                                                                />
                                                                            ) : valhead.type ==
                                                                              'tooltip' ? (
                                                                                <div>
                                                                                    <Button
                                                                                        size="small"
                                                                                        aria-owns={
                                                                                            popoverOpen
                                                                                                ? 'mouseOverPopover'
                                                                                                : undefined
                                                                                        }
                                                                                        aria-haspopup="true"
                                                                                        onClick={(
                                                                                            event
                                                                                        ) => {
                                                                                            getMissingFields(
                                                                                                valhead.tooltipType ===
                                                                                                    'field'
                                                                                                    ? row[
                                                                                                          valhead
                                                                                                              .tooltipTitle
                                                                                                      ]?.toString()
                                                                                                    : valhead.tooltipTitle
                                                                                                    ? valhead.tooltipTitle
                                                                                                    : valhead.label
                                                                                            )
                                                                                            handlePopoverOpen(
                                                                                                event
                                                                                            )
                                                                                        }}
                                                                                    >
                                                                                        <span
                                                                                            className={`material-icons statusIcon ${
                                                                                                row[
                                                                                                    valhead
                                                                                                        .field
                                                                                                ] ==
                                                                                                'error'
                                                                                                    ? 'error'
                                                                                                    : 'success'
                                                                                            }`}
                                                                                        >
                                                                                            {
                                                                                                row[
                                                                                                    valhead
                                                                                                        .field
                                                                                                ]
                                                                                            }
                                                                                        </span>
                                                                                    </Button>
                                                                                </div>
                                                                            ) : valhead.type ==
                                                                                  'imagewithurl' ||
                                                                              valhead.name ==
                                                                                  'avatar' ? (
                                                                                <>
                                                                                    {row[
                                                                                        'avatar_type'
                                                                                    ] ===
                                                                                        2 ||
                                                                                    isValidHttpUrl(
                                                                                        row[
                                                                                            valhead
                                                                                                .field
                                                                                        ]
                                                                                    ) ? (
                                                                                        <>
                                                                                            <img
                                                                                                className={`tableimage ${
                                                                                                    dense &&
                                                                                                    'small'
                                                                                                }`}
                                                                                                src={
                                                                                                    valhead.custom
                                                                                                        ? row[
                                                                                                              valhead
                                                                                                                  .field
                                                                                                          ]?.replace(
                                                                                                              'www.',
                                                                                                              'staging.'
                                                                                                          )
                                                                                                        : `${
                                                                                                              row[
                                                                                                                  valhead
                                                                                                                      .field
                                                                                                              ]
                                                                                                          }`
                                                                                                }
                                                                                                alt={
                                                                                                    row.title
                                                                                                }
                                                                                                onError={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    (e.target.src =
                                                                                                        '/assets/images/noImg.png')
                                                                                                }
                                                                                            />
                                                                                        </>
                                                                                    ) : (
                                                                                        <>
                                                                                            {/* {console.log(
                                                                                            valhead,
                                                                                            row[
                                                                                                valhead
                                                                                                    .field
                                                                                            ],
                                                                                            'valhead',
                                                                                        )} */}
                                                                                            <img
                                                                                                className={`tableimage ${
                                                                                                    dense &&
                                                                                                    'small'
                                                                                                }`}
                                                                                                src={`${
                                                                                                    global.site_url
                                                                                                }/uploads/${
                                                                                                    valhead.folder
                                                                                                }/${
                                                                                                    row[
                                                                                                        valhead
                                                                                                            .field
                                                                                                    ]
                                                                                                }`}
                                                                                                alt={
                                                                                                    row.title
                                                                                                }
                                                                                                onError={(
                                                                                                    e
                                                                                                ) =>
                                                                                                    (e.target.src =
                                                                                                        '/assets/images/noImg.png')
                                                                                                }
                                                                                            />
                                                                                        </>
                                                                                    )}
                                                                                </>
                                                                            ) : valhead.type ==
                                                                              'imagewithcompressedurl' ? (
                                                                                <img
                                                                                    className={`tableimage ${
                                                                                        dense &&
                                                                                        'small'
                                                                                    }`}
                                                                                    src={`${
                                                                                        global.site_url
                                                                                    }/uploads/${
                                                                                        valhead.isCompress
                                                                                            ? valhead.folder +
                                                                                              '/compressed'
                                                                                            : valhead.folder
                                                                                    }/${
                                                                                        row[
                                                                                            valhead
                                                                                                .field
                                                                                        ]
                                                                                    }`}
                                                                                    alt={
                                                                                        row.title
                                                                                    }
                                                                                    onError={(
                                                                                        e
                                                                                    ) =>
                                                                                        (e.target.src =
                                                                                            '/assets/images/noImg.png')
                                                                                    }
                                                                                />
                                                                            ) : valhead.type ==
                                                                              'date' ? (
                                                                                dateFormatFunction(
                                                                                    row[
                                                                                        valhead
                                                                                            .field
                                                                                    ]
                                                                                )
                                                                            ) : valhead.type ==
                                                                              'dropdown' ? (
                                                                                row[
                                                                                    valhead
                                                                                        .field
                                                                                ] !==
                                                                                    null &&
                                                                                selectValues ? (
                                                                                    valhead?.dropdownFilterBy !==
                                                                                        '' &&
                                                                                    valhead?.returnVariable !==
                                                                                        '' ? (
                                                                                        customFilterFunction(
                                                                                            selectValues,
                                                                                            valhead.dropdownFilterBy,
                                                                                            row[
                                                                                                valhead
                                                                                                    .field
                                                                                            ],
                                                                                            valhead?.dropdownReturnVariable
                                                                                        )
                                                                                    ) : (
                                                                                        selectValues
                                                                                            .filter(
                                                                                                (
                                                                                                    inner
                                                                                                ) =>
                                                                                                    inner.id ===
                                                                                                    parseInt(
                                                                                                        row[
                                                                                                            valhead
                                                                                                                .field
                                                                                                        ]
                                                                                                    )
                                                                                            )
                                                                                            .map(
                                                                                                (
                                                                                                    filtered
                                                                                                ) =>
                                                                                                    filtered.description
                                                                                            )
                                                                                    )
                                                                                ) : null
                                                                            ) : // selectValues
                                                                            //     .filter(
                                                                            //         (inner) =>
                                                                            //             inner.id ===
                                                                            //             row[
                                                                            //                 valhead
                                                                            //                     .field
                                                                            //             ],
                                                                            //     )
                                                                            //     .map((filtered) =>
                                                                            //         // filtered.description,
                                                                            //         console.log(
                                                                            //             filtered,
                                                                            //             'filter',
                                                                            //         ),
                                                                            //     )
                                                                            valhead.type ==
                                                                              'datetime' ? (
                                                                                dateTimeFormatFunction(
                                                                                    row[
                                                                                        valhead
                                                                                            .field
                                                                                    ]
                                                                                )
                                                                            ) : valhead.type ==
                                                                              'datetimesecond' ? (
                                                                                dateTimeSecondFormatFunction(
                                                                                    row[
                                                                                        valhead
                                                                                            .field
                                                                                    ]
                                                                                )
                                                                            ) : valhead.type ==
                                                                              'price' ? (
                                                                                valhead.isTransaction ==
                                                                                true ? (
                                                                                    row[
                                                                                        valhead
                                                                                            .field
                                                                                    ] ? (
                                                                                        (row.remove_credit ==
                                                                                        0
                                                                                            ? '+'
                                                                                            : '-') +
                                                                                        currencyFormat(
                                                                                            row[
                                                                                                valhead
                                                                                                    .field
                                                                                            ]
                                                                                        )
                                                                                    ) : (
                                                                                        '-'
                                                                                    )
                                                                                ) : row[
                                                                                      valhead
                                                                                          .field
                                                                                  ] ? (
                                                                                    currencyFormat(
                                                                                        row[
                                                                                            valhead
                                                                                                .field
                                                                                        ]
                                                                                    )
                                                                                ) : (
                                                                                    '-'
                                                                                )
                                                                            ) : valhead.type ==
                                                                              'capitalize' ? (
                                                                                capitalize(
                                                                                    row[
                                                                                        valhead
                                                                                            .field
                                                                                    ]
                                                                                )
                                                                            ) : valhead.type ==
                                                                              'payment_method' ? (
                                                                                row.payment_method !==
                                                                                'cash' ? (
                                                                                    `${row.mk_card_number} (${row.card_type}) `
                                                                                ) : (
                                                                                    capitalize(
                                                                                        row.payment_method
                                                                                    )
                                                                                )
                                                                            ) : valhead.type ==
                                                                              'button' ? (
                                                                                valhead.isMultiple ? (
                                                                                    <div className="d-flex justify-content-center align-items-center">
                                                                                        {valhead.isMultiple.map(
                                                                                            (
                                                                                                inner,
                                                                                                innerIndex
                                                                                            ) => (
                                                                                                <div
                                                                                                    key={
                                                                                                        innerIndex
                                                                                                    }
                                                                                                >
                                                                                                    <Tooltip
                                                                                                        title={
                                                                                                            inner.tooltipTitle
                                                                                                                ? inner.tooltipTitle
                                                                                                                : valhead.label
                                                                                                        }
                                                                                                    >
                                                                                                        <Button
                                                                                                            id="tableButton"
                                                                                                            onClick={() => {
                                                                                                                inner.onclick(
                                                                                                                    valhead.clickID &&
                                                                                                                        !inner.clickID
                                                                                                                        ? row[
                                                                                                                              valhead
                                                                                                                                  .clickID
                                                                                                                          ]
                                                                                                                        : inner.clickID
                                                                                                                        ? row[
                                                                                                                              inner
                                                                                                                                  .clickID
                                                                                                                          ]
                                                                                                                        : row.id,
                                                                                                                    valhead.secondaryClickID
                                                                                                                        ? row[
                                                                                                                              valhead
                                                                                                                                  .secondaryClickID
                                                                                                                          ]
                                                                                                                        : inner.secondaryClickID
                                                                                                                        ? row[
                                                                                                                              inner
                                                                                                                                  .secondaryClickID
                                                                                                                          ]
                                                                                                                        : null,
                                                                                                                    inner.clickType
                                                                                                                )
                                                                                                            }}
                                                                                                            disabled={
                                                                                                                valhead.disabled
                                                                                                                    ? row[
                                                                                                                          valhead
                                                                                                                              .disabled
                                                                                                                      ]
                                                                                                                    : false
                                                                                                            }
                                                                                                        >
                                                                                                            {/*<span className="material-icons">
                                                                                                            {inner.buttonType
                                                                                                                ? inner.buttonType
                                                                                                                : 'edit'}
                                                                                                        </span>*/}
                                                                                                            {row[
                                                                                                                valhead
                                                                                                                    .disabled
                                                                                                            ] &&
                                                                                                            inner.showText ? (
                                                                                                                <span
                                                                                                                    style={{
                                                                                                                        textTransform:
                                                                                                                            'capitalize',
                                                                                                                        color: 'rgba(0, 0, 0, 0.87)',
                                                                                                                        cursor: 'text',
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {
                                                                                                                        inner.textVal
                                                                                                                    }
                                                                                                                </span>
                                                                                                            ) : (
                                                                                                                <span className="material-icons">
                                                                                                                    {row[
                                                                                                                        valhead
                                                                                                                            .disabled
                                                                                                                    ] &&
                                                                                                                    inner.buttonType ===
                                                                                                                        'sync'
                                                                                                                        ? 'sync_disabled'
                                                                                                                        : inner.buttonType
                                                                                                                        ? inner.buttonType
                                                                                                                        : 'edit'}
                                                                                                                </span>
                                                                                                            )}
                                                                                                            {/*<span className="material-icons">
                                                                                                            {row[
                                                                                                                valhead
                                                                                                                    .disabled
                                                                                                            ] &&
                                                                                                            inner.buttonType ===
                                                                                                                'sync'
                                                                                                                ? 'sync_disabled'
                                                                                                                : inner.buttonType
                                                                                                                ? inner.buttonType
                                                                                                                : 'edit'}
                                                                                                        </span>*/}
                                                                                                        </Button>
                                                                                                    </Tooltip>
                                                                                                </div>
                                                                                            )
                                                                                        )}
                                                                                    </div>
                                                                                ) : (
                                                                                    <Tooltip
                                                                                        title={
                                                                                            valhead.tooltipTitle
                                                                                                ? valhead.tooltipTitle
                                                                                                : valhead.label
                                                                                        }
                                                                                    >
                                                                                        <Button
                                                                                            id="buttontool"
                                                                                            onClick={() => {
                                                                                                valhead.onclick(
                                                                                                    valhead.clickID
                                                                                                        ? row[
                                                                                                              valhead
                                                                                                                  .clickID
                                                                                                          ]
                                                                                                        : row.id,
                                                                                                    valhead.secondaryClickID
                                                                                                        ? row[
                                                                                                              valhead
                                                                                                                  .secondaryClickID
                                                                                                          ]
                                                                                                        : null,
                                                                                                    valhead.clickType
                                                                                                )
                                                                                            }}
                                                                                        >
                                                                                            <span className="material-icons">
                                                                                                {valhead.buttonType
                                                                                                    ? valhead.buttonType
                                                                                                    : 'edit'}
                                                                                            </span>
                                                                                        </Button>
                                                                                    </Tooltip>
                                                                                )
                                                                            ) : (
                                                                                <>
                                                                                    {valhead?.showIcon &&
                                                                                    row[
                                                                                        valhead
                                                                                            ?.conditionField
                                                                                    ] ==
                                                                                        valhead?.valCondition ? (
                                                                                        <span className="tableIcon">
                                                                                            {' '}
                                                                                            <img
                                                                                                src={
                                                                                                    valhead.iconUrl
                                                                                                }
                                                                                                alt={
                                                                                                    valhead.iconAlt
                                                                                                }
                                                                                            />
                                                                                        </span>
                                                                                    ) : null}

                                                                                    <Tooltip
                                                                                        title={
                                                                                            valhead.field ===
                                                                                            'bidscount'
                                                                                                ? valhead.label
                                                                                                : ''
                                                                                        }
                                                                                    >
                                                                                        <span
                                                                                            className={`${
                                                                                                valhead?.click
                                                                                                    ? 'cursorDecoy link'
                                                                                                    : row[
                                                                                                          valhead
                                                                                                              .field
                                                                                                      ] ==
                                                                                                      0
                                                                                                    ? 'pntrEvntNone'
                                                                                                    : 'titleText'
                                                                                            }`}
                                                                                            onClick={() => {
                                                                                                valhead.click
                                                                                                    ? valhead.onclick(
                                                                                                          valhead.clickID
                                                                                                              ? row[
                                                                                                                    valhead
                                                                                                                        .clickID
                                                                                                                ]
                                                                                                              : row.id,
                                                                                                          valhead.secondaryClickID
                                                                                                              ? row[
                                                                                                                    valhead
                                                                                                                        .secondaryClickID
                                                                                                                ]
                                                                                                              : null,
                                                                                                          valhead.clickType
                                                                                                      )
                                                                                                    : valhead.clickable
                                                                                                    ? valhead.onclick(
                                                                                                          valhead.clickID
                                                                                                              ? row[
                                                                                                                    valhead
                                                                                                                        .clickID
                                                                                                                ]
                                                                                                              : row.id,
                                                                                                          valhead.secondaryClickID
                                                                                                              ? row[
                                                                                                                    valhead
                                                                                                                        .secondaryClickID
                                                                                                                ]
                                                                                                              : null,
                                                                                                          valhead.clickType
                                                                                                      )
                                                                                                    : ''
                                                                                            }}
                                                                                        >
                                                                                            {valhead.field?.split(
                                                                                                ','
                                                                                            )
                                                                                                .length >
                                                                                            1 ? (
                                                                                                <>
                                                                                                    {valhead.field
                                                                                                        .split(
                                                                                                            ','
                                                                                                        )
                                                                                                        .map(
                                                                                                            (
                                                                                                                value,
                                                                                                                index
                                                                                                            ) =>
                                                                                                                `${
                                                                                                                    row[
                                                                                                                        value
                                                                                                                    ] ||
                                                                                                                    ''
                                                                                                                } `
                                                                                                        )}
                                                                                                </>
                                                                                            ) : row[
                                                                                                  valhead
                                                                                                      .field
                                                                                              ] !==
                                                                                              null ? (
                                                                                                conditionalFormatting(
                                                                                                    row,
                                                                                                    valhead
                                                                                                )
                                                                                            ) : (
                                                                                                '-'
                                                                                            )}
                                                                                        </span>
                                                                                    </Tooltip>
                                                                                </>
                                                                            )}
                                                                        </TableCell>
                                                                    </>
                                                                )
                                                            }
                                                        )}
                                                    </TableRow>
                                                )
                                            })
                                        )}
                                        {/* </TableBody> */}
                                    </Table>
                                </TableContainer>
                                {showPaginationView && (
                                    <TablePagination
                                        rowsPerPageOptions={
                                            formik.limitOptions
                                                ? formik.limitOptions
                                                : [5, 20, 40, 60].includes(
                                                      formik.limit
                                                  )
                                                ? [5, 20, 40, 60]
                                                : [formik.limit, 5, 20, 40, 60]
                                        }
                                        component="div"
                                        count={allData.totalRecords}
                                        rowsPerPage={formik.limit}
                                        page={formik.page - 1}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={
                                            handleChangeRowsPerPage
                                        }
                                    />
                                )}
                            </Paper>
                            <Snackbar
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'center',
                                }}
                                open={open}
                                autoHideDuration={2500}
                                onClose={handleFieldClose}
                                message="Default fields can not be edited!"
                                action={
                                    <>
                                        <IconButton
                                            size="small"
                                            aria-label="close"
                                            color="inherit"
                                            onClick={() => handleFieldClose()}
                                        >
                                            <CloseIcon fontSize="small" />
                                        </IconButton>
                                    </>
                                }
                            />
                        </>
                    ) : (
                        <NoRecordsFound />
                    )}
                </>
            )}
            {enableColumnEdit ? (
                <>
                    <CustomDialog
                        className="editColumnModal"
                        title={`Edit columns`}
                        open={editColumnModal}
                        function={() => setEditColumnModal(!editColumnModal)}
                    >
                        <Button
                            className="closeBtn"
                            onClick={() => setEditColumnModal(false)}
                        >
                            <span className="material-icons">close</span>
                        </Button>
                        <p>
                            Select what all information you would like to see.
                        </p>
                        <h6>Default Fields</h6>
                        <div className="columnSettingsCnt">
                            <CheckBox
                                name="fields"
                                label="Select all"
                                checked={
                                    tableValues
                                        .map((n) => n.field)
                                        .every((r) =>
                                            columnsFormik?.values?.fields.includes(
                                                r
                                            )
                                        )
                                        ? true
                                        : false
                                }
                                value="allDefault"
                                onChange={selectAllDefault}
                            />

                            {tableValues.map((data, index) => (
                                <>
                                    <CheckBox
                                        name="fields"
                                        label={data.label}
                                        disabled={
                                            data.field === 'id' ||
                                            data.field === 'action'
                                                ? true
                                                : false
                                        }
                                        checked={
                                            data.field
                                                ? columnsFormik?.values?.fields?.indexOf(
                                                      data.field.toString()
                                                  ) !== -1
                                                    ? true
                                                    : false
                                                : false
                                        }
                                        value={data.field?.toString()}
                                        onChange={columnsFormik.handleChange}
                                    />
                                </>
                            ))}
                        </div>
                        <Divider />
                        <h6>Custom Fields</h6>
                        <div className="columnSettingsCnt">
                            <CheckBox
                                name="fields"
                                label="Select all"
                                checked={
                                    allDynamicFields
                                        .map((n) => n.field)
                                        .every((r) =>
                                            columnsFormik?.values?.fields.includes(
                                                r
                                            )
                                        )
                                        ? true
                                        : false
                                }
                                value="allCustom"
                                onChange={selectAllCustom}
                            />
                            {allDynamicFields.length &&
                                allDynamicFields.map((data, index) => (
                                    <>
                                        <CheckBox
                                            name="fields"
                                            label={data.label}
                                            checked={
                                                columnsFormik?.values?.fields?.indexOf(
                                                    data.name.toString()
                                                ) !== -1
                                                    ? true
                                                    : false
                                            }
                                            value={data.name.toString()}
                                            onChange={
                                                columnsFormik.handleChange
                                            }
                                        />
                                    </>
                                ))}
                        </div>
                    </CustomDialog>
                </>
            ) : null}
            <Popover
                id="mouseOverPopover"
                className="missingFieldsCnt"
                open={popoverOpen}
                anchorEl={popoverAnchorEl}
                onClose={handlePopoverClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <div className="missingFieldsInner">
                    {missingFields?.length ? (
                        <>
                            {' '}
                            <h6>
                                The following fields are missing from your
                                listing:
                            </h6>
                            <ol>
                                {missingFields?.split(',').map((data) => (
                                    <li>{data}</li>
                                ))}
                            </ol>
                        </>
                    ) : (
                        <h6>There are no missing fields in your listing.</h6>
                    )}
                </div>
            </Popover>
        </div>
    )
}

export default CustomTable
