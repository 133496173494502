import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../product/components/templates/Layout'
import '../Dashboard/Dashboard.css'
import CustomTable from '../../product/components/molecules/CustomTable'
import CustomSearch from '../../product/components/molecules/CustomSearch'

import { useFormik } from 'formik'
import AlertContext from '../../product/context/alert/alertContext'
import AuctionContext from '../../product/context/auction/auctionContext'
import { Button } from '@mui/material'
import AuctionManage from './AuctionManage'
import AuctionListing from './AuctionListing'

import CustomDialog from '../../product/components/organisms/Dialog'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'

const AuctionLots = (props) => {
    const alertContext = useContext(AlertContext)
    const auctionContext = useContext(AuctionContext)
    const history = useHistory()

    const [isLoading, setIsLoading] = useState(true)

    const { setAlert } = alertContext
    const {
        all_auctions,
        getAllAuctions,
        changeAuctionStatus,
        responseStatus: responseStatusAuction,
        clearResponse: clearResponseAuction,
    } = auctionContext

    let action = props.match.params.action

    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id })
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                action: {
                    value: action
                        ? action.toString() !== 'all'
                            ? action.toString()
                            : ''
                        : null,
                    type: 'in',
                    field: 'ac.market_status',
                },
                searchterm: {
                    value: '',
                    type: 'like',
                    field: 'ac.title',
                },
                fromdate: {
                    value: '',
                    type: 'dategreaterequal',
                    field: 'ac.date_closed',
                },
                todate: {
                    value: '',
                    type: 'datesmallerequal',
                    field: 'ac.date_closed',
                },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllAuctions(values)
        },
    })
    useEffect(() => {
        if (all_auctions) {
            setTableBody(
                all_auctions.records.length ? all_auctions.records : []
            )
            setIsLoading(false)
        }
    }, [all_auctions])

    useEffect(() => {
        setTableBody([])
        setIsLoading(true)
        formik.values.action = action
        formik.values.page = 1
        formik.values.fromdate = ''
        formik.values.todate = ''
        formik.values.searchterm = ''
        getAllAuctions(formik.values)
        /*  return () => {
            setTableBody([])
        }*/
    }, [action])

    const onClickEdit = (id, secondaryID, type) => {
        console.log('onClickEdit', id, type)
        toggleFullScreenPopup(true, type, id)
    }

    const formikSelection = useFormik({
        initialValues: {
            action: '',
            auction_id: '',
        },
        onSubmit: (values) => {
            if (values.action === 'removed') {
                setIsLoading(true)
                changeAuctionStatus(values)
            }
        },
    })

    const [changeStatus, setChangeStatus] = React.useState(false)

    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const onSelectMultiProducts = (data, action) => {
        console.log('data', data)
        console.log('action', action)
        formikSelection.values.action = action
        formikSelection.values.auction_id = data
        if (action === 'removed') {
            let selectedProjects = tableBody.filter(
                (filter) =>
                    formikSelection.values.auction_id.indexOf(filter.id) !== -1
            )
            let draftStatusCheck = selectedProjects.filter(
                (filter) => filter.market_status === 'draft'
            )
            if (
                draftStatusCheck.length !==
                formikSelection.values.auction_id.length
            ) {
                setAlert('Only draft auctions can be deleted', 'error')
            } else {
                setChangeStatus(true)
            }
        }
    }

    useEffect(() => {
        if (responseStatusAuction) {
            if (
                responseStatusAuction.status === 'success' &&
                responseStatusAuction.from === 'auctionchange'
            ) {
                setChangeStatus(false)
                getAllAuctions(formik.values)
            }
        }
    }, [responseStatusAuction])

    const getMultipleAction = () => {
        let actionPush = [
            {
                buttonType: 'edit',
                clickType: 'edit',
                onclick: onClickEdit,
                tooltipTitle: 'Edit lot',
            },
            {
                buttonType: 'visibility',
                clickType: 'viewListing',
                onclick: onClickEdit,
                tooltipTitle: 'View Listing',
            },
        ]
        return actionPush
    }

    const tableValues = [
        {
            field: 'id',
            type: '',
            firstChild: true,
            sort: true,
            sortField: 'ac.id',
            disablePadding: false,
            label: 'ID',
        },
        {
            field: 'avatar',
            type: 'imagewithurl',
            folder: 'auction',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'Picture',
        },
        {
            field: 'title',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Title',
        },
        {
            field: 'date_added',
            sortField: 'ac.date_added',
            type: 'datetime',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Start date',
        },
        {
            field: 'date_closed',
            type: 'datetime',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'End date',
        },
        {
            field: 'projectcount',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'No of items',
        },
        {
            field: 'market_status',
            type: 'capitalize',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            field: 'created_at',
            type: 'datetime',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Created At',
        },
        {
            field: 'updated_at',
            type: 'datetime',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Updated At',
        },
        {
            field: 'as_employees_emp_firstname,as_employees_emp_lastname',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Posted By',
        },
        {
            field: 'action',
            type: 'button',
            isMultiple: true,
            clickID: 'id',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            isMultiple: getMultipleAction(),
        },
    ]

    const tableActions = [
        {
            label: 'Delete',
            icon: <span className="material-icons">delete</span>,
            onclick: onSelectMultiProducts,
            type: 'removed',
        },
    ]

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: 'Auction Lot Title',
                placeholder: 'Enter auction lot title',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'searchterm',
                filter: true,
            },
            {
                label: 'Closed From Date',
                placeholder: 'Date Ending from',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'fromdate',
                filter: true,
            },
            {
                label: 'Closed To Date',
                placeholder: 'Date Ending To',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'todate',
                filter: true,
            },
        ],
    }

    useEffect(() => {
        if (responseStatusAuction) {
            if (
                responseStatusAuction.status === 'success' &&
                responseStatusAuction.from === 'auctionAction'
            ) {
                toggleFullScreenPopup(false)
                getAllAuctions(formik.values)
            }
        }
    }, [responseStatusAuction])

    return (
        <Layout>
            <div className="dashboard auctionLots">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <div className="miscActions d-flex justify-content-between align-items-center mt-4">
                    <Button
                        variant="outlined"
                        className="btnOutlined"
                        onClick={() => toggleFullScreenPopup(true, 'new', 0)}
                    >
                        <span className="material-icons">note_add</span>Add New
                        Lot
                    </Button>
                </div>

                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_auctions}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    changeTableFunction={getAllAuctions}
                />
            </div>
            <AuctionManage data={manage} function={toggleFullScreenPopup} />
            <AuctionListing data={manage} function={toggleFullScreenPopup} />
            <CustomDialog
                title={'Change auction status'}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>Are you sure you want to change the status</h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form
                        onSubmit={formikSelection.handleSubmit}
                        autoComplete="nofill"
                    >
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </Layout>
    )
}

export default AuctionLots
