import React, { useContext, useState, useEffect } from 'react'
import moment from 'moment'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import SecondaryButton from '../../product/components/atoms/SecondaryButton'
import FullScreenPopup from '../../product/components/organisms/FullScreenPopup'

import {
    mapData,
    dateTimeFormatFunction,
    handleRedirectInternal,
} from '../../product/common/components'
import Uploader from '../../product/common/uploader'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { LinearProgress } from '@mui/material'
import Loaders from '../../product/components/molecules/Loaders'

import CommonContext from '../../product/context/common/commonContext'
import ProductContext from '../../product/context/product/productContext'
import CustomSearch from '../../product/components/molecules/CustomSearch'
import CustomTable from '../../product/components/molecules/CustomTable'
import { Button } from '@mui/material'

import BidDeposit from '../BidDeposit/BidDeposit'

const UserManage = (props) => {
    const productContext = useContext(ProductContext)
    const commonContext = useContext(CommonContext)

    const { setBidHistoryValue } = commonContext

    const [isLoading, setIsLoading] = useState(true)

    const data = props.data
    const toggleFullScreenPopup = props.function

    const [manage, setManage] = useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const toggleBidDepositPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id })
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    const { all_products, getAllProducts } = productContext

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                auctionid: {
                    value: 0,
                    type: 'in',
                    field: 'p.auctionid',
                },
                searchterm: {
                    value: '',
                    type: 'like',
                    field: 'p.title',
                },
                fromdate: {
                    value: '',
                    type: 'dategreaterequal',
                    field: 'p.date_closed',
                },
                todate: {
                    value: '',
                    type: 'datesmallerequal',
                    field: 'p.date_closed',
                },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllProducts(values)
        },
    })

    const onClickEdit = (id, secondaryID, type) => {
        if (type === 'bidcount') {
            setBidHistoryValue({
                id,
                proxy: true,
            })
        } else if (type === 'deposit') {
            toggleBidDepositPopup(true, 'edit', id)
        }
    }

    useEffect(() => {
        if (data.status === 'viewListing') {
            setTableBody([])
            setIsLoading(true)
            formik.values.filters.auctionid.value = data.id
            formik.values.page = 1
            formik.values.filters.fromdate.value = ''
            formik.values.filters.todate.value = ''
            formik.values.filters.searchterm.value = ''
            getAllProducts(formik.values)
        }
        return () => {
            setTableBody([])
        }
    }, [data.id])

    useEffect(() => {
        return () => setIsLoading(false)
    }, [])
    useEffect(() => {
        if (all_products) {
            setTableBody(
                all_products.records.length ? all_products.records : []
            )
        }
        setIsLoading(false)
    }, [all_products])

    const tableValues = []

    tableValues.push({
        field: 'buynow_common_invoice',
        type: '',
        firstChild: false,
        sort: true,
        disablePadding: false,
        label: 'Invoice ID',
    })

    tableValues.push(
        {
            field: 'id',
            type: '',
            firstChild: true,
            sort: true,
            sortField: 'p.id',
            disablePadding: false,
            label: 'ID',
        },
        {
            field: 'avatar',
            type: 'imagewithurl',
            folder: 'product',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'Picture',
        },

        {
            field: 'title',
            type: '',
            firstChild: false,
            sort: true,
            sortField: 'p.title',
            disablePadding: false,
            label: 'Title',
        },
        {
            field: 'auctiontitle',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Auction Title',
        },
        {
            field: 'company',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Company Name',
        },
        {
            field: 'bidscount',
            click: true,
            onclick: onClickEdit,
            clickID: 'id',
            secondaryClickID: 'user_id',
            clickType: 'bidcount',
            type: '',
            sort: true,
            sortField: 'bidscount',
            firstChild: false,
            disablePadding: false,
            label: 'Bids',
        }
    )

    tableValues.push(
        {
            field: 'wprice',
            type: 'price',
            firstChild: false,
            sort: true,
            sortField: 'p.wprice',
            disablePadding: false,
            label: 'Current price',
        },
        {
            field: 'rprice',
            type: 'price',
            firstChild: false,
            sort: true,
            sortField: 'p.rprice',
            disablePadding: false,
            label: 'Reserve price',
        },
        {
            field: 'market_status',
            sortField: 'p.market_status',
            type: 'capitalize',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Status',
        },
        {
            field: 'total_deposit_count',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Deposit Users',
        },
        {
            field: 'total_deposit_amount',
            type: 'price',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Deposit Amount',
        },
        {
            field: 'total_refund_count',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Refund Users',
        },
        {
            field: 'total_refund_amount',
            type: 'price',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Refund Amount',
        }
    )

    tableValues.push(
        {
            field: 'date_added',
            type: 'datetime',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Start date',
        },
        {
            field: 'date_closed',
            type: 'datetime',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'End date',
        }
    )

    tableValues.push(
        {
            field: 'buynow_paid_date',
            type: 'date',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Sold date',
        },
        {
            field: 'buynow_qty',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Purchased Quantity',
        },
        {
            field: 'buynow_amount',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Amount',
        },
        {
            field: 'winner_first_name,winner_last_name',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Winner Buyer',
        },
        {
            field: 'winner_email',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Winner Email',
        }
    )

    tableValues.push(
        {
            field: 'created_at',
            type: 'datetime',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Created At',
        },
        {
            field: 'updated_at',
            type: 'datetime',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Updated At',
        },
        {
            field: 'as_employees_emp_firstname,as_employees_emp_lastname',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Posted By',
        },
        {
            field: 'action',
            type: 'button',
            buttonType: 'history',
            clickType: 'deposit',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            tooltipTitle: 'View deposit history',
        }
    )

    const tableActions = []

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: 'Listing Title',
                placeholder: 'Enter listing title',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'searchterm',
                filter: true,
            },
            {
                label: 'Closed From Date',
                placeholder: 'Date Ending from',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'fromdate',
                filter: true,
            },
            {
                label: 'Closed To Date',
                placeholder: 'Date Ending To',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'todate',
                filter: true,
            },
        ],
    }

    return (
        <>
            <FullScreenPopup
                modaltitle={'Listings'}
                open={data.status === 'viewListing' ? data.popup : false}
                handleClose={() => toggleFullScreenPopup(false)}
            >
                <div className="addUserModal">
                    <div className="dashboard listing">
                        <CustomSearch searchInfo={searchInfo} formik={formik} />
                        <div className="miscActions d-flex justify-content-start align-items-center flex-wrap mt-4">
                            {/* <Button
                            onClick={() =>
                                getAllExportDownload({
                                    action: 'export',
                                    order: 'asc',
                                    orderby: '',
                                    search: 'all',
                                    report: `all_product_management`,
                                })
                            }
                        >
                            <span className="material-icons">get_app</span>Export All Data
                        </Button> */}
                        </div>
                        {isLoading ? (
                            <Loaders name="table" isLoading={isLoading} />
                        ) : (
                            <CustomTable
                                formik={formik.values}
                                tableBody={tableBody}
                                tableValues={tableValues}
                                tableActions={tableActions}
                                allData={all_products}
                                changeTableFunction={getAllProducts}
                            />
                        )}
                    </div>
                </div>
            </FullScreenPopup>
            <BidDeposit data={manage} function={toggleBidDepositPopup} />
        </>
    )
}

export default UserManage
