import React, { useReducer } from 'react'
import AppointmentContext from './appointmentContext'
import AppointmentReducer from './appointmentReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_APPOINTMENTS,
    GET_SINGLE_APPOINTMENT,
    GET_ALL_APPOINTMENTS_AUCTION,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './appointmentTypes'

const AppointmentState = (props) => {
    const initialState = {
        all_appointments: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        single_appointment: {
            records: [],
        },
        all_appointments_auction: {
            records: [],
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(AppointmentReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllAppointments = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getAll', formData, '', 'appointment'),
            ])
            const from = 'appointment'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_APPOINTMENTS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleAppointment = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'get', formData, '', 'appointment'),
            ])
            const from = 'appointment'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_APPOINTMENT,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getAppointmentForInvoice = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'getForInvoice', formData, '', 'appointment'),
            ])
            const from = 'appointment'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_APPOINTMENT,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            resp.commonErrorResponse('dashboard')
        }
    }

    const getAllAppointmentsForAuction = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall(
                    'post',
                    'getAllForAuction',
                    formData,
                    '',
                    'appointment'
                ),
            ])
            const from = 'appointment'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_APPOINTMENTS_AUCTION,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const updateAppointment = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'update', formData, '', 'appointment'),
            ])
            const from = 'appointment'
            if (res.data.status === 'success') {
                resp.commonResponse(res.data, from)
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <AppointmentContext.Provider
            value={{
                all_appointments: state.all_appointments,
                all_appointments_auction: state.all_appointments_auction,
                single_appointment: state.single_appointment,
                responseStatus: state.responseStatus,
                clearResponse,
                getAllAppointments,
                getSingleAppointment,
                getAppointmentForInvoice,
                getAllAppointmentsForAuction,
                updateAppointment,
            }}
        >
            {props.children}
        </AppointmentContext.Provider>
    )
}

export default AppointmentState
