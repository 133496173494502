import React, { useState, useEffect, useRef } from 'react'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import IconButton from '@mui/material/IconButton'
import Checkbox from '@mui/material/Checkbox'
import Tooltip from '@mui/material/Tooltip'
import { Button, Divider, Popover } from '@mui/material'
import './CustomTable.css'
import {
    dateFormatFunction,
    dateTimeFormatFunction,
    currencyFormat,
    capitalize,
} from '../../../../custom/common/components'
import {
    dateTimeSecondFormatFunction,
    mapData,
} from '../../../../product/common/components'
import Reorder from 'react-reorder'

const CustomCommon = (props) => {
    let tableBody = props.tableBody

    return (
        <Reorder
            reorderId={props.reorderId ? props.reorderId : 'tableReorderId'}
            reorderGroup={
                props.reorderId ? props.reorderId : 'tableReorderIdChange'
            }
            component="tbody"
            className={`${
                props.allowReorder ? 'reorderCntTable' : ''
            } MuiTableBody-root`}
            draggedClassName="dragged"
            lock="horizontal"
            touchHoldTime={250}
            mouseHoldTime={100}
            onReorder={props.onReorder}
            autoScroll={true}
            disabled={!props.allowReorder}
            disableContextMenus={true}
        >
            {tableBody.map((row, indexhead) => {
                const isItemSelected = props.isSelected(row.id)
                const labelId = `enhanced-table-checkbox-${indexhead}`

                return (
                    <TableRow
                        hover
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={indexhead}
                        selected={isItemSelected}
                    >
                        {props.allowReorder && (
                            <TableCell className="dragCell stickyHeader">
                                <IconButton className="dragBtn" size="large">
                                    <span className="material-icons">
                                        drag_indicator
                                    </span>
                                </IconButton>
                            </TableCell>
                        )}
                        {props.tableActions.length > 0 && (
                            <TableCell padding="checkbox">
                                <Checkbox
                                    onClick={(event) =>
                                        props.handleClick(event, row.id)
                                    }
                                    checked={isItemSelected}
                                    inputProps={{
                                        'aria-labelledby': labelId,
                                    }}
                                />
                            </TableCell>
                        )}
                        {props.availableColumns?.map((valhead, index) => {
                            return (
                                <>
                                    <TableCell
                                        onClick={() =>
                                            props.allowFieldEdit
                                                ? props.handleFieldEdit(
                                                      valhead,
                                                      row
                                                  )
                                                : null
                                        }
                                        className={`${
                                            valhead &&
                                            valhead?.type === 'datetime' &&
                                            'ws_noWrap'
                                        }`}
                                    >
                                        {valhead.type == 'image' ? (
                                            <img
                                                className={`tableimage ${
                                                    props.dense && 'small'
                                                }`}
                                                src={row[valhead.field]}
                                                alt={row.title}
                                                onError={(e) =>
                                                    (e.target.src =
                                                        '/assets/images/noImg.png')
                                                }
                                            />
                                        ) : valhead.type == 'input' ? (
                                            <input
                                                value={
                                                    valhead.value[
                                                        valhead.field +
                                                            row[valhead.clickID]
                                                    ] > -1
                                                        ? valhead.value[
                                                              valhead.field +
                                                                  row[
                                                                      valhead
                                                                          .clickID
                                                                  ]
                                                          ]
                                                        : row[valhead.field]
                                                }
                                                onKeyUp={(e) =>
                                                    valhead.onClick(
                                                        e,
                                                        valhead.returnFullRrow
                                                            ? row
                                                            : row[
                                                                  valhead
                                                                      .clickID
                                                              ]
                                                    )
                                                }
                                                onChange={(e) =>
                                                    valhead.onChange(
                                                        e,
                                                        valhead.returnFullRrow
                                                            ? row
                                                            : row[
                                                                  valhead
                                                                      .clickID
                                                              ]
                                                    )
                                                }
                                                name={row.title}
                                                disabled={
                                                    valhead.disabled || false
                                                }
                                                style={{
                                                    width: valhead.inputWidth
                                                        ? valhead.inputWidth +
                                                          '%'
                                                        : '',
                                                }}
                                                className={
                                                    valhead.addClass
                                                        ? valhead.addClass
                                                        : ''
                                                }
                                                id={
                                                    valhead.addId
                                                        ? valhead.addId +
                                                          '-' +
                                                          row[valhead.clickID]
                                                        : ''
                                                }
                                            />
                                        ) : valhead.type == 'tooltip' ? (
                                            <div>
                                                <Button
                                                    size="small"
                                                    aria-owns={
                                                        props.popoverOpen
                                                            ? 'mouseOverPopover'
                                                            : undefined
                                                    }
                                                    aria-haspopup="true"
                                                    onClick={(event) => {
                                                        props.getMissingFields(
                                                            valhead.tooltipType ===
                                                                'field'
                                                                ? row[
                                                                      valhead
                                                                          .tooltipTitle
                                                                  ]?.toString()
                                                                : valhead.tooltipTitle
                                                                ? valhead.tooltipTitle
                                                                : valhead.label
                                                        )
                                                        props.handlePopoverOpen(
                                                            event
                                                        )
                                                    }}
                                                >
                                                    <span
                                                        className={`material-icons statusIcon ${
                                                            row[
                                                                valhead.field
                                                            ] == 'error'
                                                                ? 'error'
                                                                : 'success'
                                                        }`}
                                                    >
                                                        {row[valhead.field]}
                                                    </span>
                                                </Button>
                                            </div>
                                        ) : valhead.type == 'imagewithurl' ||
                                          valhead.name == 'avatar' ? (
                                            <>
                                                {row['avatar_type'] === 2 ? (
                                                    <>
                                                        <img
                                                            className={`tableimage ${
                                                                props.dense &&
                                                                'small'
                                                            }`}
                                                            src={
                                                                valhead.custom
                                                                    ? row[
                                                                          valhead
                                                                              .field
                                                                      ]?.replace(
                                                                          'www.',
                                                                          'staging.'
                                                                      )
                                                                    : `${
                                                                          row[
                                                                              valhead
                                                                                  .field
                                                                          ]
                                                                      }`
                                                            }
                                                            alt={row.title}
                                                            onError={(e) =>
                                                                (e.target.src =
                                                                    '/assets/images/noImg.png')
                                                            }
                                                        />
                                                    </>
                                                ) : (
                                                    <>
                                                        {/* {console.log(
                                                valhead,
                                                row[
                                                    valhead
                                                        .field
                                                ],
                                                'valhead',
                                            )} */}
                                                        <img
                                                            className={`tableimage ${
                                                                props.dense &&
                                                                'small'
                                                            }`}
                                                            src={`${
                                                                global.site_url
                                                            }/uploads/${
                                                                valhead.folder
                                                            }/${
                                                                row[
                                                                    valhead
                                                                        .field
                                                                ]
                                                            }`}
                                                            alt={row.title}
                                                            onError={(e) =>
                                                                (e.target.src =
                                                                    '/assets/images/noImg.png')
                                                            }
                                                        />
                                                    </>
                                                )}
                                            </>
                                        ) : valhead.type ==
                                          'imagewithcompressedurl' ? (
                                            <img
                                                className={`tableimage ${
                                                    props.dense && 'small'
                                                }`}
                                                src={`${
                                                    global.site_url
                                                }/uploads/${
                                                    valhead.isCompress
                                                        ? valhead.folder +
                                                          '/compressed'
                                                        : valhead.folder
                                                }/${row[valhead.field]}`}
                                                alt={row.title}
                                                onError={(e) =>
                                                    (e.target.src =
                                                        '/assets/images/noImg.png')
                                                }
                                            />
                                        ) : valhead.type == 'date' ? (
                                            dateFormatFunction(
                                                row[valhead.field]
                                            )
                                        ) : valhead.type == 'dropdown' ? (
                                            row[valhead.field] !== null &&
                                            selectValues ? (
                                                selectValues
                                                    .filter(
                                                        (inner) =>
                                                            inner.id ===
                                                            parseInt(
                                                                row[
                                                                    valhead
                                                                        .field
                                                                ]
                                                            )
                                                    )
                                                    .map(
                                                        (filtered) =>
                                                            filtered.description
                                                    )
                                            ) : null
                                        ) : // selectValues
                                        //     .filter(
                                        //         (inner) =>
                                        //             inner.id ===
                                        //             row[
                                        //                 valhead
                                        //                     .field
                                        //             ],
                                        //     )
                                        //     .map((filtered) =>
                                        //         // filtered.description,
                                        //         console.log(
                                        //             filtered,
                                        //             'filter',
                                        //         ),
                                        //     )
                                        valhead.type == 'datetime' ? (
                                            dateTimeFormatFunction(
                                                row[valhead.field]
                                            )
                                        ) : valhead.type == 'datetimesecond' ? (
                                            dateTimeSecondFormatFunction(
                                                row[valhead.field]
                                            )
                                        ) : valhead.type == 'price' ? (
                                            row[valhead.field] ? (
                                                currencyFormat(
                                                    row[valhead.field]
                                                )
                                            ) : (
                                                '-'
                                            )
                                        ) : valhead.type == 'capitalize' ? (
                                            capitalize(row[valhead.field])
                                        ) : valhead.type == 'payment_method' ? (
                                            row.payment_method !== 'cash' ? (
                                                `${row.mk_card_number} (${row.card_type}) `
                                            ) : (
                                                capitalize(row.payment_method)
                                            )
                                        ) : valhead.type == 'button' ? (
                                            valhead.isMultiple ? (
                                                <div className="d-flex justify-content-center align-items-center">
                                                    {valhead.isMultiple.map(
                                                        (inner, innerIndex) => (
                                                            <div
                                                                key={innerIndex}
                                                            >
                                                                <Tooltip
                                                                    title={
                                                                        inner.tooltipTitle
                                                                            ? inner.tooltipTitle
                                                                            : valhead.label
                                                                    }
                                                                >
                                                                    <Button
                                                                        id="tableButton"
                                                                        onClick={() => {
                                                                            inner.onclick(
                                                                                valhead.clickID &&
                                                                                    !inner.clickID
                                                                                    ? row[
                                                                                          valhead
                                                                                              .clickID
                                                                                      ]
                                                                                    : inner.clickID
                                                                                    ? row[
                                                                                          inner
                                                                                              .clickID
                                                                                      ]
                                                                                    : row.id,
                                                                                valhead.secondaryClickID
                                                                                    ? row[
                                                                                          valhead
                                                                                              .secondaryClickID
                                                                                      ]
                                                                                    : inner.secondaryClickID
                                                                                    ? row[
                                                                                          inner
                                                                                              .secondaryClickID
                                                                                      ]
                                                                                    : null,
                                                                                inner.clickType
                                                                            )
                                                                        }}
                                                                        disabled={
                                                                            valhead.disabled
                                                                                ? row[
                                                                                      valhead
                                                                                          .disabled
                                                                                  ]
                                                                                : false
                                                                        }
                                                                    >
                                                                        {/*<span className="material-icons">
                                                                {inner.buttonType
                                                                    ? inner.buttonType
                                                                    : 'edit'}
                                                            </span>*/}
                                                                        {row[
                                                                            valhead
                                                                                .disabled
                                                                        ] &&
                                                                        inner.showText ? (
                                                                            <span
                                                                                style={{
                                                                                    textTransform:
                                                                                        'capitalize',
                                                                                    color: 'rgba(0, 0, 0, 0.87)',
                                                                                    cursor: 'text',
                                                                                }}
                                                                            >
                                                                                {
                                                                                    inner.textVal
                                                                                }
                                                                            </span>
                                                                        ) : (
                                                                            <span className="material-icons">
                                                                                {row[
                                                                                    valhead
                                                                                        .disabled
                                                                                ] &&
                                                                                inner.buttonType ===
                                                                                    'sync'
                                                                                    ? 'sync_disabled'
                                                                                    : inner.buttonType
                                                                                    ? inner.buttonType
                                                                                    : 'edit'}
                                                                            </span>
                                                                        )}
                                                                        {/*<span className="material-icons">
                                                                {row[
                                                                    valhead
                                                                        .disabled
                                                                ] &&
                                                                inner.buttonType ===
                                                                    'sync'
                                                                    ? 'sync_disabled'
                                                                    : inner.buttonType
                                                                    ? inner.buttonType
                                                                    : 'edit'}
                                                            </span>*/}
                                                                    </Button>
                                                                </Tooltip>
                                                            </div>
                                                        )
                                                    )}
                                                </div>
                                            ) : (
                                                <Tooltip
                                                    title={
                                                        valhead.tooltipTitle
                                                            ? valhead.tooltipTitle
                                                            : valhead.label
                                                    }
                                                >
                                                    <Button
                                                        id="buttontool"
                                                        onClick={() => {
                                                            valhead.onclick(
                                                                valhead.clickID
                                                                    ? row[
                                                                          valhead
                                                                              .clickID
                                                                      ]
                                                                    : row.id,
                                                                valhead.secondaryClickID
                                                                    ? row[
                                                                          valhead
                                                                              .secondaryClickID
                                                                      ]
                                                                    : null,
                                                                valhead.clickType
                                                            )
                                                        }}
                                                    >
                                                        <span className="material-icons">
                                                            {valhead.buttonType
                                                                ? valhead.buttonType
                                                                : 'edit'}
                                                        </span>
                                                    </Button>
                                                </Tooltip>
                                            )
                                        ) : (
                                            <Tooltip
                                                title={
                                                    valhead.field ===
                                                    'bidscount'
                                                        ? valhead.label
                                                        : ''
                                                }
                                            >
                                                <span
                                                    className={`${
                                                        valhead?.click
                                                            ? 'cursorDecoy'
                                                            : row[
                                                                  valhead.field
                                                              ] == 0
                                                            ? 'pntrEvntNone'
                                                            : 'titleText'
                                                    }`}
                                                    onClick={() => {
                                                        valhead.click
                                                            ? valhead.onclick(
                                                                  valhead.clickID
                                                                      ? row[
                                                                            valhead
                                                                                .clickID
                                                                        ]
                                                                      : row.id,
                                                                  valhead.secondaryClickID
                                                                      ? row[
                                                                            valhead
                                                                                .secondaryClickID
                                                                        ]
                                                                      : null,
                                                                  valhead.clickType
                                                              )
                                                            : valhead.clickable
                                                            ? valhead.onclick(
                                                                  valhead.clickID
                                                                      ? row[
                                                                            valhead
                                                                                .clickID
                                                                        ]
                                                                      : row.id,
                                                                  valhead.secondaryClickID
                                                                      ? row[
                                                                            valhead
                                                                                .secondaryClickID
                                                                        ]
                                                                      : null,
                                                                  valhead.clickType
                                                              )
                                                            : ''
                                                    }}
                                                >
                                                    {valhead.field?.split(',')
                                                        .length > 1 ? (
                                                        <>
                                                            {valhead.field
                                                                .split(',')
                                                                .map(
                                                                    (
                                                                        value,
                                                                        index
                                                                    ) =>
                                                                        `${
                                                                            row[
                                                                                value
                                                                            ] ||
                                                                            ''
                                                                        } `
                                                                )}
                                                        </>
                                                    ) : row[valhead.field] !==
                                                      null ? (
                                                        props.conditionalFormatting(
                                                            row,
                                                            valhead
                                                        )
                                                    ) : (
                                                        '-'
                                                    )}
                                                </span>
                                            </Tooltip>
                                        )}
                                    </TableCell>
                                </>
                            )
                        })}
                    </TableRow>
                )
            })}
        </Reorder>
    )
}

export default CustomCommon
