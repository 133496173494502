import React, { useContext, useState, useEffect } from 'react'
import './ChangePassword.css'
import { useHistory } from 'react-router-dom'
import PrimaryButton from '../../components/atoms/PrimaryButton'
import { mapData, handleRedirectInternal } from '../../common/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import Layout from '../../components/templates/Layout'
import AuthContext from '../../context/auth/authContext'
import Loaders from '../../components/molecules/Loaders'

const ChangePassword = (props) => {
    const history = useHistory()
    const authContext = useContext(AuthContext)
    const [isLoading, setIsLoading] = useState(false)

    const { updateProfile, responseStatus: responseStatusUser } = authContext

    const validationArray = Yup.object({
        current_password: Yup.string().required('Required!'),
        new_password: Yup.string()
            .notOneOf(
                [Yup.ref('current_password')],
                'New password must be different'
            )
            .matches(
                /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]/,
                'Password should contain Upper case ,Lower case ,Number and Special characters'
            )
            .min(8, 'Minimum 8 characters')
            .required('Required'),
        confirm_password: Yup.string()
            .oneOf([Yup.ref('new_password')], 'Passwords not match')
            .required('Required!'),
    })

    const formik = useFormik({
        initialValues: {
            change_password: true,
            current_password: '',
            new_password: '',
            confirm_password: '',
        },
        validationSchema: validationArray,
        onSubmit: (values) => {
            updateProfile(values)
        },
    })

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.from === 'updateProfile') {
                if (responseStatusUser.status === 'success') {
                    handleRedirectInternal(history, '')
                }
            }
        }
    }, [responseStatusUser])

    const changePassword = [
        {
            label: 'Current password',
            name: 'current_password',
            type: 'password',
            placeholder: 'Enter your current password',
            class: 'col-6',
            autoFocus: true,
            formik: formik,
        },
        {
            type: 'misc',
            content: '',
            class: 'col-6 col-xs-12',
        },
        {
            label: 'New Password',
            name: 'new_password',
            type: 'password',
            placeholder: 'Enter your password',
            class: 'col-6 col-xs-12',
            formik: formik,
        },
        {
            label: 'Confirm Password',
            name: 'confirm_password',
            type: 'password',
            placeholder: 'Re-enter your new password',
            class: 'col-6 col-xs-12',
            formik: formik,
        },
    ]

    return (
        <Layout
            noUI={props.noUI || false}
            renderSidePanelDefault={props.renderSidePanelDefault || false}
        >
            <div className="dashboard employeeManagement">
                <div className="layoutTopHeader">
                    <h2>Change Password</h2>
                </div>
                {isLoading ? (
                    <Loaders name="banner" isLoading={isLoading} />
                ) : (
                    <div className="loginCnt">
                        <form onSubmit={formik.handleSubmit}>
                            <div className="row mt-5">
                                {Object.values(mapData(changePassword))}
                                <div className="col-3">
                                    <PrimaryButton
                                        id="password_submit"
                                        label="Update Password"
                                        type="submit"
                                        btnSize="medium"
                                    />
                                </div>
                            </div>
                        </form>
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default ChangePassword
