import {
    GET_ALL_INVOICES,
    GET_INVOICE_DETAILS,
    GET_SINGLE_INVOICE,
    RESPONSE_STATUS,
    GET_MULTIPLE_INVOICE,
    CLEAR_RESPONSE,
} from './invoiceTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_INVOICES:
            return {
                ...state,
                all_invoices: action.payload,
            }

        case GET_SINGLE_INVOICE:
            return {
                ...state,
                single_invoice: action.payload,
            }
        case GET_MULTIPLE_INVOICE:
            return {
                ...state,
                multiple_invoice: action.payload,
            }
        case GET_INVOICE_DETAILS:
            return {
                ...state,
                invoice_details: action.payload,
            }

        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
