import React, { useState, useEffect } from 'react'
import moment from 'moment'
import axios from 'axios'
import { socket } from './socket.js'
import { Calendar } from 'react-multi-date-picker'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'

import CustomSelect from '../components/atoms/Inputs/CustomSelect'
import CustomMultiSelect from '../components/atoms/Inputs/CustomMultiSelect'
import RadioBox from '../components/atoms/RadioBox'

import MenuItem from '@mui/material/MenuItem'
import CustomInput from '../components/atoms/Inputs/CustomInput'
import CustomMaskInput from '../components/atoms/Inputs/CustomMaskInput'
import CustomMaskInputWithHidden from '../components/atoms/Inputs/CustomMaskInputWithHidden'
import GooglePlaceAutoComplete from '../components/atoms/Inputs/GooglePlaceAutoComplete'
import CustomPhone from '../components/atoms/Inputs/CustomPhone.js'
import Webcam from './webcam'
import PasswordStrength from './passwordStrength'
import './productCommon.css'

import CheckBox from '../components/atoms/CheckBox'
import CustomTextArea from '../components/atoms/Inputs/CustomTextArea'
import { CKEditor } from 'ckeditor4-react'
import Uploader from '../../product/common/uploader'

import FormHelperText from '@mui/material/FormHelperText'
import CustomAutocomplete from '../components/atoms/Inputs/CustomAutocomplete.js'
import { Button, Checkbox, Tooltip } from '@mui/material'
import EmailEditor from '../components/atoms/EmailEditor'
import { ListItemText } from '@mui/material'
// import { CKEditor as CKEditor5 } from '@ckeditor/ckeditor5-react'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import MultipleArray from '../components/molecules/MultipleArrayPopup'
import OtpInput from 'react-otp-input'
import GoogleCaptch from '../components/atoms/GoogleRecaptcha/index.js'
import { CKEditor as CKEditor5 } from '@ckeditor/ckeditor5-react'
// import { Editor as ClassicEditor } from 'ckeditor5-custom-build'
import uploader from './ckeditor-uploader'
import { TwitterPicker } from 'react-color'
import { Editor as TINYMCE } from '@tinymce/tinymce-react'
import tinyMCEImageUpload from './tinymce-uploader'

import { DatePicker } from '@mui/x-date-pickers'
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo'
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'

let serverTime = new Date()
const monthFormat = 'YYYY-MM'
const yearFormat = 'YYYY'
const dateFormat = 'YYYY-MM-DD'
const dateTimeFormat = 'MM-DD-YYYY h:mm a'
const dateTimeSecondFormat = 'MM-DD-YYYY h:mm:ss a'
const timeFormat = 'h:mm a'

if (socket) {
    socket.on('sliservertime', (data) => {
        if (moment(data.dTime).isValid()) {
            serverTime = new Date(data.dTime)
        }
    })
}

const isEncodedURIComponent = (str) => {
    try {
        const decoded = decodeURIComponent(str)
        return decoded !== str
    } catch (error) {
        return false
    }
}

const errorCheck = (data, formik) => {
    return data.filter &&
        formik &&
        formik.touched &&
        formik.touched.filters &&
        formik.touched.filters[data.name] &&
        formik.errors &&
        formik.errors.filters &&
        formik.errors.filters[data.name]
        ? formik.errors.filters[data.name]
        : formik.touched[data.name] && formik.errors[data.name]
}

export const noImageAvailable = (e) => {
    e.target.src = '/assets/images/no_image.jpg'
    e.target.onError = null
    e.target.className += ' imgNotFound'
}

const multiSelectValue = (data, formik) => {
    return data.filter
        ? formik.values.filters[data.name].value.length <= data.options.length
            ? data.options
                  .filter((d) =>
                      formik.values.filters[data.name].value.length
                          ? !formik.values.filters[data.name].value.includes(
                                d.value
                            )
                          : true
                  )
                  .map((d) => d.value)
                  .flat()
            : (formik.values.filters[data.name].value.length = 0 && null)
        : formik.values[data.name].length <= data.options.length
        ? data.options
              .filter((d) =>
                  formik.values[data.name].length
                      ? !formik.values[data.name].includes(d.value)
                      : true
              )
              .map((d) => d.value)
              .flat()
        : (formik.values[data.name].length = 0 && null)
}

const helperText = (data, formik) => {
    return data.filter &&
        formik &&
        formik.touched &&
        formik.touched.filters &&
        formik.touched.filters[data.name] &&
        formik.errors &&
        formik.errors.filters &&
        formik.errors.filters[data.name]
        ? formik.errors.filters[data.name]
        : formik.touched[data.name] && formik.errors[data.name]
}

export const sortCategoryAlphabetical = (a, b) => {
    var nameA = a.description.toUpperCase() // ignore upper and lowercase
    var nameB = b.description.toUpperCase() // ignore upper and lowercase
    if (nameA < nameB) {
        return -1
    }
    if (nameA > nameB) {
        return 1
    }

    // names must be equal
    return 0
}
export const calculateAge = (birthday) => {
    let compareDate = moment(
        moment(birthday, dateFormat).format(dateFormat),
        dateFormat
    ).toDate()
    let ageDifMs = Date.now() - compareDate.getTime()
    let ageDate = new Date(ageDifMs) // miliseconds from epoch
    return Math.abs(ageDate.getUTCFullYear() - 1970)
}

export const calculatePercentage = (amount, percent) => {
    let percentAmount = 0
    let totalAmount = 0

    percentAmount = parseFloat(
        parseFloat(amount) * parseFloat(percent / 100)
    ).toFixed(2)
    totalAmount = parseFloat(
        parseFloat(amount) + parseFloat(percentAmount)
    ).toFixed(2)

    return { percentAmount, totalAmount }
}

export const converDateTime = (data) => {
    let dataReturn = null
    if (data) {
        if (moment(data).isValid()) {
            dataReturn = `${moment(data).format(dateTimeFormat)}`
        }
    }
    return dataReturn
}

export const converDate = (data) => {
    let dataReturn = ''
    if (data) {
        if (moment(data).isValid()) {
            dataReturn = `${moment(data).format(dateFormat)}`
        }
    }
    return dataReturn
}

const converTime = (data) => {
    let dataReturn = ''
    if (data) {
        if (moment(data).isValid()) {
            dataReturn = `${moment(data).format(timeFormat)}`
        }
    }
    return dataReturn
}

function shouldDisableDate(date, data) {
    if (data.shouldDisableDate && data.shouldDisableDate.length) {
        let allDates = data.shouldDisableDate
        if (allDates.find((e) => e == date.format('YYYY-MM-DD'))) {
            return true
        } else {
            return false
        }
    } else {
        return false
    }
}

// accepts array dates for data.shouldEnableDate in format 'YYYY-MM-DD'
function shouldEnableDate(date, data) {
    let allDates = data.shouldEnableDate
    if (allDates?.length > 0) {
        return allDates.find((e) => e == date.format('YYYY-MM-DD')) == undefined
    } else {
        return true
    }
}

export const urlFormat = (data) => {
    return data
        ? data.toLowerCase().replaceAll(' ', '_').replaceAll('/', '-')
        : ''
}

export const converYear = (data) => {
    let dataReturn = ''
    if (data) {
        if (moment(data).isValid()) {
            dataReturn = `${moment(data).format(yearFormat)}`
        }
    }
    return dataReturn
}

export const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index += 1) {
        await callback(array[index], index, array)
    }
}

export const insertArrayIndex = (array, index, newItem) => {
    return array.splice(index, 0, ...newItem)
}

export const mapData = (page, multiform, step) => {
    let formik = page.formik ? page.formik : page[0].formik
    let pageData
    if (multiform) {
        pageData = page.data[step]
    } else {
        pageData = page.data ? page.data : page
    }
    let data =
        pageData && pageData.length
            ? pageData.map((data, index) => (
                  <>
                      <div key={index} className={data.class}>
                          {data.type === 'select' ? (
                              <>
                                  <CustomSelect
                                      label={data.label}
                                      id={data.id}
                                      value={
                                          data.value
                                              ? data.value
                                              : data.filter || data.table_search
                                              ? formik.values.filters[data.name]
                                                    .value
                                              : formik.values[data.name]
                                      }
                                      autoFocus={data.autoFocus}
                                      className={data.className}
                                      variant={data.variant}
                                      name={
                                          data.filter
                                              ? `filters.${data.name}.value`
                                              : data.name
                                      }
                                      size={data.size}
                                      onChange={
                                          data.onChange
                                              ? data.onChange
                                              : formik.handleChange
                                      }
                                      placeholder={data.placeholder}
                                      disabled={data.disabled}
                                      onBlur={formik.handleBlur}
                                      type={data.type}
                                      error={errorCheck(data, formik)}
                                      helperText={helperText(data, formik)}
                                      upperLabel={data.upperLabel}
                                  >
                                      {!data.isNoDefaultOptionValue && (
                                          <option
                                              value=""
                                              name={data.placeholder}
                                          >
                                              {data.placeholder}
                                          </option>
                                      )}
                                      {data.options &&
                                          data.options.map((opt, optindex) => (
                                              <option
                                                  key={optindex}
                                                  value={opt.value}
                                                  name={opt.value}
                                              >
                                                  {opt.show}
                                              </option>
                                          ))}

                                      {data.groupoptions &&
                                          data.groupoptions.map(
                                              (opt, optindex) => (
                                                  <>
                                                      <optgroup
                                                          label={opt.head}
                                                      >
                                                          <>
                                                              {opt.options.map(
                                                                  (
                                                                      opt,
                                                                      optindex
                                                                  ) => (
                                                                      <option
                                                                          key={
                                                                              optindex
                                                                          }
                                                                          value={
                                                                              opt.value
                                                                          }
                                                                          name={
                                                                              opt.value
                                                                          }
                                                                      >
                                                                          {
                                                                              opt.show
                                                                          }
                                                                      </option>
                                                                  )
                                                              )}
                                                          </>
                                                      </optgroup>
                                                  </>
                                              )
                                          )}
                                  </CustomSelect>
                              </>
                          ) : data.type === 'autocomplete' ? (
                              <>
                                  <CustomAutocomplete
                                      label={data.label}
                                      id={data.id}
                                      multiple={data.multiple}
                                      onClear={data.onClear}
                                      value={
                                          data.filter
                                              ? formik.values.filters[data.name]
                                                    .value
                                              : formik.values[data.name]
                                      }
                                      autoFocus={data.autoFocus}
                                      name={
                                          data.filter
                                              ? `filters.${data.name}.value`
                                              : data.name
                                      }
                                      size={data.size}
                                      onChange={(val) => {
                                          data.filter
                                              ? formik.setFieldValue(
                                                    `filters.${data.name}.value`,
                                                    val
                                                )
                                              : formik.setFieldValue(
                                                    data.name,
                                                    val
                                                )
                                      }}
                                      placeholder={data.placeholder}
                                      disabled={data.disabled}
                                      onBlur={formik.handleBlur}
                                      options={data.options}
                                      type={data.type}
                                      error={errorCheck(data, formik)}
                                      helperText={helperText(data, formik)}
                                  />
                              </>
                          ) : data.type === 'misc' ? (
                              <>{data.content}</>
                          ) : data.type === 'switch' ? (
                              <>
                                  <FormGroup row className="customToggle">
                                      <FormControlLabel
                                          label={data.label}
                                          control={
                                              <Switch
                                                  checked={
                                                      formik.values[data.name]
                                                  }
                                                  onChange={(val) => {
                                                      formik.setFieldValue(
                                                          data.name,
                                                          val.target.checked
                                                      )
                                                  }}
                                                  name={data.name}
                                                  color={data.color}
                                              />
                                          }
                                      />
                                  </FormGroup>

                                  {formik.errors[data.name] &&
                                      formik.touched[data.name] && (
                                          <div className="checkboxError mb-4">
                                              <FormHelperText>
                                                  {formik.errors[data.name]}
                                              </FormHelperText>
                                          </div>
                                      )}
                              </>
                          ) : data.type === 'check' ? (
                              <>
                                  {data.options &&
                                      data.options.map((opt, optindex) => (
                                          <CheckBox
                                              key={optindex}
                                              name={
                                                  data.filter
                                                      ? `filters.${data.name}.value`
                                                      : data.name
                                              }
                                              disabled={data.disabled}
                                              label={opt.description}
                                              customized={opt.customized}
                                              checked={
                                                  data.filter
                                                      ? formik?.values?.filters[
                                                            data.name
                                                        ].value
                                                            ?.flat()
                                                            .indexOf(
                                                                opt.id.toString()
                                                            ) > -1
                                                      : formik?.values[
                                                            data.name
                                                        ]
                                                            ?.flat()
                                                            .indexOf(
                                                                opt.id.toString()
                                                            ) > -1
                                              }
                                              value={opt.id.toString()}
                                              onChange={
                                                  data.onChange
                                                      ? data.onChange
                                                      : formik.handleChange
                                              }
                                          />
                                      ))}
                                  <div className="checkboxError">
                                      <FormHelperText>
                                          {formik.errors[data.name] &&
                                              formik.touched[data.name] &&
                                              formik.errors[data.name]}
                                      </FormHelperText>
                                  </div>
                              </>
                          ) : data.type === 'uploadDropZone' ? (
                              <>
                                  <Uploader
                                      formik={formik}
                                      name={data.name}
                                      icon={data.icon}
                                      titleText={data.titleText}
                                      innerText={data.innerText}
                                      folder={data.folder}
                                      multiple={data.multiple}
                                      reorder={data.reorder}
                                      accept={data.accept}
                                      objectImage={data.object_image}
                                      isCompress={
                                          data.isCompress
                                              ? data.isCompress
                                              : data.is_compress
                                              ? true
                                              : false
                                      }
                                      quality={
                                          data.quality || data.compress_quality
                                      }
                                      resultWidth={
                                          data.resultWidth ||
                                          data.compress_width
                                      }
                                      resultHeight={
                                          data.resultHeight ||
                                          data.compress_height
                                      }
                                      custom={data.custom}
                                      webcam={data.webcam}
                                      isCrop={data.isCrop}
                                  ></Uploader>
                              </>
                          ) : data.type === 'phone' ? (
                              <>
                                  <CustomPhone
                                      id={data.id}
                                      value={formik.values[data.name]}
                                      autoFocus={data.autoFocus}
                                      name={data.name}
                                      disabled={data.disabled}
                                      shrink={formik.values[data.name] && true}
                                      onBlur={formik.handleBlur}
                                      onChange={(phone) =>
                                          formik.setFieldValue(data.name, phone)
                                      }
                                      label={data.label}
                                      placeholder={data.placeholder}
                                      type={data.type}
                                      error={errorCheck(data, formik)}
                                      helperText={
                                          formik.errors[data.name] &&
                                          formik.touched[data.name] &&
                                          formik.errors[data.name]
                                      }
                                      countryCodeEditable={
                                          data.countryCodeEditable
                                      }
                                      upperLabel={data.upperLabel}
                                      variant={data.variant}
                                  />
                              </>
                          ) : data.type === 'multiselect' ? (
                              <>
                                  <CustomMultiSelect
                                      label={data.label}
                                      id={data.id}
                                      value={
                                          data.filter
                                              ? formik.values.filters[data.name]
                                                    .value || []
                                              : formik.values[data.name] || []
                                      }
                                      autoFocus={data.autoFocus}
                                      name={
                                          data.filter
                                              ? `filters.${data.name}.value`
                                              : data.name
                                      }
                                      onChange={(event, value) => {
                                          let arrayValue =
                                              event.target.value.flat()
                                          let allLength = data.filter
                                              ? formik.values.filters[data.name]
                                                    .value.length ===
                                                data.options.length
                                              : formik.values[data.name]
                                                    .length ===
                                                data.options.length
                                          if (
                                              allLength &&
                                              arrayValue.length ===
                                                  data.options.length
                                          ) {
                                              arrayValue = []
                                          }
                                          data.filter
                                              ? formik.setFieldValue(
                                                    `filters.${data.name}.value`,
                                                    arrayValue
                                                )
                                              : formik.setFieldValue(
                                                    data.name,
                                                    arrayValue
                                                )
                                      }}
                                      placeholder={data.placeholder}
                                      onBlur={formik.handleBlur}
                                      disabled={data.disabled}
                                      type={data.type}
                                      error={errorCheck(data, formik)}
                                      helperText={helperText(data, formik)}
                                      options={data.options}
                                      size={data.size}
                                  >
                                      <MenuItem
                                          value={multiSelectValue(data, formik)}
                                      >
                                          <Checkbox
                                              checked={
                                                  data.filter
                                                      ? formik.values.filters[
                                                            data.name
                                                        ].value?.flat()
                                                            .length ==
                                                        data.options.length
                                                          ? true
                                                          : false
                                                      : formik.values[
                                                            data.name
                                                        ]?.flat().length ==
                                                        data.options.length
                                                      ? true
                                                      : false
                                              }
                                              onChange={(val) => {
                                                  val.target.checked
                                                      ? data.filter
                                                          ? formik.setFieldValue(
                                                                `filters.${data.name}.value`,
                                                                formik.values.filters[
                                                                    data.name
                                                                ].value.concat(
                                                                    multiSelectValue(
                                                                        data,
                                                                        formik
                                                                    )
                                                                )
                                                            )
                                                          : formik.setFieldValue(
                                                                data.name,
                                                                formik.values[
                                                                    data.name
                                                                ].concat(
                                                                    multiSelectValue(
                                                                        data,
                                                                        formik
                                                                    )
                                                                )
                                                            )
                                                      : data.filter
                                                      ? formik.setFieldValue(
                                                            `filters.${data.name}.value`,
                                                            []
                                                        )
                                                      : formik.setFieldValue(
                                                            data.name,
                                                            []
                                                        )
                                              }}
                                          />
                                          <ListItemText primary={'All'} />
                                      </MenuItem>
                                      {data.options.map((opt, optindex) => (
                                          <MenuItem
                                              key={optindex}
                                              value={opt.value}
                                          >
                                              <Checkbox
                                                  checked={
                                                      data.filter
                                                          ? formik?.values?.filters[
                                                                data.name
                                                            ].value
                                                                ?.flat()
                                                                .indexOf(
                                                                    opt.value
                                                                ) > -1
                                                          : formik?.values[
                                                                data.name
                                                            ]
                                                                ?.flat()
                                                                .indexOf(
                                                                    opt.value
                                                                ) > -1
                                                  }
                                                  onChange={
                                                      data.onChange
                                                          ? data.onChange
                                                          : formik.handleChange
                                                  }
                                              />
                                              {opt.show}
                                          </MenuItem>
                                      ))}
                                  </CustomMultiSelect>
                              </>
                          ) : data.type === 'ckeditor5' ? (
                              <>
                                  {data?.label ? (
                                      <label className="datePickerLabel text-left w-100">
                                          {data.label}
                                      </label>
                                  ) : null}
                                  <CKEditor5
                                      editor={ClassicEditor}
                                      config={{
                                          dataFilter: {
                                              disallowedElements: ['script'],
                                              disallowedAttributes: {
                                                  '*': ['on*', 'style'],
                                              },
                                          },
                                          htmlSupport: {
                                              allow: [
                                                  {
                                                      name: /.*/,
                                                      attributes: true,
                                                      classes: true,
                                                      styles: true,
                                                  },
                                              ],
                                          },
                                      }}
                                      onReady={(editor) => {
                                          if (editor)
                                              editor.plugins.get(
                                                  'FileRepository'
                                              ).createUploadAdapter = (
                                                  loader
                                              ) => new uploader(loader)
                                          return editor
                                      }}
                                      data={formik.values[data.name]}
                                      onChange={(event, editor) => {
                                          formik.setFieldValue(
                                              data.name,
                                              editor.getData() || ' '
                                          )
                                      }}
                                  />

                                  <div className="checkboxError">
                                      <FormHelperText>
                                          {formik.errors[data.name] &&
                                              formik.touched[data.name] &&
                                              formik.errors[data.name]}
                                      </FormHelperText>
                                  </div>
                              </>
                          ) : data.type === 'ckeditor' ? (
                              <>
                                  {/* <CKEditor
                        editor={ClassicEditor}
                        data={formik.values[data.name] ? formik.values[data.name] : ''}
                        onChange={(event, editor) => {
                            formik.setFieldValue(data.name, editor.getData())
                        }}
                    /> */}
                                  {data?.label ? (
                                      <label className="datePickerLabel text-left w-100">
                                          {data.label}
                                      </label>
                                  ) : null}
                                  <CKEditor
                                      config={{
                                          allowedContent: true,
                                      }}
                                      type="classic"
                                      initData={formik.values[data.name]}
                                      onChange={(event, editor) => {
                                          formik.setFieldValue(
                                              data.name,
                                              event.editor.getData()
                                          )
                                      }}
                                      disableEnforceFocus
                                      onInstanceReady={({ editor }) => {
                                          editor.setData(
                                              formik.values[data.name]
                                          )
                                      }}
                                  />
                                  <div className="checkboxError">
                                      <FormHelperText>
                                          {formik.errors[data.name] &&
                                              formik.touched[data.name] &&
                                              formik.errors[data.name]}
                                      </FormHelperText>
                                  </div>
                              </>
                          ) : data.type === 'advancedEditor' ? (
                              <>
                                  {data?.label ? (
                                      <label className="datePickerLabel text-left w-100">
                                          {data.label}
                                      </label>
                                  ) : null}
                                  <EmailEditor
                                      reload={data.reload}
                                      data={data}
                                      formik={formik}
                                      name={
                                          data.filter
                                              ? `filters.${data.name}.value`
                                              : data.name
                                      }
                                  />
                                  <div className="checkboxError">
                                      <FormHelperText>
                                          {formik.errors[data.name] &&
                                              formik.touched[data.name] &&
                                              formik.errors[data.name]}
                                      </FormHelperText>
                                  </div>
                              </>
                          ) : data.type === 'password_checker' ? (
                              <>
                                  <PasswordStrength
                                      data={data}
                                      field={
                                          data.field ? data.field : 'password'
                                      }
                                  />
                              </>
                          ) : data.type === 'date' ? (
                              data.upperLabel ? (
                                  <DemoContainer components={['DatePicker']}>
                                      <label className="datePickerLabel">
                                          {data.label}
                                      </label>
                                      <DatePicker
                                          margin="0"
                                          autoOk={true}
                                          showTodayButton={true}
                                          id={data.name}
                                          name={data.name}
                                          variant="outlined"
                                          format={dateFormat}
                                          placeholder={data.placeholder || ''}
                                          disabled={data.disabled}
                                          disableFuture={
                                              data.disableFuture || false
                                          }
                                          disablePast={
                                              data.disablePast || false
                                          }
                                          maxDate={
                                              data.maxDate ||
                                              moment('01-01-2100').format(
                                                  dateFormat
                                              )
                                          }
                                          maxDateMessage={
                                              data.maxDateMessage ||
                                              'Date should not be after maximal date'
                                          }
                                          minDate={
                                              data.minDate ||
                                              moment('01-01-1900').format(
                                                  dateFormat
                                              )
                                          }
                                          minDateMessage={
                                              data.minDateMessage ||
                                              'Date should not be before minimal date'
                                          }
                                          className="customDatepicker"
                                          //   inputValue={
                                          //       data.filter
                                          //           ? formik.values.filters[
                                          //                 data.name
                                          //             ].value || null
                                          //           : formik.values[data.name] ||
                                          //             null
                                          //   }

                                          value={
                                              data.filter
                                                  ? moment(
                                                        formik.values.filters[
                                                            data.name
                                                        ].value,
                                                        dateFormat
                                                    ) || null
                                                  : moment(
                                                        formik.values[
                                                            data.name
                                                        ],
                                                        dateFormat
                                                    ) || null
                                          }
                                          onChange={(val) => {
                                              data.filter
                                                  ? formik.setFieldValue(
                                                        `filters.${data.name}.value`,
                                                        converDate(val)
                                                    )
                                                  : formik.setFieldValue(
                                                        data.name,
                                                        converDate(val)
                                                    )
                                          }}
                                          KeyboardButtonProps={{
                                              'aria-label': 'change date',
                                          }}
                                          error={
                                              formik.touched[data.name] &&
                                              formik.errors[data.name]
                                          }
                                          helperText={
                                              helperText(data, formik)
                                                  ? helperText(data, formik)
                                                  : data.helperText
                                          }
                                          size={data.size}
                                      />
                                  </DemoContainer>
                              ) : (
                                  <DemoContainer components={['DatePicker']}>
                                      <>
                                          <DatePicker
                                              margin="0"
                                              autoOk={true}
                                              showTodayButton={true}
                                              id={data.name}
                                              name={data.name}
                                              label={data.label}
                                              variant="outlined"
                                              format={dateFormat}
                                              placeholder={
                                                  data.placeholder || ''
                                              }
                                              disabled={data.disabled}
                                              disableFuture={
                                                  data.disableFuture || false
                                              }
                                              disablePast={
                                                  data.disablePast || false
                                              }
                                              maxDate={
                                                  data.maxDate ||
                                                  moment('2100-01-01')
                                              }
                                              maxDateMessage={
                                                  data.maxDateMessage ||
                                                  'Date should not be after maximal date'
                                              }
                                              minDate={
                                                  data.minDate ||
                                                  moment('1900-01-01')
                                              }
                                              minDateMessage={
                                                  data.minDateMessage ||
                                                  'Date should not be before minimal date'
                                              }
                                              className="customDatepicker"
                                              // inputValue={
                                              //     data.filter
                                              //         ? formik.values.filters[data.name].value ||
                                              //           null
                                              //         : formik.values[data.name] || null
                                              // }
                                              value={
                                                  data.filter
                                                      ? formik.values.filters[
                                                            data.name
                                                        ].value
                                                          ? moment(
                                                                formik.values
                                                                    .filters[
                                                                    data.name
                                                                ].value,
                                                                dateFormat
                                                            )
                                                          : null
                                                      : formik.values[data.name]
                                                      ? moment(
                                                            formik.values[
                                                                data.name
                                                            ],
                                                            dateFormat
                                                        )
                                                      : null
                                              }
                                              onChange={(val) => {
                                                  data.filter
                                                      ? formik.setFieldValue(
                                                            `filters.${data.name}.value`,
                                                            val
                                                        )
                                                      : formik.setFieldValue(
                                                            data.name,
                                                            val
                                                        )
                                              }}
                                              KeyboardButtonProps={{
                                                  'aria-label': 'change date',
                                              }}
                                              //   error={
                                              //       formik.touched[data.name] &&
                                              //       formik.errors[data.name]
                                              //   }
                                              helperText={
                                                  helperText(data, formik)
                                                      ? helperText(data, formik)
                                                      : data.helperText
                                              }
                                              size={data.size}
                                          />
                                      </>
                                  </DemoContainer>
                              )
                          ) : data.type == 'multidate' ? (
                              <>
                                  <div className="mb-4">
                                      <span>{data.label}:</span>
                                      <Calendar
                                          multiple
                                          minDate={new Date()}
                                          value={formik.values[data.name]}
                                          onChange={(val) => {
                                              formik.setFieldValue(
                                                  data.name,
                                                  val
                                              )
                                          }}
                                      />
                                  </div>
                              </>
                          ) : data.type === 'dateWithCustomPicker' ? (
                              <DemoContainer components={['DatePicker']}>
                                  <DatePicker
                                      margin="0"
                                      autoOk={true}
                                      showTodayButton={
                                          typeof data.showTodayButton ===
                                          'boolean'
                                              ? data.showTodayButton
                                              : true
                                      }
                                      id={data.name}
                                      name={data.name}
                                      open={data.open}
                                      onOpen={data.onOpen ? data.onOpen : null}
                                      onClose={
                                          data.onClose ? data.onClose : null
                                      }
                                      variant="outlined"
                                      label={data.label}
                                      format={dateFormat}
                                      disabled={data.disabled}
                                      disableFuture={
                                          data.disableFuture || false
                                      }
                                      disablePast={data.disablePast || false}
                                      maxDate={
                                          data.maxDate ||
                                          moment('01-01-2100').format(
                                              dateFormat
                                          )
                                      }
                                      maxDateMessage={
                                          data.maxDateMessage ||
                                          'Date should not be after maximal date'
                                      }
                                      minDate={
                                          data.minDate ||
                                          moment('01-01-1900').format(
                                              dateFormat
                                          )
                                      }
                                      minDateMessage={
                                          data.minDateMessage ||
                                          'Date should not be before minimal date'
                                      }
                                      className="customDatepicker"
                                      //   inputValue={
                                      //       data.filter
                                      //           ? formik.values.filters[data.name]
                                      //                 .value || null
                                      //           : formik.values[data.name] || null
                                      //   }
                                      value={moment(
                                          formik.values[data.name],
                                          yearFormat
                                      )}
                                      onChange={(val) => {
                                          formik.setFieldValue(
                                              data.name,
                                              moment(val).format(yearFormat)
                                          )
                                      }}
                                      KeyboardButtonProps={{
                                          'aria-label': 'change date',
                                      }}
                                      error={
                                          formik.touched[data.name] &&
                                          formik.errors[data.name]
                                      }
                                      helperText={
                                          formik.errors[data.name] &&
                                          formik.touched[data.name] &&
                                          formik.errors[data.name]
                                      }
                                      size={data.size}
                                      shouldDisableDate={(date) => {
                                          return data.shouldDisableDate
                                              ? shouldDisableDate(date, data)
                                              : data.shouldEnableDate
                                              ? shouldEnableDate(date, data)
                                              : false
                                      }}
                                  />
                              </DemoContainer>
                          ) : data.type === 'month' ? (
                              <DemoContainer components={['DatePicker']}>
                                  <DatePicker
                                      margin="0"
                                      autoOk={true}
                                      openTo="month"
                                      views={['year', 'month']}
                                      showTodayButton={true}
                                      id={data.name}
                                      name={data.name}
                                      variant="outlined"
                                      label={data.label}
                                      disablePast={data.disablePast || false}
                                      maxDate={
                                          data.maxDate ||
                                          moment('01-01-2100').format(
                                              dateFormat
                                          )
                                      }
                                      maxDateMessage={
                                          data.maxDateMessage ||
                                          'Date should not be after maximal date'
                                      }
                                      minDate={
                                          data.minDate ||
                                          moment('01-01-1900').format(
                                              dateFormat
                                          )
                                      }
                                      minDateMessage={
                                          data.minDateMessage ||
                                          'Date should not be before minimal date'
                                      }
                                      format={monthFormat}
                                      disabled={data.disabled}
                                      className="customDatepicker"
                                      //   inputValue={formik.values[data.name]}
                                      value={moment(
                                          formik.values[data.name],
                                          yearFormat
                                      )}
                                      onChange={(val) => {
                                          formik.setFieldValue(
                                              data.name,
                                              moment(val).format(yearFormat)
                                          )
                                      }}
                                      KeyboardButtonProps={{
                                          'aria-label': 'change date',
                                      }}
                                      error={
                                          formik.touched[data.name] &&
                                          formik.errors[data.name]
                                      }
                                      helperText={
                                          formik.errors[data.name] &&
                                          formik.touched[data.name] &&
                                          formik.errors[data.name]
                                      }
                                  />
                              </DemoContainer>
                          ) : data.type === 'datetime' ? (
                              <DemoContainer components={['DateTimePicker']}>
                                  <DateTimePicker
                                      margin="0"
                                      autoOk={
                                          data.autoOk === false ? false : true
                                      }
                                      showTodayButton={
                                          typeof data.showTodayButton ===
                                          'boolean'
                                              ? data.showTodayButton
                                              : true
                                      }
                                      id={data.name}
                                      name={data.name}
                                      variant="outlined"
                                      label={data.label}
                                      format={dateTimeFormat}
                                      disabled={data.disabled}
                                      disablePast={data.disablePast || false}
                                      strictCompareDates={
                                          data.strictCompareDates || false
                                      }
                                      //   maxDate={
                                      //       data.maxDate ||
                                      //       moment('01/01/2100')
                                      //   }
                                      //   maxDateMessage={
                                      //       data.maxDateMessage ||
                                      //       'Date should not be after maximal date'
                                      //   }
                                      //   minDate={
                                      //       data.minDate ||
                                      //       moment('01/01/1900')
                                      //   }
                                      //   minDateMessage={
                                      //       data.minDateMessage ||
                                      //       'Date should not be before minimal date'
                                      //   }
                                      className="customDatepicker"
                                      //   inputValue={
                                      //       data.table_search
                                      //           ? formik.values.filters[data.name]
                                      //                 .value
                                      //           : formik.values[data.name]
                                      //   }
                                      value={
                                          formik.values[data.name]
                                              ? moment(
                                                    formik.values[data.name],
                                                    dateTimeFormat
                                                )
                                              : null
                                      }
                                      onChange={(val) => {
                                          formik.setFieldValue(
                                              data.name,
                                              moment(val).format(dateTimeFormat)
                                          )
                                      }}
                                      KeyboardButtonProps={{
                                          'aria-label': 'change date',
                                      }}
                                      error={
                                          formik.touched[data.name] &&
                                          formik.errors[data.name]
                                      }
                                      helperText={
                                          formik.errors[data.name] &&
                                          formik.touched[data.name] &&
                                          formik.errors[data.name]
                                      }
                                      componentsProps={{
                                          inputAdornment: {
                                              position: data.icon_only
                                                  ? 'start'
                                                  : 'end',
                                          },

                                          textField: {
                                              InputProps: {
                                                  readOnly: data.icon_only
                                                      ? true
                                                      : false,
                                              },
                                          },
                                      }}
                                  />
                              </DemoContainer>
                          ) : data.type === 'year' ? (
                              <DemoContainer components={['DatePicker']}>
                                  <DateTimePicker
                                      margin="0"
                                      autoOk={true}
                                      showTodayButton={true}
                                      id={data.name}
                                      openTo="year"
                                      views={['year']}
                                      name={data.name}
                                      label={data.label}
                                      variant="outlined"
                                      format={yearFormat}
                                      placeholder={data.placeholder || ''}
                                      disabled={data.disabled}
                                      disableFuture={
                                          data.disableFuture || false
                                      }
                                      disablePast={data.disablePast || false}
                                      maxDate={
                                          data.maxDate ||
                                          moment('01-01-2100').format(
                                              dateFormat
                                          )
                                      }
                                      maxDateMessage={
                                          data.maxDateMessage ||
                                          'Date should not be after maximal date'
                                      }
                                      minDate={
                                          data.minDate ||
                                          moment('01-01-1900').format(
                                              dateFormat
                                          )
                                      }
                                      minDateMessage={
                                          data.minDateMessage ||
                                          'Date should not be before minimal date'
                                      }
                                      className="customDatepicker"
                                      //   inputValue={
                                      //       data.filter
                                      //           ? formik.values.filters[data.name]
                                      //                 .value || null
                                      //           : formik.values[data.name] || null
                                      //   }
                                      value={moment(
                                          formik.values[data.name],
                                          yearFormat
                                      )}
                                      onChange={(val) => {
                                          formik.setFieldValue(
                                              data.name,
                                              moment(val).format(yearFormat)
                                          )
                                      }}
                                      KeyboardButtonProps={{
                                          'aria-label': 'change date',
                                      }}
                                      error={
                                          formik.touched[data.name] &&
                                          formik.errors[data.name]
                                      }
                                      helperText={
                                          helperText(data, formik)
                                              ? helperText(data, formik)
                                              : data.helperText
                                      }
                                      size={data.size}
                                  />
                              </DemoContainer>
                          ) : data.type === 'time' ? (
                              <DemoContainer components={['TimePicker']}>
                                  <>
                                      {console.log(
                                          moment(
                                              formik.values[data.name]
                                          ).format(timeFormat),
                                          'timeFormik'
                                      )}
                                      <TimePicker
                                          margin="0"
                                          autoOk={true}
                                          id={data.name}
                                          name={data.name}
                                          variant="outlined"
                                          format={timeFormat}
                                          label={data.label}
                                          disabled={data.disabled}
                                          disablePast={
                                              data.disablePast || false
                                          }
                                          className="customDatepicker"
                                          //   inputValue={formik.values[data.name]}
                                          value={
                                              formik.values[data.name]
                                                  ? moment(
                                                        formik.values[
                                                            data.name
                                                        ],
                                                        timeFormat
                                                    )
                                                  : null
                                          }
                                          onChange={(val) => {
                                              data.filter || data.table_search
                                                  ? formik.setFieldValue(
                                                        `filters.${data.name}.value`,
                                                        moment(val).format(
                                                            timeFormat
                                                        )
                                                    )
                                                  : formik.setFieldValue(
                                                        data.name,
                                                        moment(val).format(
                                                            timeFormat
                                                        )
                                                    )
                                          }}
                                          KeyboardButtonProps={{
                                              'aria-label': 'change time',
                                          }}
                                          error={
                                              formik.touched[data.name] &&
                                              formik.errors[data.name]
                                          }
                                          helperText={
                                              formik.errors[data.name] &&
                                              formik.touched[data.name] &&
                                              formik.errors[data.name]
                                          }
                                      />
                                  </>
                              </DemoContainer>
                          ) : data.type === 'textarea' ? (
                              <>
                                  <CustomTextArea
                                      id={data.id}
                                      value={formik.values[data.name]}
                                      autoFocus={data.autoFocus}
                                      name={data.name}
                                      disabled={data.disabled}
                                      shrink={formik.values[data.name] && true}
                                      onBlur={formik.handleBlur}
                                      onChange={
                                          data.onChange
                                              ? data.onChange
                                              : formik.handleChange
                                      }
                                      label={data.label}
                                      placeholder={data.placeholder}
                                      rowsMax={data.rowsMax}
                                      rows={data.rows}
                                      type={data.type}
                                      upperLabel={data.upperLabel}
                                      error={
                                          formik.touched[data.name] &&
                                          formik.errors[data.name]
                                      }
                                      helperText={
                                          formik.errors[data.name] &&
                                          formik.touched[data.name] &&
                                          formik.errors[data.name]
                                      }
                                  />
                              </>
                          ) : data.type === 'checkbox' ? (
                              <>
                                  <CheckBox
                                      checked={formik.values[data.name]}
                                      label={data.label}
                                      value={true}
                                      customized={data.customized}
                                      disabled={data.disabled}
                                      onChange={() => {
                                          formik.setFieldValue(
                                              data.name,
                                              !formik.values[data.name]
                                          )
                                      }}
                                      name={data.name}
                                      error={
                                          formik.touched[data.name] &&
                                          formik.errors[data.name]
                                      }
                                      helperText={
                                          formik.errors[data.name] &&
                                          formik.touched[data.name] &&
                                          formik.errors[data.name]
                                      }
                                  />
                              </>
                          ) : data.type === 'checkboxarray' ? (
                              data.item.map((d, i) => (
                                  <>
                                      <CheckBox
                                          name={data.name}
                                          label={d.description}
                                          checked={
                                              formik.values[data.name].indexOf(
                                                  d.id.toString()
                                              ) !== -1
                                                  ? true
                                                  : false
                                          }
                                          disabled={d.disabled || false}
                                          value={d.id.toString()}
                                          onChange={formik.handleChange}
                                      />
                                  </>
                              ))
                          ) : data.type === 'google_place_autocomplete' ? (
                              <>
                                  <GooglePlaceAutoComplete
                                      id={data.id}
                                      name={data.name}
                                      value={formik.values[data.name]}
                                      formik={formik}
                                      label={data.label}
                                      googlePlaceType={data.googlePlaceType}
                                      error={
                                          formik.touched[data.name] &&
                                          formik.errors[data.name]
                                      }
                                      placeID={data.placeID}
                                      helperText={
                                          formik.errors[data.name] &&
                                          formik.touched[data.name] &&
                                          formik.errors[data.name]
                                      }
                                  />
                              </>
                          ) : data.type === 'radio' ? (
                              <>
                                  <RadioBox
                                      error={
                                          formik.touched[data.name] &&
                                          formik.errors[data.name]
                                      }
                                      helperText={
                                          formik.errors[data.name] &&
                                          formik.touched[data.name] &&
                                          formik.errors[data.name]
                                      }
                                      title={data.title}
                                      name={
                                          data.filter
                                              ? `filters.${data.name}.value`
                                              : data.name
                                      }
                                      items={data.item || data.options}
                                      value={
                                          data.filter
                                              ? formik.values.filters[data.name]
                                                    .value
                                              : formik.values[data.name]
                                      }
                                      onChange={formik.handleChange}
                                      int={data.int}
                                      disabled={data.disabled}
                                  />
                              </>
                          ) : data.type === 'mask' ? (
                              <>
                                  <CustomMaskInput
                                      id={data.id}
                                      maskValue={data.maskValue}
                                      value={
                                          data.filter
                                              ? formik.values.filters[data.name]
                                                    .value
                                              : formik.values[data.name]
                                      }
                                      autoFocus={data.autoFocus}
                                      name={
                                          data.filter
                                              ? `filters.${data.name}.value`
                                              : data.name
                                      }
                                      disabled={data.disabled}
                                      shrink={
                                          data.filter
                                              ? formik.values.filters[data.name]
                                                    .value
                                                  ? true
                                                  : false
                                              : formik.values[data.name]
                                              ? true
                                              : false
                                      }
                                      onBlur={formik.handleBlur}
                                      onChange={
                                          data.onChange
                                              ? data.onChange
                                              : formik.handleChange
                                      }
                                      label={data.label}
                                      placeholder={data.placeholder}
                                      type={data.type}
                                      variant={data.variant}
                                      size={data.size}
                                      startAdornment={data.startAdornment}
                                      endAdornment={data.endAdornment}
                                      error={errorCheck(data, formik)}
                                      helperText={
                                          helperText(data, formik)
                                              ? helperText(data, formik)
                                              : data.helperText
                                      }
                                      inputStyle={data.inputStyle}
                                      upperLabel={data.upperLabel}
                                      tooltiptitle={data.tooltiptitle}
                                  />
                              </>
                          ) : data.type === 'multipleArrayValue' ? (
                              <>
                                  <MultipleArray
                                      data={data}
                                      formik={formik}
                                      id={data.id}
                                      error={errorCheck(data, formik)}
                                      helperText={
                                          helperText(data, formik)
                                              ? helperText(data, formik)
                                              : data.helperText
                                      }
                                  />
                              </>
                          ) : data.type === 'hiddenMask' ? (
                              <>
                                  <CustomMaskInputWithHidden
                                      id={data.id}
                                      maskValue={data.maskValue}
                                      value={
                                          data.filter
                                              ? formik.values.filters[data.name]
                                                    .value
                                              : formik.values[data.name]
                                      }
                                      autoFocus={data.autoFocus}
                                      name={
                                          data.filter
                                              ? `filters.${data.name}.value`
                                              : data.name
                                      }
                                      disabled={data.disabled}
                                      shrink={
                                          data.filter
                                              ? formik.values.filters[data.name]
                                                    .value
                                                  ? true
                                                  : false
                                              : formik.values[data.name]
                                              ? true
                                              : false
                                      }
                                      onBlur={formik.handleBlur}
                                      onChange={
                                          data.onChange
                                              ? data.onChange
                                              : formik.handleChange
                                      }
                                      label={data.label}
                                      placeholder={data.placeholder}
                                      type={data.type}
                                      variant={data.variant}
                                      size={data.size}
                                      startAdornment={data.startAdornment}
                                      endAdornment={data.endAdornment}
                                      error={errorCheck(data, formik)}
                                      helperText={
                                          helperText(data, formik)
                                              ? helperText(data, formik)
                                              : data.helperText
                                      }
                                      inputStyle={data.inputStyle}
                                      upperLabel={data.upperLabel}
                                      tooltiptitle={data.tooltiptitle}
                                      modal={
                                          data.modalPP ? data.modalPP : false
                                      }
                                  />
                              </>
                          ) : data.type === 'otpInput' ? (
                              <>
                                  {console.log(
                                      data,
                                      formik.values[data.name],
                                      'viewData'
                                  )}
                                  <OtpInput
                                      value={
                                          data.value
                                              ? data.value
                                              : formik.values[data.name]
                                      }
                                      onChange={(val) => {
                                          formik.setFieldValue(data.name, val)
                                      }}
                                      numInputs={
                                          data.inputCount ? data.inputCount : 6
                                      }
                                      separator={
                                          data.separator ? (
                                              data.separator
                                          ) : (
                                              <span>-</span>
                                          )
                                      }
                                      containerStyle="otpInput"
                                      isDisabled={
                                          data.isDisabled
                                              ? data.isDisabled
                                              : false
                                      }
                                      isInputSecure={
                                          data.isInputSecure
                                              ? data.isInputSecure
                                              : false
                                      }
                                      shouldAutoFocus={
                                          data.shouldAutoFocus
                                              ? data.shouldAutoFocus
                                              : false
                                      }
                                      placeholder={
                                          data.placeholder
                                              ? data.placeholder
                                              : ''
                                      }
                                  />
                              </>
                          ) : data.type === 'colorpicker' ? (
                              <>
                                  {data?.label ? (
                                      <div className="datePickerLabelCnt d-flex justify-content-between w-100">
                                          <label className="datePickerLabel text-left">
                                              {data.label}
                                          </label>
                                          <Button
                                              onClick={() =>
                                                  formik.setFieldValue(
                                                      data.name,
                                                      ''
                                                  )
                                              }
                                              variant="outlined"
                                          >
                                              <span className="material-icons">
                                                  clear
                                              </span>
                                              Clear
                                          </Button>
                                      </div>
                                  ) : null}
                                  <TwitterPicker
                                      color={formik.values[data.name]}
                                      onChangeComplete={(color) =>
                                          formik.setFieldValue(
                                              data.name,
                                              color.hex
                                          )
                                      }
                                  />
                                  <div className="checkboxError">
                                      <FormHelperText>
                                          {formik.errors[data.name] &&
                                              formik.touched[data.name] &&
                                              formik.errors[data.name]}
                                      </FormHelperText>
                                  </div>
                              </>
                          ) : data.type === 'captcha' ? (
                              <>
                                  <GoogleCaptch
                                      data={data}
                                      formik={formik}
                                      id={data.id}
                                      error={errorCheck(data, formik)}
                                      helperText={
                                          helperText(data, formik)
                                              ? helperText(data, formik)
                                              : data.helperText
                                      }
                                  />
                              </>
                          ) : data.type === 'tinymce' ? (
                              <>
                                  {data?.label ? (
                                      <label className="datePickerLabel text-left w-100">
                                          {data.label}
                                      </label>
                                  ) : null}
                                  <TINYMCE
                                      //initialValue={formik.values[data.name]}
                                      value={formik.values[data.name]}
                                      //apiKey="npza6iqlfcih82txxmivvlbj7ra000zmh9ccmlul0vvv5sul" - live
                                      apiKey={process.env.REACT_APP_TINYMCEKEY}
                                      init={{
                                          menubar: true,
                                          plugins: [
                                              'print',
                                              'preview',
                                              'powerpaste',
                                              'casechange',
                                              'importcss',
                                              'searchplace',
                                              'autolink',
                                              'directonaity',
                                              'advcode',
                                              'visualblock',
                                              'visualchars',
                                              'fullscreen',
                                              'image',
                                              'link',
                                              'media',
                                              'mediaembed',
                                              //'template',
                                              'codesample',
                                              'table',
                                              'charmap',
                                              'hr',
                                              'pagebreak',
                                              'nonbreaking',
                                              'anchor',
                                              'toc',
                                              'insertdatetime',
                                              'advist',
                                              'lists',
                                              'checklists',
                                              'wordcount',
                                              //'tinymcespellchecker',
                                              'a11ychecker',
                                              'imagetools',
                                              'textpattern',
                                              'noneditable',
                                              'help',
                                              'formatpainter',
                                              'permanentpen',
                                              'pageembed',
                                              //'tinycomments',
                                              //'mentions',
                                              'quickbar',
                                              'linkchecker',
                                              'emoticons',
                                              'advtable',
                                              'export',
                                              'code',
                                          ],
                                          menubar:
                                              'file edit view insert format tools table tc help',
                                          toolbar:
                                              'undo redo | bold italic underline strikethrough | fontselect insert styleselect | alignleft aligncenter alignright alignjustify | outdent indent | numlist bullist checklist | forecolor backcolor casechange permanentpen removeformat| pagebreak | charmap emoticons | fullscreen preview print | insertfile image media pageembed link anchor codesample | a11ycheck ltr rtl',
                                          // | insert | styleselect | casechange blocks | bold italic forecolor backcolor | bullist numlist checklist outdent indent | removeformat | allycheck code table | link image code',
                                          quickbars_selection_toolbar:
                                              'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
                                          toolbar_mode: 'sliding',
                                          spellchecker_ignore_list: [
                                              'Ephox',
                                              'Moxiecode',
                                          ],
                                          content_style: '',
                                          contextmenu:
                                              'link image imagetools table configurepermanentpen',
                                          content_style: '',
                                          powerpaste_allow_local_images: true,
                                          powerpaste_word_import: 'prompt',
                                          powerpaste_html_import: 'prompt',
                                          images_upload_handler:
                                              tinyMCEImageUpload,
                                      }}
                                      /* onEditorChange={(nexTxt, editor) => {
                                if (nexTxt) {
                                    clearTimeout(tinymceEditorChangeHandler)
                                    tinymceEditorChangeHandler = setTimeout(() => {
                                        formik.setFieldValue(data.name, nexTxt)
                                        editor.focus()
                                        editor.selection.select(editor.getBody(), true)
                                        editor.selection.collapse(false)
                                    }, 1000)
                                }
                            }} */
                                      onEditorChange={(event, editor) => {
                                          data.onChange
                                              ? data.onChange(event, editor)
                                              : formik.setFieldValue(
                                                    data.name,
                                                    editor.getContent() || ' '
                                                )
                                      }}
                                  />

                                  <div className="checkboxError">
                                      <FormHelperText>
                                          {formik.errors[data.name] &&
                                              formik.touched[data.name] &&
                                              formik.errors[data.name]}
                                      </FormHelperText>
                                  </div>
                              </>
                          ) : (
                              <>
                                  <CustomInput
                                      id={data.id}
                                      value={
                                          data.filter || data.table_search
                                              ? formik.values.filters[data.name]
                                                    .value
                                              : formik.values[data.name]
                                      }
                                      autoFocus={data.autoFocus}
                                      name={
                                          data.filter || data.table_search
                                              ? `filters.${data.name}.value`
                                              : data.name
                                      }
                                      disabled={data.disabled}
                                      shrink={
                                          data.filter || data.table_search
                                              ? formik.values.filters[data.name]
                                                    .value
                                                  ? true
                                                  : false
                                              : formik.values[data.name]
                                              ? true
                                              : false
                                      }
                                      onBlur={formik.handleBlur}
                                      onChange={
                                          data.onChange
                                              ? data.onChange
                                              : formik.handleChange
                                      }
                                      label={data.label}
                                      placeholder={data.placeholder}
                                      type={data.type}
                                      variant={data.variant}
                                      size={data.size}
                                      startAdornment={data.startAdornment}
                                      endAdornment={data.endAdornment}
                                      error={errorCheck(data, formik)}
                                      helperText={
                                          helperText(data, formik)
                                              ? helperText(data, formik)
                                              : data.helperText
                                      }
                                      inputStyle={data.inputStyle}
                                      upperLabel={data.upperLabel}
                                      tooltiptitle={data.tooltiptitle}
                                      readonly={data.readonly}
                                  />
                              </>
                          )}
                      </div>
                  </>
              ))
            : null
    return data
}

export const handleRedirectInternal = (history, path) => {
    history.push(`/${path}`)
    window.scrollTo(0, 0)
}

export const dateFormatFront = (data) => {
    return moment(data).isValid()
        ? `${moment(data).format('MMM Do')}, ${moment(data).format('h:mm a')}`
        : '-'
}

export const dateFormatFrontDay = (data) => {
    return `${moment(data).format('MMM Do YYYY')}`
}

export const dateFormatFrontTime = (data) => {
    return `${moment(data).format('h:mm a')}`
}

export const checkProductOpen = (date_added, user_role) => {
    let startDate = new Date(serverTime)
    let addDate = new Date(date_added)
    let milliSeconds = 0
    // if (parseInt(user_role) !== 3) {
    //   milliSeconds = 1 * 3600000;
    // } else {
    // }
    let incrementedTime = addDate.getTime() + parseInt(milliSeconds)
    let newaddDate = new Date(incrementedTime)
    if (newaddDate > startDate) {
        return false
        // let incrementedTime = addDate.getTime() + parseInt(milliSeconds);
        // let timerTime = incrementedTime - startDate.getTime();
        // if (timerTime <= 0) {
        //   return true;
        // } else {
        //   return false;
        // }
    } else {
        return true
    }
}

export const maskEmailFront = (data) => {
    data = data.toString()
    var first4 = data.substring(0, 4)
    var last5 = data.substring(data.length - 5)
    var mask = data.substring(4, data.length - 5).replace(/[^]/g, '*')
    return first4 + mask + last5
}

export const capitalize = (data) => {
    let dataReturn = '-'
    if (data) {
        data = data.replace(/_/g, ' ').toLowerCase()
        data.split(' ')
        if (data instanceof Array) {
            dataReturn = data
                .map((word) => {
                    return word[0].toUpperCase() + word.substring(1)
                })
                .join(' ')
        } else {
            dataReturn = data.charAt(0).toUpperCase() + data.slice(1)
        }
    }
    return dataReturn
}

export const currencyFormat = (data, locale = true) => {
    let dataReturn = '-'
    if (data) {
        if (locale) {
            dataReturn = `US $${parseFloat(data).toLocaleString('en-US')}`
        } else {
            dataReturn = `US $${parseFloat(data)}`
        }
    }
    return dataReturn
}

export const currencyFormatWithDecimal = (data) => {
    let dataReturn = '-'
    if (data) {
        dataReturn = `US ${new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 2,
            style: 'currency',
            currency: 'USD',
        }).format(data)}`
    }
    return dataReturn
}

export const dateFormatFunction = (data) => {
    let dataReturn = '-'
    if (data) {
        if (moment(data).isValid()) {
            dataReturn = `${moment(data).format(dateFormat)}`
        }
    }
    return dataReturn
}

export const dateTimeFormatFunction = (data, nullDefault, customFormat) => {
    let dataReturn = '-'
    if (nullDefault) {
        dataReturn = null
    }
    if (data) {
        if (moment(data).isValid()) {
            dataReturn = `${moment(data).format(
                customFormat ? customFormat : dateTimeFormat
            )}`
        }
    }
    return dataReturn
}

export const dateTimeSecondFormatFunction = (data) => {
    let dataReturn = '-'
    if (data) {
        if (moment(data).isValid()) {
            dataReturn = `${moment(data).format(dateTimeSecondFormat)}`
        }
    }
    return dataReturn
}

export const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
}

export const DirectAPICAll = (
    method,
    url,
    data,
    headertype,
    baseurl,
    token
) => {
    return new Promise(async (resolve, reject) => {
        try {
            let type = ''
            if (headertype && headertype === 'formdata') {
                type = 'multipart/form-data'
            } else {
                type = 'application/json'
            }
            const config = {
                headers: {
                    'content-type': type,
                },
            }
            if (token) {
                config.headers.authorization = `Bearer ${token}`
            }

            if (method === 'get') {
                let res = await axios.get(`${url}`)
                resolve(res)
            } else if (method === 'post') {
                if (data) {
                    let res = await axios.post(`${url}`, data, config)
                    resolve(res)
                } else {
                    let res = await axios.post(`${url}`, config)
                    resolve(res)
                }
            }
        } catch (err) {
            console.log('responsode error from api', err)
            resolve(err)
        }
    })
}

export const useCustomMediaQuery = (queryString) => {
    const [isMatch, setIsMatch] = useState(false)
    const mqChange = (mq) => {
        setIsMatch(mq.matches)
    }

    useEffect(() => {
        const mq = window.matchMedia(queryString)
        mq.addListener(mqChange)
        mqChange(mq)

        return () => {
            mq.removeListener(mqChange)
        }
    }, [queryString])

    return isMatch
}

export const IndianCurrencyInWords = (inputNumber) => {
    let str = new String(inputNumber)
    let splt = str.split('')
    let rev = splt.reverse()
    let once = [
        'Zero',
        ' One',
        ' Two',
        ' Three',
        ' Four',
        ' Five',
        ' Six',
        ' Seven',
        ' Eight',
        ' Nine',
    ]
    let twos = [
        'Ten',
        ' Eleven',
        ' Twelve',
        ' Thirteen',
        ' Fourteen',
        ' Fifteen',
        ' Sixteen',
        ' Seventeen',
        ' Eighteen',
        ' Nineteen',
    ]
    let tens = [
        '',
        'Ten',
        ' Twenty',
        ' Thirty',
        ' Forty',
        ' Fifty',
        ' Sixty',
        ' Seventy',
        ' Eighty',
        ' Ninety',
    ]

    let numLength = rev.length
    let word = new Array()
    let j = 0

    for (var i = 0; i < numLength; i++) {
        switch (i) {
            case 0:
                if (rev[i] == 0 || rev[i + 1] == 1) {
                    word[j] = ''
                } else {
                    word[j] = '' + once[rev[i]]
                }
                word[j] = word[j]
                break

            case 1:
                aboveTens()
                break

            case 2:
                if (rev[i] == 0) {
                    word[j] = ''
                } else if (rev[i - 1] == 0 || rev[i - 2] == 0) {
                    word[j] = once[rev[i]] + ' Hundred '
                } else {
                    word[j] = once[rev[i]] + ' Hundred and'
                }
                break

            case 3:
                if (rev[i] == 0 || rev[i + 1] == 1) {
                    word[j] = ''
                } else {
                    word[j] = once[rev[i]]
                }
                if (rev[i + 1] != 0 || rev[i] > 0) {
                    word[j] = word[j] + ' Thousand '
                }
                break

            case 4:
                aboveTens()
                break

            case 5:
                if (rev[i] == 0 || rev[i + 1] == 1) {
                    word[j] = ''
                } else {
                    word[j] = once[rev[i]]
                }
                if (rev[i + 1] !== '0' || rev[i] > '0') {
                    word[j] = word[j] + ' Lakh '
                }

                break

            case 6:
                aboveTens()
                break

            case 7:
                if (rev[i] == 0 || rev[i + 1] == 1) {
                    word[j] = ''
                } else {
                    word[j] = once[rev[i]]
                }
                if (rev[i + 1] !== '0' || rev[i] > '0') {
                    word[j] = word[j] + ' Crore '
                }
                break

            case 8:
                aboveTens()
                break

            default:
                break
        }
        j++
    }

    function aboveTens() {
        if (rev[i] == 0) {
            word[j] = ''
        } else if (rev[i] == 1) {
            word[j] = twos[rev[i - 1]]
        } else {
            word[j] = tens[rev[i]]
        }
    }

    word.reverse()
    let finalOutput = ''
    for (i = 0; i < numLength; i++) {
        finalOutput = finalOutput + word[i]
    }
    return finalOutput
}

export const removeHTMLTags = (data) => {
    let tempEle = document.createElement('span')
    tempEle.innerHTML = data
    let convertedText = tempEle.textContent || tempEle.innerText || ''
    return convertedText
}
