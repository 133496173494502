import {
    GET_ALL_APPOINTMENTS,
    GET_ALL_APPOINTMENTS_AUCTION,
    GET_SINGLE_APPOINTMENT,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './appointmentTypes'

export default (state, action) => {
    switch (action.type) {
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case GET_ALL_APPOINTMENTS:
            return {
                ...state,
                all_appointments: action.payload,
            }
        case GET_SINGLE_APPOINTMENT:
            return {
                ...state,
                single_appointment: action.payload,
            }
        case GET_ALL_APPOINTMENTS_AUCTION:
            return {
                ...state,
                all_appointments_auction: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
