import React, { useState, useContext, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import { Button } from '@mui/material'
import MuiAccordion from '@mui/material/Accordion'
import MuiAccordionSummary from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './ProductView.css'
import CustomCommonContext from '../../../../custom/context/common/commonContext'
import ProductCommonContext from '../../../../product/context/common/commonContext'
import { dateFormatFront, currencyFormat } from '../../../common/components'
// import TertiaryButton from '../../atoms/TertiaryButton'
import Timer from '../../../../product/common/timer'
import Bidding from '../../molecules/Bidding/BiddingItem'
import AuthContext from '../../../../product/context/auth/authContext'

const PREFIX = 'ProductView'

const classes = {
    root: `${PREFIX}-root`,
    expanded: `${PREFIX}-expanded`,
    root2: `${PREFIX}-root2`,
    content: `${PREFIX}-content`,
    expanded2: `${PREFIX}-expanded2`,
    root3: `${PREFIX}-root3`,
}

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.root}`]: {
        border: '1px solid rgba(0, 0, 0, .125)',
        boxShadow: 'none',
        '&:not(:last-child)': {
            borderBottom: 0,
        },
        '&:before': {
            display: 'none',
        },
        '&$expanded': {
            margin: 'auto',
        },
    },

    [`& .${classes.expanded}`]: {},

    [`& .${classes.root2}`]: {
        backgroundColor: '#fff',
        marginBottom: -1,
        minHeight: 40,
        '&$expanded': {
            minHeight: 40,
        },
    },

    [`& .${classes.content}`]: {
        '&$expanded': {
            margin: '0',
        },
    },

    [`& .${classes.expanded2}`]: {},

    [`& .${classes.root3}`]: {
        padding: '10px',
    },
}))

const Accordion = MuiAccordion

const AccordionSummary = MuiAccordionSummary

const AccordionDetails = MuiAccordionDetails
function accorianTerms(t) {
    return [
        {
            title: t('terms_and_conditions'),
            details: t('terms_and_condition_details'),
        },
    ]
}
const ProductView = (props) => {
    const t = (text) => {
        text = text.charAt(0).toUpperCase() + text.slice(1)
        return text.replace(/_/g, ' ')
    }
    const accordianData = accorianTerms(t)
    const [expanded, setExpanded] = useState()
    const [images, setImages] = useState([])
    const [product, setProduct] = useState()
    const [isLoading, setIsLoading] = useState(false)

    const authContext = useContext(AuthContext)
    const customCommonContext = useContext(CustomCommonContext)
    const productCommonContext = useContext(ProductCommonContext)

    const { isAuthenticated } = authContext
    const { allCategory, allCondition, allLocations } = customCommonContext
    const { setBidHistoryValue } = productCommonContext

    useEffect(() => {
        setProduct(props.data)
    }, [props.data])

    useEffect(() => {
        if (product) {
            if (product.allattachmentlist) {
                if (product.allattachmentlist.length) {
                    product.allattachmentlist.forEach(function (data) {
                        data['original'] = global.images_url + data['file_name']
                        data['thumbnail'] =
                            global.images_url + data['file_name']
                    })
                    setImages(product.allattachmentlist)
                } else {
                    setImages([])
                }
            } else {
                setImages([])
            }
        } else {
            setImages([])
        }
    }, [product])

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false)
    }
    return (
        <Root className="productView" id="productView">
            {isLoading ? (
                'Loading ..'
            ) : product ? (
                <div className="d-flex flex-wrap">
                    {isAuthenticated && (
                        <>
                            {product.bidtopstatus === 'outbid' && (
                                <div className="pvBidStatus">
                                    <h4 className="outbid">
                                        {t('oops_you_have_been_outbid_caps')}.
                                    </h4>
                                </div>
                            )}
                            {product.bidtopstatus === 'winner' && (
                                <div className="pvBidStatus">
                                    <h4 className="winning">
                                        {t(
                                            'yayy_you_are_winning_this_item_caps'
                                        )}
                                        .
                                    </h4>
                                </div>
                            )}
                            {product.bidtopstatus === 'won' && (
                                <div className="pvBidStatus">
                                    <h4 className="won">
                                        {t(
                                            'congratulations_you_have_won_this_item'
                                        )}
                                    </h4>
                                </div>
                            )}
                            {product.bidtopstatus === 'lost' && (
                                <div className="pvBidStatus">
                                    <h4 className="lost">
                                        {t(
                                            'unfortunately_you_have_lost_this_item'
                                        )}
                                    </h4>
                                </div>
                            )}
                        </>
                    )}
                    <div className="pvLt">
                        <ImageGallery
                            items={images}
                            thumbnailPosition="bottom"
                            showNav={false}
                            showBullets={false}
                            showFullscreenButton={true}
                            showPlayButton={false}
                        />
                    </div>
                    <div className="pvRt">
                        <div>
                            <div className="pvProductInfo mt-4">
                                <h2>
                                    #{product.id} - {product.title}
                                </h2>
                            </div>
                            {product.market_status === 'open' ? (
                                <div className="pvTimerBox">
                                    <span className="material-icons">
                                        timer
                                    </span>
                                    <h4>
                                        <Timer
                                            date_added={product.date_added}
                                            date_closed={product.date_closed}
                                            withText={1}
                                            endText="Time Left: "
                                            startText="Starts in: "
                                        ></Timer>
                                    </h4>
                                </div>
                            ) : (
                                <div className="pvTimerBox">
                                    <span className="material-icons">
                                        timer
                                    </span>
                                    <label>{t('closed_on')}</label>
                                    <h4>
                                        {dateFormatFront(product.date_closed)}
                                    </h4>
                                </div>
                            )}

                            <div className="pvActBtn d-flex justify-content-around align-items-center">
                                {product.market_status === 'open' ? (
                                    <>
                                        {product.buynow ? (
                                            <div className="pabChild">
                                                <label>
                                                    {t('buy_now_price')}
                                                </label>
                                                <h4>{product.bprice} AED</h4>
                                            </div>
                                        ) : (
                                            ''
                                        )}

                                        {product.auction ? (
                                            <>
                                                {console.log(
                                                    product,
                                                    'productproduct'
                                                )}
                                                <div className="pabChild pvCurrentBid">
                                                    <label>
                                                        {product.cbidtext}
                                                    </label>
                                                    <h4>
                                                        {currencyFormat(
                                                            product.wprice
                                                        )}
                                                    </h4>
                                                </div>
                                                <div className="pabChild">
                                                    <label>
                                                        {t('minimum_bid')}
                                                    </label>
                                                    <h4>
                                                        {currencyFormat(
                                                            product.next_bid
                                                        )}
                                                    </h4>
                                                </div>{' '}
                                            </>
                                        ) : (
                                            ''
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div className="pabChild pvCurrentBid">
                                            <label>{product.cbidtext}</label>
                                            <h4>
                                                {currencyFormat(
                                                    product.buynowamount
                                                )}
                                            </h4>
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className="pvBidBox">
                                {product.market_status === 'open' &&
                                !product.future_active ? (
                                    <>
                                        {product.auction && (
                                            <>
                                                <Bidding
                                                    data={product}
                                                    type="hard"
                                                />
                                            </>
                                        )}
                                    </>
                                ) : null}
                            </div>
                            {product.auction ? (
                                <Button
                                    className="pvBidHistBtn"
                                    onClick={() =>
                                        setBidHistoryValue(product.id)
                                    }
                                >
                                    <span className="material-icons">
                                        history
                                    </span>{' '}
                                    {t('view_bid_history')}
                                </Button>
                            ) : (
                                ''
                            )}

                            <div className="pvProductDesc mt-3 mb-3">
                                <div className="pvDescChild">
                                    <label>{t('lot')} #:</label>
                                    <h6>#{product.id}</h6>
                                </div>

                                <div className="pvDescChild">
                                    <label>{t('auction_ends')}:</label>
                                    <h6>
                                        {dateFormatFront(product.date_closed)}
                                    </h6>
                                </div>

                                {/* <div className="pvDescChild">
                                <label>Product Owner:</label>
                                <h6>Spencer C</h6>
                            </div> */}

                                <div className="pvDescChild">
                                    <label>{t('location')}:</label>
                                    <h6>
                                        {allLocations
                                            .filter(
                                                (inner) =>
                                                    inner.id ===
                                                    product.location_id
                                            )
                                            .map(
                                                (filtered) =>
                                                    `${
                                                        filtered.address || ''
                                                    }, ${filtered.city || ''} ${
                                                        filtered.state || ''
                                                    }  ${
                                                        filtered.zipCode || ''
                                                    }`
                                            )}
                                    </h6>
                                </div>

                                <div className="pvDescChild">
                                    <label>{t('available_quantity')}:</label>
                                    <h6>{product.qty}</h6>
                                </div>

                                <div className="pvDescChild">
                                    <label>{t('category')}:</label>
                                    <h6>
                                        {allCategory
                                            .filter(
                                                (inner) =>
                                                    inner.id ===
                                                    product.categoryTypeId
                                            )
                                            .map(
                                                (filtered) =>
                                                    filtered.description
                                            )}
                                    </h6>
                                </div>

                                <div className="pvDescChild">
                                    <label>{t('sub_category')}:</label>
                                    <h6>
                                        {allCategory
                                            .filter(
                                                (inner) =>
                                                    inner.id ===
                                                    product.subCategoryTypeId
                                            )
                                            .map(
                                                (filtered) =>
                                                    filtered.description
                                            )}
                                    </h6>
                                </div>
                                <div className="pvDescChild">
                                    <label>{t('condition')}:</label>
                                    <h6>
                                        {allCondition
                                            .filter(
                                                (inner) =>
                                                    inner.id ===
                                                    product.conditionTypeId
                                            )
                                            .map(
                                                (filtered) =>
                                                    filtered.description
                                            )}
                                    </h6>
                                </div>

                                <div className="pvDescChild">
                                    <label>{t('description')}:</label>
                                    <h6
                                        dangerouslySetInnerHTML={{
                                            __html: product.desc_proc,
                                        }}
                                    ></h6>
                                </div>
                            </div>
                            <div className="pvAccordian mt-2 mb-2 w-100">
                                {accordianData.map((data, index) => (
                                    <Accordion
                                        square
                                        expanded={expanded === `panel${index}`}
                                        key={index}
                                        onChange={handleChange(`panel${index}`)}
                                        classes={{
                                            root: classes.root,
                                            expanded: classes.expanded,
                                        }}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1d-content"
                                            id={`panel${index}d-header`}
                                            classes={{
                                                root: classes.root2,
                                                content: classes.content,
                                                expanded: classes.expanded2,
                                            }}
                                        >
                                            <h6 className="accTitle">
                                                {data.title}
                                            </h6>
                                        </AccordionSummary>
                                        <AccordionDetails
                                            classes={{
                                                root: classes.root3,
                                            }}
                                        >
                                            {data.details}
                                        </AccordionDetails>
                                    </Accordion>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </Root>
    )
}
export default ProductView
