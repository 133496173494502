import React, { useReducer } from 'react'
import ReturnContext from './returnContext'
import ReturnReducer from './returnReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import {
    GET_ALL_INVOICES,
    GET_SINGLE_INVOICE,
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
} from './returnTypes'

const ReturnState = (props) => {
    const initialState = {
        all_invoices: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
        single_invoice: {
            invoicerecords: [],
            transactionrecords: [],
            appointmentrecord: {},
            locationrecord: {},
            cartvalues: {},
        },
        responseStatus: null,
    }

    const [state, dispatch] = useReducer(ReturnReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const getAllInvoices = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'invoices', formData, '', 'return'),
            ])
            const from = 'Invoices'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_INVOICES,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const getSingleInvoice = async (formData) => {
        try {
            const [res] = await Promise.all([
                apiCall('post', 'returninvoice', formData, '', 'user/invoice'),
            ])
            const from = 'InvoiceManagement'
            if (res.data.status === 'success') {
                dispatch({
                    type: GET_SINGLE_INVOICE,
                    payload: {
                        invoicerecords: res.data.data.responseData.invoiceItems
                            .length
                            ? res.data.data.responseData.invoiceItems
                            : [],
                        transactionrecords: res.data.data.responseData
                            .allTransactions.length
                            ? res.data.data.responseData.allTransactions
                            : [],
                        appointmentrecord: res.data.data.responseData
                            .appointmentDetail
                            ? res.data.data.responseData.appointmentDetail
                            : [],
                        locationrecord: res.data.data.responseData
                            .locationDetail
                            ? res.data.data.responseData.locationDetail
                            : [],
                        cartvalues: res.data.data.responseData.cartValues,
                        invoiceuserrecord: res.data.data.responseData
                            .invoiceUser
                            ? res.data.data.responseData.invoiceUser
                            : [],
                        invoiceaddressrecord: res.data.data.responseData
                            .invoiceAddress
                            ? res.data.data.responseData.invoiceAddress
                            : [],
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }
    const changeProductStatus = async (formData) => {
        try {
            const from = 'productchange'
            const [res] = await Promise.all([
                apiCall('post', 'changeStatus', formData, '', 'return'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const refundInvoiceStatus = async (formData) => {
        try {
            const from = 'refundInvoiceStatus'
            const [res] = await Promise.all([
                apiCall('post', 'refundInvoice', formData, '', 'refund'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <ReturnContext.Provider
            value={{
                // buyer_allproducts: state.buyer_allproducts,
                all_invoices: state.all_invoices,
                responseStatus: state.responseStatus,
                single_invoice: state.single_invoice,
                clearResponse,
                getAllInvoices,
                changeProductStatus,
                refundInvoiceStatus,
                getSingleInvoice,
            }}
        >
            {props.children}
        </ReturnContext.Provider>
    )
}

export default ReturnState
