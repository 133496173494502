import React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'

const PREFIX = 'PrimaryButton'

const classes = {
    root: `${PREFIX}-root`,
    root2: `${PREFIX}-root2`,
}

const Root = styled('div')(({ theme }) => ({
    [`& .${classes.root2}`]: {
        '& > *': {
            width: '100%',
            height: '60px',
        },
    },
}))

const ColorButton = Button

const PrimaryButton = (props) => {
    return (
        <Root className={`${classes.root} ${props.btnSize} primButton`}>
            <ColorButton
                variant="contained"
                color="primary"
                id={props.id}
                onClick={props.onClick}
                type={props.type}
                disabled={props.disabled}
                classes={{
                    root: classes.root,
                }}
            >
                {props.iconLeft}
                {props.label}
                {props.children}
            </ColorButton>
        </Root>
    )
}

export default PrimaryButton
