import React, { useEffect, useState } from 'react'
import TextField from '@mui/material/TextField'
import Autocomplete from '@mui/material/Autocomplete'

function CustomAutocomplete(props) {
    const [selectedOption, setSelectedOption] = useState([])

    useEffect(() => {
        setSelectedOption([])
    }, [props.onClear])

    const getMultipleValues = (values) => {
        setSelectedOption(values)
        return values.map((item) => item.value)
    }

    return (
        <>
            {props.multiple ? (
                <Autocomplete
                    id={props.id}
                    multiple={props.multiple}
                    options={props.options}
                    value={selectedOption}
                    onChange={(event, value) => {
                        if (value) {
                            props.onChange(getMultipleValues(value))
                        }
                    }}
                    getOptionLabel={(option) => option.show}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={props.label}
                            variant={props.variant ? props.variant : 'outlined'}
                            error={props.error}
                            value={props.value}
                            size={props.size}
                            name={props.name}
                            onChange={props.onChange}
                            helperText={props.helperText}
                        />
                    )}
                    autoFocus={props.autoFocus}
                />
            ) : (
                <Autocomplete
                    id={props.id}
                    options={props.options}
                    onChange={(event, value) => {
                        if (value) {
                            props.onChange(value.value)
                        }
                    }}
                    getOptionLabel={(option) => option.show}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={props.label}
                            variant={props.variant ? props.variant : 'outlined'}
                            error={props.error}
                            value={props.value}
                            size={props.size}
                            name={props.name}
                            onChange={props.onChange}
                            helperText={props.helperText}
                        />
                    )}
                    autoFocus={props.autoFocus}
                />
            )}
        </>
    )
}
export default CustomAutocomplete
