import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../product/components/templates/Layout'
import '../Dashboard/Dashboard.css'
import CustomTable from '../../product/components/molecules/CustomTable'
import CustomSearch from '../../product/components/molecules/CustomSearch'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'

import { useFormik } from 'formik'
import BidDepositContext from '../../product/context/bid_deposit/bidDepositContext'

import { mapData } from '../../product/common/components'

import CustomDialog from '../../product/components/organisms/Dialog'
import BidDeposit from './BidDeposit'
import { Button } from '@mui/material'

const Listing = (props) => {
    const bidDepositContext = useContext(BidDepositContext)
    const [isLoading, setIsLoading] = useState(true)
    const [manage, setManage] = useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id })
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    const {
        all_products,
        getAllProducts,
        changeProductStatus,
        responseStatus: responseStatusProduct,
        clearResponse: clearResponseProduct,
    } = bidDepositContext

    const [changeStatus, setChangeStatus] = React.useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    let action = props.match.params.action
    const [tableBody, setTableBody] = useState([])
    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                action: {
                    value: action
                        ? action.toString() !== 'all'
                            ? action.toString()
                            : ''
                        : null,
                    type: 'in',
                    field: 'p.market_status',
                },
                searchterm: {
                    value: '',
                    type: 'like',
                    field: 'p.title',
                },
                fromdate: {
                    value: '',
                    type: 'like',
                    field: 'p.date_closed',
                },
                todate: {
                    value: '',
                    type: 'like',
                    field: 'p.date_closed',
                },
            },
        },
        onSubmit: (values) => {
            getAllProducts(values)
            setIsLoading(true)
        },
    })

    const formikSelection = useFormik({
        initialValues: {
            action: '',
            project_id: '',
            auction_id: '',
        },
        onSubmit: (values) => {
            if (
                values.action === 'open' ||
                values.action === 'removed' ||
                values.action === 'unsold'
            ) {
                changeProductStatus(values)
            } else {
            }
        },
    })

    useEffect(() => {
        if (all_products) {
            setTableBody(
                all_products.records.length ? all_products.records : []
            )
            setIsLoading(false)
        }
    }, [all_products])

    useEffect(() => {
        setTableBody([])
        setIsLoading(true)
        formik.values.filters.action.value = action
        formik.values.page = 1
        formik.values.filters.fromdate.value = ''
        formik.values.filters.todate.value = ''
        formik.values.filters.searchterm.value = ''
        getAllProducts(formik.values)
        /*  return () => {
            setTableBody([])
        }*/
    }, [action])

    useEffect(() => {
        console.log('formikSelection.values', formikSelection.values)
    }, [formikSelection.values])

    const onClickEdit = (id, secondaryID, type) => {
        toggleFullScreenPopup(true, 'edit', id)
    }

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.project_id = data
        if (action === 'open' || action === 'removed' || action === 'unsold') {
            setChangeStatus(true)
        }
    }

    const tableValues = []
    tableValues.push(
        {
            field: 'avatar',
            type: 'imagewithurl',
            folder: 'product',
            firstChild: true,
            sort: false,
            disablePadding: false,
            label: 'Picture',
        },
        {
            field: 'title',
            type: '',
            firstChild: false,
            sort: true,
            sortField: 'p.title',
            disablePadding: false,
            label: 'Title',
        },
        {
            field: 'total_deposit_count',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Deposit Users',
        },
        {
            field: 'total_deposit_amount',
            type: 'price',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Deposit Amount',
        },
        {
            field: 'total_refund_count',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Refund Users',
        },
        {
            field: 'total_refund_amount',
            type: 'price',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Refund Amount',
        },
        {
            field: 'action',
            type: 'button',
            buttonType: 'history',
            clickType: 'edit',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            tooltipTitle: 'View deposit history',
        }
    )
    const tableActions = []

    if (action === 'draft') {
        tableActions.push(
            {
                label: 'Move to Active',
                icon: <span className="material-icons">low_priority</span>,
                onclick: onSelectMultiProducts,
                type: 'open',
            },
            {
                label: 'Assign to Auction',
                icon: <span className="material-icons">add_task</span>,
                onclick: onSelectMultiProducts,
                type: 'auction_assign',
            },
            {
                label: 'Delete',
                icon: <span className="material-icons">delete</span>,
                onclick: onSelectMultiProducts,
                type: 'removed',
            }
        )
    } else if (action === 'open') {
        tableActions.push(
            {
                label: 'Move to Unsold',
                icon: <span className="material-icons">low_priority</span>,
                onclick: onSelectMultiProducts,
                type: 'unsold',
            },
            {
                label: 'Delete',
                icon: <span className="material-icons">delete</span>,
                onclick: onSelectMultiProducts,
                type: 'removed',
            }
        )
    } else if (action === 'sold') {
        tableActions.push({
            label: 'Move to Unsold',
            icon: <span className="material-icons">low_priority</span>,
            onclick: onSelectMultiProducts,
            type: 'unsold',
        })
    } else if (action === 'unsold') {
        tableActions.push({
            label: 'Delete',
            icon: <span className="material-icons">delete</span>,
            onclick: onSelectMultiProducts,
            type: 'removed',
        })
    }

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: 'Listing Title',
                placeholder: 'Enter listing title',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'searchterm',
                filter: true,
            },
            {
                label: 'Closed From Date',
                placeholder: 'Date Ending from',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'fromdate',
                filter: true,
            },
            {
                label: 'Closed To Date',
                placeholder: 'Date Ending To',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'date',
                name: 'todate',
                filter: true,
            },
        ],
    }

    useEffect(() => {
        if (responseStatusProduct) {
            if (responseStatusProduct.status === 'success') {
                if (responseStatusProduct.from === 'productchange') {
                    setChangeStatus(false)
                    getAllProducts(formik.values)
                } else if (responseStatusProduct.from === 'projectAction') {
                    toggleFullScreenPopup(false)
                    getAllProducts(formik.values)
                }
            }
        }
    }, [responseStatusProduct])

    return (
        <Layout>
            <div className="dashboard listing">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    allData={all_products}
                    changeTableFunction={getAllProducts}
                />
            </div>
            <BidDeposit data={manage} function={toggleFullScreenPopup} />

            <CustomDialog
                title={'Change product status'}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>Are you sure you want to change the status</h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form
                        onSubmit={formikSelection.handleSubmit}
                        autoComplete="nofill"
                    >
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </Layout>
    )
}

export default Listing
