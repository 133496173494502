import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Layout from '../../product/components/templates/Layout'
import CustomTable from '../../product/components/molecules/CustomTable'
import CustomSearch from '../../product/components/molecules/CustomSearch'

import { useFormik } from 'formik'
import EmployeeContext from '../../product/context/employee/empContext'
import ReportContext from '../../product/context/report/reportContext'
import { handleRedirectInternal } from '../../product/common/components'
import PrimaryButton from '../../product/components/atoms/PrimaryButton'
import CustomDialog from '../../product/components/organisms/Dialog'
import '../Dashboard/Dashboard.css'
import { Button } from '@mui/material'

import EmployeeManage from './view'

const EmployeeView = (props) => {
    const [isLoading, setIsLoading] = useState(false)
    const employeeContext = useContext(EmployeeContext)
    const reportContext = useContext(ReportContext)
    const { getAllExportDownload } = reportContext
    const {
        all_employees,
        changeUserStatus,
        getAllEmployees,
        responseStatus: responseStatusUser,
        clearResponse: clearResponseUser,
    } = employeeContext
    const [changeStatus, setChangeStatus] = React.useState(false)
    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
    })
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }
    useEffect(() => {
        return () => setIsLoading(false)
    }, [])
    const toggleFullScreenPopup = (popup, status, id) => {
        if (popup) {
            setManage({ popup, status, id })
        } else {
            setManage({ popup, status: 'new', id: 0 })
        }
    }

    let action =
        props.match.params.action !== 'all' ? props.match.params.action : null

    const [tableBody, setTableBody] = useState([])

    const formik = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            filters: {
                action: {
                    value: action ? action.toString() : null,
                    type: 'in',
                    field: 'u.status',
                },
                emp_email: {
                    value: '',
                    type: 'like',
                    field: 'e.emp_email',
                },
                emp_firstname: {
                    value: '',
                    type: 'like',
                    field: 'e.emp_firstname',
                },
                emp_lastname: {
                    value: '',
                    type: 'like',
                    field: 'e.emp_lastname',
                },
            },
        },
        onSubmit: (values) => {
            setIsLoading(true)
            getAllEmployees(values)
        },
    })

    useEffect(() => {
        if (all_employees) {
            setTableBody(
                all_employees.records.length ? all_employees.records : []
            )
        }
        setIsLoading(false)
    }, [all_employees])

    useEffect(() => {
        setTableBody([])
        setIsLoading(true)
        formik.values.filters.action.value = action
        formik.values.page = 1
        formik.values.filters.emp_email.value = ''
        formik.values.filters.emp_firstname.value = ''
        formik.values.filters.emp_lastname.value = ''

        getAllEmployees(formik.values)
        /*  return () => {
            setTableBody([])
        }*/
    }, [action])

    const onClickEdit = (id, type) => {
        toggleFullScreenPopup(true, 'edit', id)
    }
    const formikSelection = useFormik({
        initialValues: {
            action: '',
            user_id: '',
        },
        onSubmit: (values) => {
            if (values.action === 'active' || values.action === 'deactivate') {
                setIsLoading(true)
                changeUserStatus(values)
            }
        },
    })

    const onSelectMultiProducts = (data, action) => {
        formikSelection.values.action = action
        formikSelection.values.user_id = data
        if (action === 'active' || action === 'deactivate') {
            setChangeStatus(true)
        }
    }

    const tableValues = [
        {
            field: 'emp_firstname',
            type: '',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'First Name',
        },
        {
            field: 'emp_lastname',
            type: '',
            firstChild: false,
            sort: true,
            disablePadding: false,
            label: 'Last Name',
        },
        {
            field: 'emp_email',
            type: '',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Email',
        },
        {
            field: 'emp_status',
            type: 'capitalize',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Status',
        },
        {
            field: 'emp_type',
            type: 'capitalize',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Type',
        },
        {
            field: 'action',
            type: 'button',
            clickType: 'edit',
            onclick: onClickEdit,
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Action',
            tooltipTitle: 'Edit employee',
        },
    ]

    const searchInfo = {
        formik: formik,
        data: [
            {
                label: 'Email',
                placeholder: 'Enter Email',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'emp_email',
                filter: true,
            },
            {
                label: 'First Name',
                placeholder: 'Enter First Name',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'emp_firstname',
                filter: true,
            },
            {
                label: 'Last Name',
                placeholder: 'Enter Last Name',
                class: 'col-md-4 col-sm-6 col-12',
                type: 'text',
                name: 'emp_lastname',
                filter: true,
            },
        ],
    }

    const tableActions = []

    if (action === 'active') {
        tableActions.push({
            label: 'Move to De-activate',
            icon: <span className="material-icons">block</span>,
            onclick: onSelectMultiProducts,
            type: 'deactivate',
        })
    }
    if (action === 'deactivate') {
        tableActions.push({
            label: 'Move to Activate',
            icon: <span className="material-icons">check_circle</span>,
            onclick: onSelectMultiProducts,
            type: 'active',
        })
    }

    if (action === 'all' || !action) {
        tableActions.push(
            {
                label: 'Move to De-activate',
                icon: <span className="material-icons">block</span>,
                onclick: onSelectMultiProducts,
                type: 'deactivate',
            },
            {
                label: 'Move to Activate',
                icon: <span className="material-icons">check_circle</span>,
                onclick: onSelectMultiProducts,
                type: 'active',
            }
        )
    }

    useEffect(() => {
        if (responseStatusUser) {
            if (responseStatusUser.status === 'success') {
                if (responseStatusUser.from === 'changeStatus') {
                    setChangeStatus(false)
                    getAllEmployees(formik.values)
                    console.log(formik.values, 'changeStatus')
                }
                if (responseStatusUser.from === 'employeeAction') {
                    toggleFullScreenPopup(false)
                    getAllEmployees(formik.values)
                    console.log(formik.values, 'employeeAction')
                }
            }
        }
    }, [responseStatusUser])

    return (
        <Layout>
            <div className="dashboard employeeManagement">
                <CustomSearch searchInfo={searchInfo} formik={formik} />
                <div className="miscActions d-flex justify-content-between align-items-center mt-4 flex-wrap">
                    <div>
                        <Button
                            variant="outlined"
                            className="btnOutlined"
                            onClick={() =>
                                toggleFullScreenPopup(true, 'new', 0)
                            }
                        >
                            <span className="material-icons">
                                person_add_alt_1
                            </span>
                            Add New Employee
                        </Button>
                    </div>
                    <Button
                        onClick={() =>
                            getAllExportDownload({
                                action: 'export',
                                order: 'asc',
                                orderby: '',
                                search: 'all',
                                report: `employee_list`,
                            })
                        }
                    >
                        <span className="material-icons">get_app</span>Export
                        All Data
                    </Button>
                </div>
                <CustomTable
                    formik={formik.values}
                    tableBody={tableBody}
                    tableValues={tableValues}
                    tableActions={tableActions}
                    allData={all_employees}
                    isLoading={isLoading}
                    loadingTemplate={'table'}
                    changeTableFunction={getAllEmployees}
                />
            </div>

            <EmployeeManage data={manage} function={toggleFullScreenPopup} />
            <CustomDialog
                title={'Change user status'}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>Are you sure you want to change the status</h5>
                <div className="actionWrapper">
                    <Button onClick={() => changeChangeStatus()}>Cancel</Button>
                    <form
                        onSubmit={formikSelection.handleSubmit}
                        autoComplete="nofill"
                    >
                        <PrimaryButton type="submit" label="Confirm" />
                    </form>
                </div>
            </CustomDialog>
        </Layout>
    )
}

export default EmployeeView
