import React, { useReducer } from 'react'
import BidContext from './bidContext'
import BidReducer from './bidReducer'
import { apiCall } from '../../common/api'
import { response } from '../common'

import { GET_ALL_INCREMENTS, RESPONSE_STATUS, CLEAR_RESPONSE } from './bidTypes'

const BidState = (props) => {
    const initialState = {
        responseStatus: null,
        all_increments: {
            records: [],
            totalRecords: 0,
            setDisp: '',
        },
    }

    const [state, dispatch] = useReducer(BidReducer, initialState)
    let resp = new response(dispatch, RESPONSE_STATUS)

    const bidCancel = async (formData) => {
        const from = 'bidCancel'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'bidCancel', formData, '', 'bid'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const bidAward = async (formData) => {
        const from = 'bidAward'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'manualAward', formData, '', 'bid'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const ttwBidAction = async (formData) => {
        const from = 'ttwBidAction'
        try {
            const [res] = await Promise.all([
                apiCall('post', 'ttwBidAction', formData, '', 'bid'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const bidIncrementAction = async (formData, noAlert) => {
        const from = 'bidIncrementAction'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'bid'),
            ])
            resp.commonResponse(res.data, from, noAlert)
        } catch (err) {
            resp.commonErrorResponse(from, noAlert)
        }
    }

    const getAllBidIncrements = async (formData) => {
        const from = 'bidIncrements'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'bid'),
            ])

            if (res.data.status === 'success') {
                dispatch({
                    type: GET_ALL_INCREMENTS,
                    payload: {
                        records: res.data.data.responseData.records.length
                            ? res.data.data.responseData.records
                            : [],
                        totalRecords: res.data.data.responseData.totalRecords,
                        setDisp: res.data.data.responseData.setDisp,
                    },
                })
            } else if (res.data.status === 'error') {
                resp.commonResponse(res.data, from)
            } else {
                resp.commonErrorResponse(from)
            }
        } catch (err) {
            dispatch({
                type: RESPONSE_STATUS,
                payload: 'Something went wrong!',
            })
        }
    }

    const changeBidIncrementStatus = async (formData) => {
        const from = 'changeBidIncrementStatus'
        try {
            const [res] = await Promise.all([
                apiCall('post', from, formData, '', 'bid'),
            ])
            resp.commonResponse(res.data, from)
        } catch (err) {
            resp.commonErrorResponse(from)
        }
    }

    const clearResponse = () =>
        dispatch({
            type: CLEAR_RESPONSE,
        })

    return (
        <BidContext.Provider
            value={{
                responseStatus: state.responseStatus,
                all_increments: state.all_increments,
                bidIncrementAction,
                getAllBidIncrements,
                changeBidIncrementStatus,
                clearResponse,
                bidCancel,
                bidAward,
                ttwBidAction,
            }}
        >
            {props.children}
        </BidContext.Provider>
    )
}

export default BidState
