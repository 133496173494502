import React, { useContext, useState, useEffect } from 'react'

import { Button } from '@mui/material'
import PrimaryButton from '../../../components/atoms/PrimaryButton'
import SecondaryButton from '../../../components/atoms/SecondaryButton'
import FullScreenPopup from '../../../components/organisms/FullScreenPopup'
import { mapData, handleRedirectInternal } from '../../../common/components'
import BidContext from '../../../context/bid/bidContext'
import CustomDialog from '../../organisms/Dialog'

import { useFormik } from 'formik'
import * as Yup from 'yup'

const TTWBidManage = (props) => {
    const bidContext = useContext(BidContext)

    const { ttwBidAction, responseStatus: responseStatusBid } = bidContext

    const [changeStatus, setChangeStatus] = useState(false)
    const changeChangeStatus = () => {
        setChangeStatus(!changeStatus)
    }

    const [isLoading, setIsLoading] = useState(false)
    const [reload, setReload] = useState(false)
    const data = props.data
    const toggleFullScreenPopup = props.function

    const validationCard = Yup.object({
        id: Yup.number(),
        proposed_amount: Yup.number()
            // .min(
            //     product ? product.next_bid : 0,
            //     `${'Min bid'} ${product ? currencyFormat(product.next_bid) : currencyFormat(0)}`,
            // )
            .integer("Can't be a decimal")
            .required('Enter bid amount'),
        project_id: Yup.number().required('Required!'),
    })

    const docFormik = useFormik({
        initialValues: {
            id: 0,
            proposed_amount: 0,
            project_id: 0,
            action: '',
        },
        validationSchema: validationCard,
        onSubmit: (values) => {
            if (values.id && values.action) {
                ttwBidAction(values)
            }
        },
    })

    useEffect(() => {
        console.log('data Change', data)
        if (data.popup) {
            docFormik.values.action = data.status
            if (data.status === 'delete') {
                docFormik.values.id = data.id
                docFormik.values.project_id = data.secondaryID.projectID
                docFormik.values.proposed_amount =
                    data.secondaryID.proposed_amount
                setChangeStatus(true)
            } else if (data.status === 'edit') {
                docFormik.values.id = data.id
                docFormik.values.project_id = data.secondaryID.projectID
                docFormik.values.proposed_amount =
                    data.secondaryID.proposed_amount
                setReload(!reload)
            } else {
                setChangeStatus(false)
            }
        }
    }, [data])

    const resetFrom = (projectId) => {
        docFormik.values.project_id = projectId
        docFormik.values.id = 0
        docFormik.values.proposed_amount = 0
        docFormik.handleReset()
    }

    const docDetails = {
        formik: docFormik,
        data: [
            {
                type: 'number',
                placeholder: `${'Quantity'}`,
                class: 'col-12',
                // size: 'small',
                autoFocus: false,
                name: 'proposed_amount',
            },
        ],
    }

    useEffect(() => {
        if (responseStatusBid) {
            if (responseStatusBid.from === 'ttwBidAction') {
                if (responseStatusBid.status === 'success') {
                    toggleFullScreenPopup(false)
                    setChangeStatus(false)
                }
            }
        }
    }, [responseStatusBid])

    return (
        <>
            <FullScreenPopup
                modaltitle={`EDIT BID`}
                open={data.status === 'edit' ? data.popup : false}
                handleClose={() => toggleFullScreenPopup(false)}
            >
                <div className="addUserModal">
                    <div className="fspBody">
                        <form
                            onSubmit={docFormik.handleSubmit}
                            autoComplete="nofill"
                        >
                            <div className="row">{mapData(docDetails)}</div>

                            <div className="actionButton d-flex justify-content-center align-items-center flex-wrap">
                                {isLoading && <div>Loading..</div>}
                                <SecondaryButton
                                    id="bid_cancel"
                                    label="Cancel"
                                    onClick={() => toggleFullScreenPopup(false)}
                                />
                                <PrimaryButton
                                    id="ttwBid_submit"
                                    type="submit"
                                    label="Submit"
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </FullScreenPopup>

            <CustomDialog
                title={'Change Bid Status'}
                open={changeStatus}
                function={changeChangeStatus}
            >
                <h5>Are you sure you want to delete the bid</h5>
                <div className="actionWrapper">
                    <Button
                        id="bidManage_cancel"
                        onClick={() => changeChangeStatus()}
                    >
                        Cancel
                    </Button>
                    <form
                        onSubmit={docFormik.handleSubmit}
                        autoComplete="nofill"
                    >
                        <PrimaryButton
                            id="ttwBid_delete"
                            type="submit"
                            label="Confirm"
                        />
                    </form>
                </div>
            </CustomDialog>
        </>
    )
}

export default TTWBidManage
