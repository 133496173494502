export const GET_ALL_PRODUCTS = 'GET_ALL_PRODUCTS'
export const GET_ALL_OFFERS = 'GET_ALL_OFFERS'
export const RESPONSE_STATUS = 'RESPONSE_STATUS'
export const GET_SINGLE_PROJECT = 'GET_SINGLE_PROJECT'
export const CLEAR_RESPONSE = 'CLEAR_RESPONSE'
export const GET_ALL_ACTIVE_AUCTIONS = 'GET_ALL_ACTIVE_AUCTIONS'
export const GET_ALL_BID_HISTORY = 'GET_ALL_BID_HISTORY'
export const GET_ALL_TTWBID_HISTORY = 'GET_ALL_TTWBID_HISTORY'
export const RECONCILIATION = 'GET_RECONCILIATION_DATA'

export const GET_ALL_ROYALTY_INPUTS = 'GET_ALL_ROYALTY_INPUTS'
