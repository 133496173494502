import React, { useContext, useState, useEffect } from 'react'

import FullScreenPopup from '../FullScreenPopup'
import { useFormik } from 'formik'
import { Button } from '@mui/material'
import CustomTable from '../../molecules/CustomTable'
import ProductContext from '../../../context/product/productContext'
import CommonContext from '../../../context/common/commonContext'
import ReportContext from '../../../context/report/reportContext'
import BidContext from '../../../context/bid/bidContext'
import BidManage from './BidManage'

const BidHistory = () => {
    const productContext = useContext(ProductContext)
    const bidContext = useContext(BidContext)
    const {
        search_allttwbidhistory,
        getAllTtwBidHistory,
        bidHistoryUpdate,
        responseStatus: ttwBidUpdateStatus,
    } = productContext

    const { responseStatus: responseStatusBid } = bidContext

    const commonContext = useContext(CommonContext)
    const { ttwBidHistoryValue, clearTtwBidHistoryValue } = commonContext

    const { getAllExportDownload } = useContext(ReportContext)

    const [updateData, setUpdate] = useState({})
    const [isInputHide, setInputHide] = useState(false)

    const [tableBody, setTableBody] = useState([])

    const [manage, setManage] = React.useState({
        popup: false,
        status: 'new',
        id: 0,
        secondaryID: {},
    })

    const toggleFullScreenPopup = (popup, status, id, secondaryID) => {
        if (popup) {
            setManage({ popup, status, id, secondaryID })
        } else {
            setManage({ popup, status: 'new', id: 0, secondaryID })
        }
    }

    const [data, setData] = useState({
        popup: false,
        status: 'new',
        id: 0,
    })

    const formikData = useFormik({
        initialValues: {
            page: 1,
            limit: 20,
            order: '',
            orderby: '',
            get_proxy: false,
            ttwOffers: false,
            delete_bid: true,
            filters: {
                project_id: {
                    value: 0,
                    type: 'in',
                    field: 'b.project_id',
                },
                user_id: {
                    value: '',
                    type: 'in',
                    field: 'b.user_id',
                },
            },
            id: '',
            svTTW: false,
        },
        onSubmit: (values) => {
            getAllTtwBidHistory(values)
        },
    })

    useEffect(() => {
        if (ttwBidHistoryValue) {
            if (ttwBidHistoryValue.id) {
                formikData.values.filters.project_id.value =
                    ttwBidHistoryValue.id
                formikData.values.id = ttwBidHistoryValue.id
                formikData.values.ttwOffers = ttwBidHistoryValue.ttwOffers
                formikData.values.filters.user_id.value =
                    ttwBidHistoryValue.user_id ? ttwBidHistoryValue.user_id : ''
                setInputHide(ttwBidHistoryValue.secondaryID ? true : false)

                formikData.values.svTTW =
                    ttwBidHistoryValue.svTTW && ttwBidHistoryValue.svTTW != ''
                        ? ttwBidHistoryValue.svTTW
                        : false
                if (formikData.values.svTTW) {
                    formikData.setFieldValue(
                        'filters',
                        formikData.values.filters
                    )
                }
            } else {
                formikData.values.ttwOffers = false
                formikData.values.filters.project_id.value = ttwBidHistoryValue
                formikData.values.id = ttwBidHistoryValue
                formikData.values.filters.user_id.value =
                    ttwBidHistoryValue.user_id ? ttwBidHistoryValue.user_id : ''
                formikData.setFieldValue('filters', formikData.values.filters)
            }
            setData({ popup: true, id: ttwBidHistoryValue })
            clearTtwBidHistoryValue()
            getAllTtwBidHistory(formikData.values)
            setUpdate({})
        }
    }, [ttwBidHistoryValue])

    useEffect(() => {
        return () => {
            clearTtwBidHistoryValue()
        }
    }, [])

    useEffect(() => {
        if (formikData.values.filters.project_id.value !== 0) {
            getAllTtwBidHistory(formikData.values)
            setUpdate({})
        }
    }, [formikData.values.filters.project_id.value])

    useEffect(() => {
        if (
            ttwBidUpdateStatus &&
            ttwBidUpdateStatus.from === 'bidHistoryUpdate'
        ) {
            getAllTtwBidHistory(formikData.values)
            setUpdate({})
        }
    }, [ttwBidUpdateStatus])

    useEffect(() => {
        if (search_allttwbidhistory && search_allttwbidhistory.records) {
            search_allttwbidhistory.totalRecords =
                search_allttwbidhistory.totalRecords
            search_allttwbidhistory.records.map((data) => {
                data.proposed_amount =
                    data.update_proposed_amount > 0
                        ? data.update_proposed_amount
                        : data.proposed_amount > 0
                        ? data.proposed_amount
                        : data.init_proposed_amount

                data.totalAmount =
                    data.proposed_amount * data.currentbid_increment

                return data
            })

            console.log(
                'search_allttwbidhistory.records',
                search_allttwbidhistory.records
            )
            setTableBody(
                search_allttwbidhistory.records.length
                    ? search_allttwbidhistory.records
                    : []
            )
        }
    }, [search_allttwbidhistory])

    const tableValues = [
        {
            field: 'created_at',
            type: 'datetime',
            firstChild: true,
            sort: true,
            disablePadding: false,
            label: 'Date',
        },
        {
            field: 'user_id',
            type: '',
            firstChild: false,
            sort: false,
            disablePadding: false,
            label: 'User ID',
        },
        {
            field: 'first_name,last_name',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Username',
        },
        {
            field: 'email',
            type: '',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Email',
        },
    ]

    if (formikData.values.get_proxy) {
        tableValues.push({
            field: 'bidtext',
            condition: true,
            conditionValue: {
                formula: {
                    key1: 'proxytable',
                    key2: 1,
                    notation: '===',
                },
                true: {
                    type: 'text',
                    value: 'Max',
                },
                false: {
                    type: 'value',
                    value: 'bidText',
                },
            },
            type: 'text',
            sort: true,
            firstChild: false,
            disablePadding: false,
            label: 'Type',
        })
    }

    const onClickEdit = (event, id) => {
        if (event.keyCode === 13) {
            bidHistoryUpdate({
                id: id,
                proposed_amount: event.target.value,
            })
        } else {
            setUpdate({ ['proposed_amount' + id]: event.target.value })
        }
    }

    if (formikData.values.ttwOffers) {
        tableValues.push({
            field: 'proposed_amount',
            type: 'input',
            clickID: 'bidid',
            sort: true,
            firstChild: false,
            disablePadding: false,
            value: updateData,
            onChange: onClickEdit,
            onClick: onClickEdit,
            label: 'Share',
            disabled: isInputHide,
        })
    } else {
        tableValues.push(
            /*{
                field: 'init_proposed_amount',
                type: '',
                sort: true,
                firstChild: false,
                disablePadding: false,
                label: 'Initial Qty',
            },*/
            {
                field: 'proposed_amount',
                type: '',
                sort: true,
                firstChild: false,
                sortField: 'b.init_proposed_amount',
                disablePadding: false,
                label: 'Qty',
            },
            {
                field: 'currentbid_increment',
                type: 'price',
                sort: true,
                firstChild: false,
                disablePadding: false,
                label: 'Price',
            },
            {
                field: 'totalAmount',
                type: 'price',
                sort: false,
                firstChild: false,
                disablePadding: false,
                label: 'Total Amount',
            }
        )
    }

    tableValues.push({
        field: 'proposal',
        type: '',
        sort: true,
        firstChild: false,
        disablePadding: false,
        label: 'Bid Type',
    })

    const onClickDelete = (id, secondaryID, type) => {
        if (type === 'edit') {
            toggleFullScreenPopup(true, 'edit', id, {
                proposed_amount: secondaryID,
                projectID: formikData.values.filters.project_id.value,
            })
        } else if (type === 'delete') {
            toggleFullScreenPopup(true, 'delete', id, {
                proposed_amount: secondaryID,
                projectID: formikData.values.filters.project_id.value,
            })
        }
    }

    if (
        formikData.values.delete_bid &&
        !location.pathname.includes('/ttw/offer_created')
    ) {
        tableValues.push({
            field: 'action',
            type: 'button',
            clickID: 'bidid',
            secondaryClickID: 'proposed_amount',
            sort: false,
            firstChild: false,
            disablePadding: false,
            label: 'Bid Action',
            isMultiple: [
                {
                    buttonType: 'edit',
                    clickType: 'edit',
                    onclick: onClickDelete,
                    tooltipTitle: 'Edit',
                },
                {
                    buttonType: 'delete',
                    clickType: 'delete',
                    onclick: onClickDelete,
                    tooltipTitle: 'Delete',
                },
            ],
        })
    }

    useEffect(() => {
        if (responseStatusBid) {
            if (responseStatusBid.from === 'ttwBidAction') {
                if (responseStatusBid.status === 'success') {
                    getAllTtwBidHistory(formikData.values)
                }
            }
        }
    }, [responseStatusBid])

    const tableActions = []

    return (
        data.popup && (
            <>
                <FullScreenPopup
                    modaltitle={`Bid History`}
                    open={data.popup}
                    handleClose={() => {
                        setData({ ...data, popup: false })
                        clearTtwBidHistoryValue()
                    }}
                >
                    <div className="wallet">
                        <div className="fspBody">
                            <Button
                                id="ttwBidHistory_report"
                                onClick={() =>
                                    getAllExportDownload({
                                        action: 'export',
                                        order: 'asc',
                                        orderby: '',
                                        search: `b.project_id=${
                                            formikData.values.filters.project_id
                                                .value
                                        } ${
                                            formikData.values.ttwOffers === true
                                                ? 'and b.awarded=1'
                                                : ''
                                        }`,
                                        report: `ttw_bid_history`,
                                        ttwOffers: formikData.values.ttwOffers,
                                        projectID:
                                            formikData.values.filters.project_id
                                                .value,
                                        showAuctionName: true,
                                        svTTW: formikData.values.svTTW,
                                    })
                                }
                            >
                                {' '}
                                <span className="material-icons">get_app</span>
                                Export All Data
                            </Button>
                            <CustomTable
                                formik={formikData.values}
                                tableBody={tableBody}
                                tableValues={tableValues}
                                tableActions={tableActions}
                                allData={search_allttwbidhistory}
                                changeTableFunction={getAllTtwBidHistory}
                            />
                        </div>
                    </div>
                </FullScreenPopup>
                <BidManage data={manage} function={toggleFullScreenPopup} />
            </>
        )
    )
}

export default BidHistory
