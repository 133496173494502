import * as Yup from 'yup'
import {
    dateTimeFormatFunction,
    dateFormatFunction,
} from '../../../../common/components'

export const validationFunction = (arrayValue, customValidation) => {
    let handle = {}
    arrayValue
        .filter((data) => (data.active ? data.active === 1 : true))
        .map((data, index) => {
            // 'date','month','time'

            if (customValidation && customValidation[data.name]) {
                handle[data.name] = customValidation[data.name]
            } else {
                if (
                    data.type === 'radio' ||
                    data.type === 'text' ||
                    data.type === 'email' ||
                    data.type === 'select'
                ) {
                    if (data.is_required) {
                        handle[data.name] = Yup.string().required('Required!')
                    } else {
                        handle[data.name] = Yup.string()
                    }
                }

                if (data.name === 'password') {
                    handle[data.name] = Yup.string()
                        .min(8, 'Minimum 8 characters')
                        .required('Required!')
                }

                if (data.name === 'confirm_password') {
                    handle[data.name] = Yup.string()
                        .oneOf([Yup.ref('password')], 'Passwords not match')
                        .required('Required!')
                }

                if (data.type === 'checkboxarray') {
                    if (data.is_required) {
                        handle[data.name] = Yup.array()
                            .nullable()
                            .required('Required!')
                    } else {
                        handle[data.name] = Yup.array().nullable()
                    }
                }

                if (data.type === 'textarea') {
                    if (data.is_required) {
                        handle[data.name] = Yup.string()
                            .nullable()
                            .required('Required!')
                    } else {
                        handle[data.name] = Yup.string().nullable()
                    }
                }

                if (data.type === 'check') {
                    if (data.is_required) {
                        handle[data.name] = Yup.array()
                            .nullable()
                            .required('Required!')
                    } else {
                        handle[data.name] = Yup.array().nullable()
                    }
                }

                if (data.type === 'phone') {
                    if (data.is_required) {
                        handle[data.name] = Yup.string()
                            .nullable()
                            .required('Required!')
                    } else {
                        handle[data.name] = Yup.string().nullable()
                    }
                }
                if (data.type === 'date') {
                    if (data.is_required) {
                        handle[data.name] = Yup.string()
                            .required('Required!')
                            .nullable()
                    } else {
                        handle[data.name] = Yup.string().nullable()
                    }
                }

                if (data.type === 'time') {
                    if (data.is_required) {
                        handle[data.name] = Yup.string()
                            .required('Required!')
                            .nullable()
                    } else {
                        handle[data.name] = Yup.string().nullable()
                    }
                }

                if (data.type === 'datetime') {
                    if (data.is_required) {
                        handle[data.name] = Yup.string()
                            .required('Required!')
                            .nullable()
                    } else {
                        handle[data.name] = Yup.string().nullable()
                    }
                }

                if (data.type === 'checkbox') {
                    if (data.is_required) {
                        handle[data.name] = Yup.boolean().required('Required!')
                    } else {
                        handle[data.name] = Yup.boolean()
                    }
                }

                if (data.type === 'multiselect') {
                    if (data.is_required) {
                        handle[data.name] = Yup.array().required('Required')
                    } else {
                        handle[data.name] = Yup.array()
                    }
                }

                if (data.type === 'number') {
                    if (data.is_required) {
                        handle[data.name] = Yup.string().required('Required')
                    } else {
                        handle[data.name] = Yup.string()
                    }
                }

                if (data.type === 'ckeditor' || data.type === 'tinymce') {
                    if (data.is_required) {
                        handle[data.name] = Yup.string().required('Required')
                    } else {
                        handle[data.name] = Yup.string()
                    }
                }
                if (data.type === 'float') {
                    if (data.is_required) {
                        handle[data.name] = Yup.number()
                            .required('Required')
                            .test(
                                'is-decimal',
                                'Invalid Decimal Value',
                                (value) => /^\d+(\.\d{1,2})?$/.test(value)
                            )
                    } else {
                        handle[data.name] = Yup.number().test(
                            'is-decimal',
                            'Invalid Decimal Value',
                            (value) => {
                                if (value) {
                                    return /^\d+(\.\d{1,2})?$/.test(value)
                                } else {
                                    return true
                                }
                            }
                        )
                    }
                }

                if (data.type === 'uploadDropZone') {
                    if (data.is_required) {
                        handle[data.name] = Yup.array().required('Required!')
                    } else {
                        handle[data.name] = Yup.array()
                    }
                }

                if (data.type === 'switch') {
                    if (data.is_required) {
                        handle[data.name] = Yup.boolean().required('Required!')
                    } else {
                        handle[data.name] = Yup.boolean()
                    }
                }
                if (data.type === 'multipleArrayValue') {
                    if (data.is_required) {
                        handle[data.name] = Yup.string().required('Required')
                    } else {
                        handle[data.name] = Yup.string()
                    }
                }
            }
        })

    return handle
}

export const initialFunction = (
    arrayValue,
    alldata_all_dropdown,
    subLevelOptions,
    allCountries,
    subStateOptions,
    subCityOptions,
    allLocations
) => {
    let show = {}
    let tempMapData = []
    let calculateValues = []
    arrayValue
        .filter((data) => (data.active ? data.active === 1 : true))
        .map((data, index) => {
            let mapValue = {}

            if (data.type !== 'default' && data.type !== 'custom') {
                mapValue.name = data.name
                mapValue.type = data.type
                mapValue.label = data.label
                mapValue.placeholder = data.placeholder
                mapValue.class = data.class
                mapValue.content = data.content
                mapValue.disabled = data.disabled
                mapValue.calculate = data.calculate
                mapValue.calculate_value = data.calculate_value
                mapValue.icon_only = data.icon_only
            } else {
                mapValue = null
            }

            if (data.type == 'uploadDropZone') {
                mapValue.folder = data.folder || 'product'
                mapValue.multiple = data.multiple || false
                mapValue.reorder = data.reorder || false
                mapValue.accept = data.accept || ''
                mapValue.titleText = data.label
                mapValue.innerText = data.placeholder
                mapValue.object_image = data.object_image || 0
                mapValue.isCompress = data.is_compress === 1 ? 'true' : 0 || 0
                mapValue.quality = data.compress_quality || 0
                mapValue.resultWidth = data.compress_width || 0
                mapValue.resultHeight = data.compress_height || 0
                mapValue.webcam = data.webcam ? data.webcam : false
                mapValue.isCrop = data.isCrop ? data.isCrop : false
            }

            if (data.type == 'multipleArrayValue') {
                mapValue.fields = data.fields
                mapValue.subname = data.subname
            }

            if (data.type === 'select' && parseInt(data.sub_select, 10) !== 1) {
                mapValue.upperLabel = data?.upperLabel
                console.log(data.name, 'select')
                if (data.options) {
                    mapValue.options = data.options
                } else {
                    mapValue.options = alldata_all_dropdown
                        .filter(
                            (value) =>
                                value.variable === data.name &&
                                value.level === 0
                        )
                        .map((item) => {
                            let valueChanged = {}
                            valueChanged.show = item.description
                            valueChanged.value = data.diff_value
                                ? item.value
                                : item.id
                            return valueChanged
                        })
                }
            }
            if (data.type === 'radio') {
                mapValue.int = 1
                mapValue.title = data.label
            }

            if (data.type === 'checkboxarray' || data.type === 'radio') {
                mapValue.item = alldata_all_dropdown
                    .filter((value) => value.variable === data.name)
                    .map((item) => {
                        let valueChanged = {}
                        valueChanged.description = item.description
                        valueChanged.id = item.id
                        return valueChanged
                    })
            }
            if (
                data.type == 'multiselect' &&
                parseInt(data.country, 10) === 1
            ) {
                console.log('countryinside')
                mapValue.options = allCountries
                    .filter(
                        (country) =>
                            global.ignoreCountry.indexOf(country.isoCode) === -1
                    )
                    .map((country) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = country.name
                        busCategoryChanged.value = country.isoCode
                        return busCategoryChanged
                    })
            }
            if (
                data.type === 'multiselect' &&
                data.name !== 'project_id' &&
                data.country !== 1 &&
                parseInt(data.sub_select, 10) !== 1
            ) {
                mapValue.options = alldata_all_dropdown
                    .filter((value) => value.variable === data.name)
                    .map((item) => {
                        let valueChanged = {}
                        valueChanged.show = item.description
                        valueChanged.value = data.diff_value
                            ? item.value
                            : item.id
                        return valueChanged
                    })
            }

            if (data.type === 'multiselect' && data.name === 'project_id') {
                mapValue.options = data.options
            }

            if (
                data.type === 'multiselect' &&
                parseInt(data.sub_select, 10) === 1
            ) {
                mapValue.sub_select = data.sub_select
                mapValue.sub_level = data.sub_level
                mapValue.sub_field = data.sub_field
                mapValue.diff_value = data.diff_value
                console.log(alldata_all_dropdown, 'optttt')
                console.log(data, 'dataaa')

                if (parseInt(data.sub_level, 10) === 0) {
                    mapValue.options = alldata_all_dropdown
                        .filter(
                            (maincat) =>
                                maincat.variable === data.name &&
                                maincat.level === 0
                        )
                        .map((maincat) => {
                            let categoryChanged = {}
                            categoryChanged.show = maincat.description
                            categoryChanged.value = data.diff_value
                                ? maincat.value
                                : maincat.id
                            return categoryChanged
                        })
                } else if (parseInt(data.sub_level, 10) > 0) {
                    let optionValue = subLevelOptions(data)
                    if (optionValue.length) {
                        mapValue.options = subLevelOptions(data)
                    } else {
                        mapValue.options = []
                    }
                }
            }
            if (data.calculate) {
                calculateValues.push(data)
            }

            // 'date','month','time'

            if (data.initialDefault) {
                show[data.name] = data.initialDefault
            } else {
                if (
                    data.type === 'radio' ||
                    data.type === 'text' ||
                    data.type === 'email' ||
                    data.type === 'select'
                ) {
                    show[data.name] = ''
                }

                if (data.name === 'password') {
                    show[data.name] = ''
                }

                if (data.name === 'confirm_password') {
                    show[data.name] = ''
                }

                if (data.type === 'checkboxarray') {
                    show[data.name] = []
                }

                if (data.type === 'textarea') {
                    show[data.name] = ''
                }

                if (data.type === 'check') {
                    show[data.name] = []
                }

                if (data.type === 'phone') {
                    show[data.name] = ''
                }

                if (data.type === 'datetime') {
                    show[data.name] = null
                }
                if (data.type === 'date') {
                    show[data.name] = null
                }

                if (data.type === 'time') {
                    show[data.name] = null
                }

                if (data.type === 'checkbox') {
                    show[data.name] = false
                }

                if (data.type === 'multiselect') {
                    show[data.name] = []
                }
                if (data.type === 'multidate') {
                    show[data.name] = []
                }

                if (data.type === 'number' || data.type === 'float') {
                    show[data.name] = ''
                }

                if (data.type === 'ckeditor') {
                    show[data.name] = ''
                }

                if (data.type === 'tinymce') {
                    show[data.name] = ''
                }

                if (data.type === 'uploadDropZone') {
                    show[data.name] = []
                }

                if (data.type === 'switch') {
                    show[data.name] = false
                }

                if (data.type === 'default') {
                    show[data.name] = data.value
                }

                if (data.type === 'custom') {
                    show[data.name] = data.value
                }
                if (data.type === 'multipleArrayValue') {
                    show[data.name] = []
                }
            }

            if (data.type === 'select' && parseInt(data.sub_select, 10) === 1) {
                mapValue.sub_select = data.sub_select
                mapValue.sub_level = data.sub_level
                mapValue.sub_field = data.sub_field
                console.log(alldata_all_dropdown, 'optttt')
                console.log(data.name, 'dataaa')

                if (parseInt(data.sub_level, 10) === 0) {
                    mapValue.options = alldata_all_dropdown
                        .filter(
                            (maincat) =>
                                maincat.variable === data.name &&
                                maincat.level === 0
                        )
                        .map((maincat) => {
                            let categoryChanged = {}
                            categoryChanged.show = maincat.description
                            categoryChanged.value = data.diff_value
                                ? maincat.value
                                : maincat.id
                            return categoryChanged
                        })
                } else if (parseInt(data.sub_level, 10) > 0) {
                    let optionValue = subLevelOptions(data)
                    if (optionValue.length) {
                        mapValue.options = optionValue
                    } else {
                        mapValue.options = []
                    }
                }
            }

            if (data.type == 'select' && parseInt(data.country, 10) === 1) {
                mapValue.options = allCountries
                    .filter(
                        (country) =>
                            global.ignoreCountry.indexOf(country.isoCode) === -1
                    )
                    .map((country) => {
                        let busCategoryChanged = {}
                        busCategoryChanged.show = country.name
                        busCategoryChanged.value = country.isoCode
                        return busCategoryChanged
                    })
            }
            if (data.type == 'select' && parseInt(data.location, 10) === 1) {
                mapValue.options = allLocations
                    .filter((value) => value.active === 1)
                    .map((location) => {
                        let locationChanged = {}
                        locationChanged.show = location.description
                        locationChanged.value = location.id
                        return locationChanged
                    })
            }
            if (data.type == 'select' && parseInt(data.state, 10) === 1) {
                mapValue.options = subStateOptions(data)
            }
            if (data.type == 'select' && parseInt(data.city, 10) === 1) {
                mapValue.options = subCityOptions(data)
            }

            if (mapValue) {
                tempMapData.push(mapValue)
            }
        })

    return { tempMapData, show, calculateValues }
}

export const editValue = (arrayValue, editData) => {
    console.log(arrayValue, 'array')
    console.log(editData, 'ediii')
    console.log(editData.asin, 'title')
    const objectPush = {}
    arrayValue
        .filter((data) => (data.active ? data.active === 1 : true))
        .map((data, index) => {
            objectPush.id = editData.id ? editData.id : 0
            {
                console.log(
                    'project_id 111',
                    editData[data.name],
                    data.type,
                    data
                )
            }
            if (data.type != 'misc') {
                if (data.initialDefault && editData[data.name]) {
                    objectPush[data.name] = data.initialDefault
                } else {
                    if (
                        data.type == 'imagewithurl' ||
                        data.type == 'uploadDropZone'
                    ) {
                        if (data.object_image) {
                            objectPush[data.name] = editData[data.name]
                                ? JSON.parse(editData[data.name].toString())
                                : []
                        } else {
                            objectPush[data.name] = editData[data.name]
                                ? editData[data.name].toString().split(',')
                                : []
                        }
                    } else if (data.type == 'datetime') {
                        objectPush[data.name] = editData[data.name]
                            ? dateTimeFormatFunction(editData[data.name], true)
                            : null
                    } else if (data.type == 'number') {
                        objectPush[data.name] = editData[data.name]
                            ? editData[data.name]
                            : 0
                    } else if (data.type == 'checkbox') {
                        objectPush[data.name] = editData[data.name]
                            ? editData[data.name]
                            : false
                    } else if (
                        data.type == 'multiselect' &&
                        data.name === 'project_id'
                    ) {
                        objectPush[data.name] = editData.auction_lots
                            ? editData.auction_lots.split(',').map(Number)
                            : []
                    } else if (
                        data.type == 'multiselect' &&
                        data.name !== 'project_id'
                    ) {
                        if (data.diff_value) {
                            objectPush[data.name] = editData[data.name]
                                ? editData[data.name].split(',').map(String)
                                : []
                        } else {
                            objectPush[data.name] = editData[data.name]?.length
                                ? editData[data.name].split(',').map(Number)
                                : []
                        }
                    } else if (data.type == 'multipleArrayValue') {
                        objectPush[data.name] = auction[data.name]
                            ? auction[data.name]
                            : []
                    } else if (data.type == 'multidate') {
                        objectPush[data.name] = editData[data.name]
                            ? editData[data.name]?.split(',').map((d) => d)
                            : []
                    } else if (data.type == 'date') {
                        objectPush[data.name] = editData[data.name]
                            ? dateFormatFunction(editData[data.name], true)
                            : null
                    } else {
                        objectPush[data.name] = editData[data.name]
                            ? editData[data.name]
                            : ''
                    }
                }
            }
        })
    return objectPush
}

export const initialFilterFunction = (arrayValue) => {
    let show = {}
    console.log(arrayValue, 'arrayone')
    function setTableShortValue(data) {
        switch (data.view_page) {
            case '1':
                if (data.is_default) {
                    show[data.field].field = `p.${data.field}`
                } else {
                    show[data.field].field = `it.${data.field}`
                }
                break
            case '2':
                if (data.is_default) {
                    show[data.field].field = `ac.${data.field}`
                } else {
                    show[data.field].field = `cac.${data.field}`
                }
                break
            case '3':
                if (data.is_default && data.additional_field) {
                    show[
                        data.field
                    ].field = `u.${data.field},${data.additional_field}`
                } else if (data.is_default) {
                    show[data.field].field = `u.${data.field}`
                } else {
                    show[data.field].field = `cu.${data.field}`
                }
                break
            case '4':
                if (data.is_default) {
                    show[data.field].field = `tp.${data.field}`
                } else {
                    show[data.field].field = `tcp.${data.field}`
                }
                break

            default:
                break
        }
    }

    arrayValue
        .filter((data) => (data.active ? data.active === 1 : true))
        .map((data, index) => {
            show[data.field] = {}
            show[data.field].type = data.filter_type

            if (data.type === 'text' || data.type === 'select') {
                show[data.field].value = ''
                setTableShortValue(data)
            }

            if (data.type === 'datetime') {
                show[data.field].value = ''
                setTableShortValue(data)
            }

            if (data.type === 'email') {
                show[data.field].value = ''
                setTableShortValue(data)
            }
            if (data.type === 'date') {
                show[data.field].value = ''
                setTableShortValue(data)
            }
        })

    return show
}
