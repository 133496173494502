import {
    RESPONSE_STATUS,
    CLEAR_RESPONSE,
    GET_PREVIOUS_DATA,
} from './commonTypes'

export default (state, action) => {
    switch (action.type) {
        case GET_PREVIOUS_DATA:
            return {
                ...state,
                previous_data: action.payload,
            }
        case RESPONSE_STATUS:
            return {
                ...state,
                responseStatus: action.payload,
            }
        case CLEAR_RESPONSE:
            return {
                ...state,
                responseStatus: '',
            }
        default:
            return state
    }
}
