import openSocket from 'socket.io-client'

let socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`, {
    transports: ['websocket'],
})

socket.on('connect', function () {
    console.log('Socket Connected')
})

function bidAddtime(cb) {
    socket.on('bidAddtime', (data) => {
        cb(data)
    })
}

function realClosedUpdates(cb) {
    socket.on('realclosedupdates', (data) => {
        cb(data)
    })
}

function refreshbuyer(cb) {
    socket.on('refreshbuyer', (data) => {
        cb(data)
    })
}
function refreshbuyerOff() {
    socket.off('refreshbuyer')
}
function bid_extended(cb) {
    socket.on('bid_extended', (data) => {
        cb(data)
    })
}
function bid_extendedOff() {
    socket.off('bid_extended')
}
socket.on('disconnect', function () {
    console.log('Socket Disconnected')
})

function reInitializeSocket() {
    socket.close()
    socket = openSocket(`${process.env.REACT_APP_DOMAIN}/`)
}

export {
    bidAddtime,
    realClosedUpdates,
    refreshbuyer,
    socket,
    reInitializeSocket,
    refreshbuyerOff,
    bid_extended,
    bid_extendedOff,
}
