import React, { useContext, useEffect } from 'react'
import {
    Route,
    BrowserRouter as Router,
    withRouter,
    Switch,
} from 'react-router-dom'
import PrivateRoute from './privateRoute'
import Dashboard from '../views/Dashboard'
import Profile from '../views/Profile'

import AuctionLots from '../views/AuctionLots'
import Report from '../views/Report'
import Login from '../views/Login'
import Listing from '../views/Listing'
import BidDeposit from '../views/BidDeposit'
import UserView from '../views/UserManagement/UserView'
import Employee from '../views/EmployeeManagement'
import TemplateView from '../views/Communications/TemplateView'
import StaticView from '../views/Settings/StaticView'
import BroadCaster from '../views/VideoAuction/BroadCaster'

import EmailLogs from '../views/Communications/EmailLogs'
import Invoice from '../views/Invoice'
import Payment from '../views/Payment'
import LanguageManager from '../views/LanguageManager'
import StaticPages from '../views/Settings/StaticPages'
import ConfigView from '../views/Settings/ConfigView'
import ChangePassword from '../product/views/ChangePassword'

import AuthContext from '../product/context/auth/authContext'
import CustomCommonContext from '../custom/context/common/commonContext'
import ProductCommonContext from '../product/context/common/commonContext'
import BusinessCategory from '../views/Settings/BusinessCategory'
import VatRegistration from '../views/Settings/VatRegistration'
import Category from '../views/Settings/Category'
import Condition from '../views/Settings/Condition'
import Location from '../views/Settings/Location'
import Wallet from '../views/Wallet'
import TransactionPayment from '../views/Transaction/payment'
import TransactionRefund from '../views/Transaction/refund'
import BidIncrementView from '../views/Settings/BidIncrementView'
import LiveAuction from '../views/VideoAuction/LiveAuction'

const Routes = () => {
    const { loadUser } = useContext(AuthContext)
    const { setUSState } = useContext(ProductCommonContext)
    const { getGlobalVariable } = useContext(CustomCommonContext)

    useEffect(() => {
        if (localStorage.token) {
            loadUser()
        }
        getGlobalVariable()
    }, [])

    return (
        <>
            <Switch>
                <Route exact path="/" component={Login} />
                <PrivateRoute
                    exact
                    path="/change_password"
                    component={ChangePassword}
                />
                <PrivateRoute exact path="/profile" component={Profile} />
                <PrivateRoute exact path="/dashboard" component={Dashboard} />
                <PrivateRoute
                    exact
                    path="/auction/:action"
                    component={AuctionLots}
                />
                <PrivateRoute exact path="/payment" component={Payment} />
                <PrivateRoute exact path="/wallet" component={Wallet} />
                <PrivateRoute exact path="/report" component={Report} />
                <PrivateRoute
                    exact
                    path="/listing/:action"
                    component={Listing}
                />
                <PrivateRoute
                    exact
                    path="/users/:action"
                    component={UserView}
                />
                <PrivateRoute
                    exact
                    path="/employees/:action"
                    component={Employee}
                />
                <PrivateRoute
                    exact
                    path="/logs/:action"
                    component={EmailLogs}
                />
                <PrivateRoute
                    exact
                    path="/communications/:action"
                    component={TemplateView}
                />
                <PrivateRoute
                    exact
                    path="/languageManager"
                    component={LanguageManager}
                />
                <PrivateRoute
                    exact
                    path="/invoice/:action"
                    component={Invoice}
                />
                <PrivateRoute
                    exact
                    path="/settings/static"
                    component={StaticView}
                />
                <PrivateRoute
                    exact
                    path="/settings/general"
                    component={ConfigView}
                />
                <PrivateRoute
                    exact
                    path="/settings/category"
                    component={Category}
                />
                <PrivateRoute
                    exact
                    path="/settings/condition"
                    component={Condition}
                />
                <PrivateRoute
                    exact
                    path="/settings/location"
                    component={Location}
                />
                <PrivateRoute
                    exact
                    path="/settings/bid_increments"
                    component={BidIncrementView}
                />
                <PrivateRoute
                    exact
                    path="/transaction/payment"
                    component={TransactionPayment}
                />
                <PrivateRoute
                    exact
                    path="/transaction/refund"
                    component={TransactionRefund}
                />
                <PrivateRoute
                    exact
                    path="/bid_deposit"
                    component={BidDeposit}
                />
                <PrivateRoute
                    exact
                    path="/settings/vat"
                    component={VatRegistration}
                />
                <PrivateRoute
                    exact
                    path="/settings/businesscategory"
                    component={BusinessCategory}
                />
                <PrivateRoute
                    exact
                    path="/videoAuction/broadcast"
                    component={BroadCaster}
                />
                <PrivateRoute
                    exact
                    path="/videoAuction/live_auction"
                    component={LiveAuction}
                />
            </Switch>
        </>
    )
}
export default withRouter(Routes)
